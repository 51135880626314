import { ChevronRightIcon } from '@heroicons/react/solid';

import { ClaimListItemProps } from '../ClaimsTypes';
import { formatDate, userHasRoundRole } from '../../../components/Claims/ClaimsUtils';

function ClaimListItem(props: ClaimListItemProps) {
  const { claimableRound, user } = props;

  const isOwnRound = userHasRoundRole(user, claimableRound.round, 'interviewer');

  let eligibilityPillClasses;
  let eligibilityPillLabel;
  if (claimableRound.canClaim && !isOwnRound) {
    eligibilityPillClasses = 'bg-green-100 text-green-800';
    eligibilityPillLabel = 'Eligible to claim';
  } else if (claimableRound.canClaim && isOwnRound) {
    eligibilityPillClasses = 'bg-orange-100 text-orange-800';
    eligibilityPillLabel = 'Your interview';
  } else {
    eligibilityPillClasses = 'bg-gray-100 text-gray-800';
    eligibilityPillLabel = 'Not eligible';
  }

  return (
    <li className="flex px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div className="mr-2 flex flex-shrink-0 items-center sm:mr-5">
        <img
          className="h-12 w-12 rounded-full"
          src={claimableRound.round.details.companyIconUrl}
          alt={claimableRound.round.details.companyName}
        />
      </div>
      <div className="flex min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
          <div className="truncate">
            <div className="flex text-sm">
              <p className="truncate">
                <time dateTime={claimableRound.round.start.toLocaleString()}>
                  {formatDate(claimableRound.round.start)}
                </time>
              </p>
            </div>
            <div className="mv-0 sm:mv-2 flex">
              <div className="flex items-center text-sm text-gray-500">
                <p className="truncate capitalize">
                  {window?.CONFIG.const.ROUND_FOCUS_DISPLAY_VALUES[claimableRound.round.details.focus]}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 sm:mt-0 sm:ml-5">
            <div className="flex overflow-hidden">
              <span
                className={`${eligibilityPillClasses} mt-0 inline-flex truncate rounded-full px-2 text-xs font-semibold leading-5`}
              >
                {eligibilityPillLabel}
              </span>
            </div>
          </div>
        </div>
        <div className="ml-2 flex flex-shrink-0 items-center md:ml-5">
          <a href={`/claim/${claimableRound.round._id}`}>
            <ChevronRightIcon
              className="h-7 w-7 rounded-full border-2 text-gray-400 hover:border-gray-600 hover:text-gray-600"
              aria-hidden="true"
            />
          </a>
        </div>
      </div>
    </li>
  );
}

export default ClaimListItem;
