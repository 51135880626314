import { useState } from 'react';

type Props = {
  onChange: (value: string) => void;
  defaultDate?: string;
};

const DatePicker = (props: Props) => {
  const { onChange, defaultDate = new Date().toLocaleDateString('en-CA') } = props;

  const [date, setDate] = useState(defaultDate);

  return (
    <input
      type={'date'}
      onChange={(date) => {
        setDate(date.target.value);
        onChange(date.target.value);
      }}
      value={date}
    />
  );
};

export default DatePicker;
