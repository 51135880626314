import CircularSpinner from '../../elements/CircularSpinner';
import RefreshIconButton from '../../elements/RefreshButton';

export type SuperAdminPaginationControlsProps = {
  isSpinnerVisible: boolean;
  currentPage: number;
  lastPage: number;
  limit: number;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  totalItems: number;
  refetchData: () => void;
};

const SuperAdminPaginationControls = (props: SuperAdminPaginationControlsProps) => {
  const { isSpinnerVisible, currentPage: page, lastPage, limit, refetchData, setLimit, setPage, totalItems } = props;
  const buttonClassNames = 'bg-blue-500 px-4 py-2 text-white rounded-md';

  const firstPage = page != 1 ? page : null;
  const prevPage = page > 1 ? page - 1 : null;
  const nextPage = page < lastPage ? page + 1 : null;

  const firstIndex = (page - 1) * limit + 1;
  const lastIndex = Math.min((page - 1) * limit + limit, totalItems);

  const handleLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLimit(parseInt(e.target.value));
  };

  return (
    <div className="my-2 flex flex-row items-center justify-end gap-2">
      {isSpinnerVisible && <CircularSpinner />}
      <div>
        <strong>Items:</strong> {firstIndex} - {lastIndex} of {totalItems}.
      </div>
      <RefreshIconButton onClick={refetchData} />
      <select value={limit} onChange={handleLimitChange} className="block w-[80px] rounded-lg border">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <button
        className={`rounded-md bg-blue-500 px-4 py-2 text-white ${
          !firstPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600'
        }`}
        disabled={!firstPage}
        onClick={() => setPage(1)}
      >
        {'<< '}
      </button>
      <button
        className={`${buttonClassNames} ${!prevPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600'}`}
        disabled={!prevPage}
        onClick={() => setPage(prevPage)}
      >
        {'<'}
      </button>
      <span className="p-2">
        {page} of {lastPage}
      </span>
      <button
        className={`rounded-md bg-blue-500 px-4 py-2 text-white ${
          !nextPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600'
        }`}
        disabled={!nextPage}
        onClick={() => setPage(nextPage)}
      >
        {'>'}
      </button>
      <button
        className={`rounded-md bg-blue-500 px-4 py-2 text-white ${
          page == lastPage ? 'cursor-not-allowed opacity-50' : 'hover:bg-blue-600'
        }`}
        disabled={page == lastPage}
        onClick={() => setPage(lastPage)}
      >
        {'>> '}
      </button>
    </div>
  );
};

export default SuperAdminPaginationControls;
