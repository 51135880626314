const EligibilityCriteriaDetails = () => (
  <div className="text-left">
    <p>
      <span className="font-semibold">Highest rating achieved:</span> Your rating is based on your performance in mock
      interviews. You need to do at least 2 practice interviews to get rated, and you need to get within the top 10% to
      be eligible. Schedule mock interviews <a href="/dashboard/interviewee">here</a> to improve your rating.{' '}
      <strong>
        Note that we only look at your highest rating, so once you get access, you can’t lose it, even if you fail a few
        interviews.
      </strong>
    </p>
    <p>
      <span className="font-semibold">Years of experience:</span> For now, our employers are only accepting intros to
      users with at least 4 years of full-time software engineering experience. If what we have on file for you is
      incorrect, please <a href="/settings">update your years of experience</a>.
    </p>
    <p>
      <span className="font-semibold">Visa status:</span> For now, our employers are only accepting intros from users
      who do not require sponsorship, those who already have an H-1B and just need a transfer, or those who need a TN
      visa. Please <a href="/settings">update your visa status</a> if your situation has changed.
    </p>
    <hr className="my-3" />
    <p>
      We hope to expand intros to users of all experience levels and visa statuses in the future. If you don't qualify
      right now,{' '}
      <span className="font-bold">
        please don’t falsify your info. It won't help you get in the door (we promise), and it'll likely make these
        companies stop working with us before we can expand
      </span>
      .
    </p>
  </div>
);

export default EligibilityCriteriaDetails;
