import React from 'react';

import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookTableOfContents } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookTableOfContents';
import { TBeyondCTCIBookTableOfContents } from '../../../types/BeyondCTCIBookTableOfContents';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { Card } from '@interviewingio/iio-react-component-lib';
import '../BeyondCTCIBookStyles.css';

const BeyondCTCIBookTableOfContentsContent = ({
  tableOfContents,
}: {
  tableOfContents: TBeyondCTCIBookTableOfContents;
}) => (
  <ul className="[&_ul]:list-[revert] list-outside pl-5">
    {tableOfContents.map((bookPart: TBeyondCTCIBookPart, i: number) => (
      <li className="book-part-list-item pt-1" key={i}>
        <div className="flex flex-col pl-1">
          <a href={`${window?.CONFIG.beyondCTCIBaseUrl}/${bookPart.attributes.Slug}`}>{bookPart.attributes.Name}</a>
          <ul className="[&_ul]:list-[revert] list-outside list-disc pl-5">
            {bookPart.attributes.book_chapters.data.map((bookChapter: TBeyondCTCIBookChapter, j: number) => (
              <li className="open-book-list-item pt-1" key={j}>
                <div className="flex flex-col pl-1">
                  <a
                    href={`${window?.CONFIG.beyondCTCIBaseUrl}/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}`}
                  >
                    {bookChapter.attributes.Name}
                  </a>
                  {/* <ul className="[&_ul]:list-[revert] list-outside list-disc pl-5">
                  
                      TODO: Filter `bookChapter.attributes.chapterContent` for components of type ComponentTypes.basicRichTextComponentLabel
                      to build a list of hyperlinked section titles

                      <li key={k}>
                        <a
                          href={`${BEYOND_CTCI_BASE_URL}/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}/${bookSection.attributes.Slug}`}
                        >
                          {bookSection.attributes.Title}
                        </a>
                      </li>
                    ))}
                  </ul> */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </li>
    ))}
  </ul>
);

const BeyondCTCIBookTableOfContentsPage = () => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookTableOfContents, isLoading, isFetched } = useBeyondCTCIBookTableOfContents();

  if (!isUserAllowed) return null;

  return (
    <Card title="Table of Contents">
      {isLoading && <p>Loading...</p>}
      {isFetched && <BeyondCTCIBookTableOfContentsContent tableOfContents={bookTableOfContents} />}
    </Card>
  );
};

export default BeyondCTCIBookTableOfContentsPage;
