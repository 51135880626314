import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { XIcon } from '@heroicons/react/outline';

export type TModalProps = {
  title: string;
  afterClose: () => void;
  icon: JSX.Element | string;
  message: JSX.Element | string;
};

/**
 * Info Modals simply provide the user with some information.
 *
 * Features:
 *  - Title
 *  - Message
 *  - Close button at top-right
 *  - An `afterClose` callback that is called after the modal has been removed
 *
 */
const InfoModal = NiceModal.create((props: TModalProps) => {
  const modal = useModal();
  const { title, afterClose, icon: ModalIcon, message } = props;

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-sm sm:p-6">
                <button
                  type="button"
                  className="absolute top-2 right-2 text-gray-400 hover:text-gray-500"
                  onClick={() => modal.hide()}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div>
                  {ModalIcon}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-5 sm:mt-6">{message}</div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export { InfoModal };
