import { Dispatch, SetStateAction } from 'react';
import { InvalidateOptions, InvalidateQueryFilters, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import OnboardingModalContainer from './components/Onboarding/OnboardingModal/OnboardingModalContainer';
import UpdateOnboardingModalContainer from './components/Onboarding/UpdateOnboardingModal/UpdateOnboardingModalContainer';
import SetDueDateModalContainer from './components/PayLaterProgram/Admin/SetDueDate/SetDueDateModalContainer';
import { ToastProvider } from './components/ToastContext';
import WhiteboardContainer from './components/Whiteboard/WhiteboardContainer';
import { TUser } from './types/User';
import { UserProvider } from './userContext';
import ExperienceConfirmationModalContainer from './components/ExperienceConfirmation/ExperienceConfirmationContainer';
import YesNoDialogContainer from './components/YesNoDialog/YesNoDialogContainer';
import SchedulerContainer from './components/Scheduler/Container';
import { type AnalyticsSnippet } from '@segment/analytics-next';
import NiceModal from '@ebay/nice-modal-react';
import ReplaySidebarContainer from './components/ReplaySidebar/ReplaySidebarContainer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { GlobalContextProvider } from './providers/GlobalContextProvider';
import React from 'react';
import ReactInNgPortalManager from './components/ReactInNgPortalManager';

declare global {
  interface Window {
    loadReact: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    angular: { element: (args: any) => any };
    analytics: AnalyticsSnippet;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CONFIG: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Rollbar: any;
    setIsWhiteboardVisible: Dispatch<SetStateAction<boolean>>;
    toggleWhiteboardCollaboration: (
      friendlyId: string,
      socket: Record<string, (event: string, data?: unknown) => void>,
      mode: 'live' | 'replay' | 'playground'
    ) => void;
    setUser: Dispatch<TUser>;
    synchronizeWhiteboard: (epochTime: number) => void;
    setIsReplayChatVisible: Dispatch<SetStateAction<boolean>>;
    toggleReplayChat: (interview: Record<string, object>) => void;
    synchronizeReplayChat: (position: number) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    downloadRecordings: (recordingsArray: Record<string, any>) => void;
    reactInNgPortalManager: {
      addPortal: <P>(key: string, componentId: string, container: HTMLElement, props: P) => JSX.Element;
      removePortal: (key: string) => void;
    };
    invalidateQueries: (filters?: InvalidateQueryFilters<unknown>, options?: InvalidateOptions) => Promise<void>;
  }
}

const BaseProviders = ({ children }: { children: React.ReactNode }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  window.invalidateQueries = queryClient.invalidateQueries.bind(queryClient);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <GlobalContextProvider>
          <NiceModal.Provider>
            <ToastProvider>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              {children}
            </ToastProvider>
          </NiceModal.Provider>
        </GlobalContextProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

const App = () => (
  <BaseProviders>
    <YesNoDialogContainer />
    <ReplaySidebarContainer />
    <WhiteboardContainer />
    <SetDueDateModalContainer />
    <ReactInNgPortalManager />
    <OnboardingModalContainer />
    <UpdateOnboardingModalContainer />
    <ExperienceConfirmationModalContainer />
    <SchedulerContainer />
  </BaseProviders>
);

export default App;
