import { useEffect } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import { useUser } from '../../../../userContext';

const Pseudonym = () => {
  const { user, generatePseudonym } = useUser();

  useEffect(() => {
    if (!user.pseudonym) generatePseudonym();
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div>
        <p className="pl-1">What everyone you interview with will see</p>
      </div>
      <div className="my-3">
        <span className="shadow-sm bg-gray-50 block w-full sm:text-sm rounded-md">
          <p className="py-2 pl-4 text-lg font-medium">{user?.pseudonym}</p>
        </span>
      </div>
      <div className="w-100">
        <div
          tabIndex={0}
          className="flex text-blue-700 float-right cursor-pointer"
          onClick={generatePseudonym}
          onKeyDown={(event) => {
            event.key === 'Enter' && generatePseudonym();
          }}
        >
          <span>Generate new handle</span>
          <RefreshIcon height={18} className="ml-2" />
        </div>
      </div>
    </div>
  );
};

export default Pseudonym;
