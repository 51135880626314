import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { FinalPropsType } from './Types';
dayjs.extend(advancedFormat);

const RoundInfoPanel = (props: Partial<FinalPropsType>) => {
  const { date, description, intervieweePseudonym, dedicatedCoachingInterviewerRoundReschedule } = props;
  const dateText = dayjs(date).format('dddd, MMM Do').toString();
  const timeText = dayjs(date).format('h:mma').toString();
  return (
    <div className="mb-2 rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm font-semibold text-gray-700">{description}</p>
        </div>
      </div>
      <div className="flex">
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm">
            <span className=" font-semibold text-gray-700">Start: </span>
            {dateText} at {timeText}
          </p>
        </div>
      </div>
      {dedicatedCoachingInterviewerRoundReschedule && (
        <div className="flex">
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm">
              <span className="text-sm font-semibold text-gray-700">Mentee:</span> {intervieweePseudonym}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const IntervieweeInstructionsPanel = () => (
  <>
    <p className="py-2 px-2 text-sm font-light">
      Participating in peer interviews requires following our rules and guidelines. Please keep in mind that even though
      the interview is anonymous, there is a real person on the other end giving up their time to interview you.
    </p>
    <p className="mb-0 py-2 px-2 text-sm font-light">With that in mind:</p>
    <ul className="list-outside list-disc px-14 py-2 text-sm font-light">
      <li className="pb-1">Please show up on time.</li>
      <li className="pb-2">If you need to cancel, please do so at least 24 hours before your interview time.</li>
      <li className="pb-1">
        Please be be conservative with the times you choose. If we see more than 2 cancellations with less than 24
        hours’ notice or more than 1 no-show, we’ll disable your access to interviewing.
      </li>
    </ul>
    <p className="px-2 py-2 text-sm  font-light">
      Help us give a great experience to everyone on our platform by observing the rules stated above. Thanks, and good
      luck!
    </p>
  </>
);

const Final = (props: FinalPropsType) => {
  const { date, description, title, intervieweePseudonym, dedicatedCoachingInterviewerRoundReschedule } = props;

  return (
    <div>
      <h2 className="border-b px-7 pb-3 text-2xl font-semibold text-gray-900">{title}</h2>
      <div className="p-7 text-base text-gray-700">
        <RoundInfoPanel
          date={date}
          description={description}
          intervieweePseudonym={intervieweePseudonym}
          dedicatedCoachingInterviewerRoundReschedule={dedicatedCoachingInterviewerRoundReschedule}
        />
        {!dedicatedCoachingInterviewerRoundReschedule && <IntervieweeInstructionsPanel />}
      </div>
    </div>
  );
};

export default Final;
