import classNames from 'classnames';

type BannerProps = {
  className?: string;
  children: React.ReactNode;
};

export const Banner = (props: BannerProps) => {
  const { children, className } = props;
  return <div className={classNames('rounded bg-yellow p-2', className)}>{children}</div>;
};

export default Banner;
