import ClaimActionBar from './ClaimActionBar';
import type { ClaimPanelProps } from './ClaimsTypes';

import { formatDate } from './ClaimsUtils';

//@ts-ignore
function getLevelLabel(round): string {
  let levelLabel = 'Unknown';
  if (round._partnerCareerGroup) {
    levelLabel = round._partnerCareerGroup;
    if (round._intervieweeYOE) levelLabel += ` (${round._intervieweeYOE} years)`;
  } else if (round._intervieweeYOE) {
    levelLabel = `${round._intervieweeYOE} years`;
  }
  return levelLabel;
}

const ClaimPanel = (props: ClaimPanelProps) => {
  const { userClaimableRound, isUserInterviewee, isUserInterviewer } = props;
  const { round } = userClaimableRound;
  if ((!round.claimableWhileAssigned && round.allowedUsers.length == 2) || round.start < new Date())
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Interview</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">This interview is no longer claimable.</p>
        </div>
      </div>
    );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Interview</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          The following interview can be claimed by eligible interviewers
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Start time</dt>
            <dd className="mt-1 text-sm capitalize text-gray-900 sm:col-span-2 sm:mt-0">{formatDate(round.start)}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Interview Type</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{round.details.companyName}</dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Focus</dt>
            <dd className="mt-1 text-sm capitalize text-gray-900 sm:col-span-2 sm:mt-0">
              {window?.CONFIG.const.ROUND_FOCUS_DISPLAY_VALUES[round.details.focus]}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Level</dt>
            <dd className="mt-1 text-sm capitalize text-gray-900 sm:col-span-2 sm:mt-0">{getLevelLabel(round)}</dd>
          </div>
        </dl>
      </div>
      <div className="bg-gray-50 px-4 py-5 sm:px-6">
        <ClaimActionBar
          userClaimableRound={userClaimableRound}
          isUserInterviewee={isUserInterviewee}
          isUserInterviewer={isUserInterviewer}
        />
      </div>
    </div>
  );
};

export default ClaimPanel;
