import { Fragment, ReactElement } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export type iioSelectOption<ValueType> = {
  value: ValueType;
  label: string;
  imagePath?: string;
  icon?: JSX.Element;
};

type Props = {
  options: iioSelectOption<number | string>[];
  label?: string;
  selected?: iioSelectOption<number | string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  className?: string;
  maxHeightClassName?: string;
  placeholder?: string;
};

const renderOptions = (maxHeightClassName: string, options: iioSelectOption<number | string>[]) => (
  <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
    <Listbox.Options
      className={classNames(
        maxHeightClassName ?? 'max-h-56',
        'text-base-legacy absolute z-10 mt-1  w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
      )}
    >
      {options.map((option, index) => (
        <Listbox.Option
          key={index}
          className={({ active }) =>
            classNames(
              active ? 'bg-blue-500 text-white' : 'text-gray-900',
              'relative cursor-default select-none py-2 pl-3 pr-9'
            )
          }
          value={option.value}
        >
          {({ selected, active }) => (
            <>
              <div className="flex items-center">
                {option.imagePath && (
                  <img src={option.imagePath} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                )}
                {option.icon && <> {option.icon} </>}
                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                  {option.label}
                </span>
              </div>

              {selected && (
                <span
                  className={classNames(
                    active ? 'text-white' : 'text-blue-500',
                    'absolute inset-y-0 right-0 flex items-center pr-4'
                  )}
                >
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Listbox.Option>
      ))}
    </Listbox.Options>
  </Transition>
);

const renderTriggerButton = ({
  label,
  imagePath,
  icon,
  placeholder,
}: {
  label: string;
  imagePath?: string;
  icon?: JSX.Element;
  placeholder?: string;
}): ReactElement => (
  <Listbox.Button
    className="relative w-full cursor-default rounded-md border border-solid border-gray-300
                          bg-white py-2 pl-3 pr-10 text-left shadow-sm
                          focus:border-blue-400 focus:outline-none focus:ring-1
                          focus:ring-blue-400 sm:text-sm"
  >
    <span className="flex h-6 items-center">
      {imagePath && <img src={imagePath} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />}
      {icon && icon}
      {label.length ? (
        <span className="ml-3 block truncate">{label}</span>
      ) : (
        <span className="ml-3 block truncate text-lg font-light text-gray-500">{placeholder}</span>
      )}
    </span>
    <span
      className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center
                            pr-2"
    >
      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </span>
  </Listbox.Button>
);

export default function Select({
  options,
  label,
  selected,
  onChange,
  className,
  maxHeightClassName = 'max-h-56',
  placeholder,
}: Props) {
  return (
    <div className={className}>
      <Listbox value={selected?.value} onChange={onChange}>
        <Listbox.Label className="block pb-1 text-base font-light">{label}</Listbox.Label>
        <span className="relative mt-1 w-full">
          {renderTriggerButton({
            label: selected?.label ?? '',
            imagePath: selected?.imagePath,
            icon: selected?.icon,
            placeholder,
          })}
          {renderOptions(maxHeightClassName, options)}
        </span>
      </Listbox>
    </div>
  );
}
