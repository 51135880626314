import { useState } from 'react';
import classNames from 'classnames';
import { XIcon } from '@heroicons/react/solid';
import { Transition } from '@headlessui/react';

type Props = {
  label: string;
  handleDeselect: (company: string) => void;
  className?: string;
};

export default function Chips({ className, label, handleDeselect }: Props) {
  const [show, setShow] = useState(true);

  return (
    <div className={classNames('grid-row', className)}>
      <Transition show={show}>
        <button
          type="button"
          className="items-center px-2.5 py-1.5 border border-transparent whitespace-nowrap text-xs font-semibold rounded text-white bg-slate-500 hover:bg-slate-400 focus:outline-offset-4 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setShow(false);
          }}
        >
          {label}
          <span className="sr-only">Close</span>
          <XIcon
            className="h-4 w-4 border-l-4 border-transparent"
            aria-hidden="true"
            onClick={() => handleDeselect(label)}
          />
        </button>
      </Transition>
    </div>
  );
}
