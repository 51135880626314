import { Round, User, UserClaimableRound } from './ClaimsTypes';
import axios from '../../utils/axios';

const claimRound = async (roundId: string): Promise<object | null> => {
  let result = null;
  // @ts-ignore
  if (window?.angular) {
    // @ts-ignore
    result = await window.angular?.element(document.body).injector().get('RoundService').claim(roundId);
  }
  return result;
};

const setClaimableWhileAssigned = async (roundId: string, claimable: boolean): Promise<object | null> => {
  let result = null;
  // @ts-ignore
  if (window?.angular) {
    result = await window.angular
      // @ts-ignore
      ?.element(document.body)
      .injector()
      .get('RoundService')
      .setClaimableWhileAssigned(roundId, claimable);
  }
  return result;
};

const getUserClaimableRound = async (roundId: string): Promise<UserClaimableRound | null> => {
  const productCatalogResponse = await axios.get(`api/rounds/claimable/${roundId}`);
  return productCatalogResponse.data;
};

const getUserClaimableRounds = async (): Promise<UserClaimableRound[] | null> => {
  let result = null;
  // @ts-ignore
  if (window?.angular) {
    // @ts-ignore
    result = await window.angular?.element(document.body).injector().get('RoundService').getClaimable();
  }
  return result;
};

const getUser = async (): Promise<User | null> => {
  let result = null;
  // @ts-ignore
  if (window?.angular) {
    // @ts-ignore
    result = await window.angular?.element(document.body).injector().get('Identity').getUser();
  }
  return result;
};

const userHasRoundRole = (user: User, round: Round, role: string) =>
  !!round.allowedUsers.find((allowedUser) => user._id === allowedUser.userId && allowedUser.roleAllowed === role);

const formatDate = (dateToFormat: Date | string) => {
  const coercedDate = dateToFormat instanceof Date ? dateToFormat : new Date(dateToFormat);
  if (coercedDate.toString() === 'Invalid Date') window.Rollbar.error('Invalid date encountered');
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };
  return coercedDate.toLocaleString([], options);
};

export {
  claimRound,
  formatDate,
  getUser,
  getUserClaimableRounds,
  getUserClaimableRound,
  setClaimableWhileAssigned,
  userHasRoundRole,
};
