import { Suspense, useRef } from 'react';
import { InterviewerWithInterviews } from '../../../../types/InterviewerWithInterviews';
import LazyLoadedAGGrid from '../../../../elements/LazyLoadedAGGrid';
import { GridReadyEvent, ColDef, ColGroupDef } from 'ag-grid-community';
import { UserTimezoneDateFormatter } from '../../../superAdminFormatters';
import { useUser } from '../../../../userContext';

export type InterviewerInterviewsPanelProps = {
  interviewer: InterviewerWithInterviews;
};

const HyperlinkCellRenderer = (props: { value: { url: string; displayText: string } }) => {
  const { url, displayText } = props.value;
  return (
    <a href={url} rel="noopener noreferrer">
      {displayText}
    </a>
  );
};

const InterviewerInterviewsPanel = (props: InterviewerInterviewsPanelProps) => {
  const { interviewer } = props;
  const { user } = useUser();

  const gridApi = useRef(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const exportToCsv = () => {
    if (gridApi.current) {
      gridApi.current.exportDataAsCsv();
    }
  };

  const gridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
  };

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      field: 'id',
      headerName: 'Interview',
      pinned: 'left',
      sortable: true,
    },
    {
      cellRenderer: HyperlinkCellRenderer,
      field: 'friendlyId',
      headerName: 'Friendly Id',
      pinned: 'left',
      sortable: true,
      valueGetter: (params) => ({
        url: `/admin/interviews?friendlyId=${params.data.friendlyId}`,
        displayText: params.data.friendlyId,
      }),
    },
    { field: 'round.orgId', headerName: 'Employer', sortable: true },
    { field: 'round.focus', headerName: 'Focus', sortable: true },
    {
      field: 'startTime',
      headerName: 'Start Time',
      sortable: true,
      cellRenderer: UserTimezoneDateFormatter(user.timezone),
    },
    {
      field: 'endTime',
      headerName: 'End Time',
      sortable: true,
      cellRenderer: UserTimezoneDateFormatter(user.timezone),
    },
    {
      headerName: 'Duration',
      sortable: true,
      valueGetter: (params) => {
        const timeDiff = Math.abs(new Date(params.data.endTime).getTime() - new Date(params.data.startTime).getTime());

        const hours = Math.floor(timeDiff / 3600000);
        const minutes = Math.floor((timeDiff % 3600000) / 60000);
        const seconds = Math.floor((timeDiff % 60000) / 1000);

        const deltaFormatted = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`;
        return deltaFormatted;
      },
    },
    {
      valueGetter: (params) => !!params.data.interviewer.excludeFromScoring,
      headerName: 'Manually excluded',
      cellDataType: 'boolean',
      sortable: true,
      headerTooltip: 'Interview was manually excluded from scoring by an admin',
    },
    { field: 'includedInScoring', headerName: 'Used in scoring', sortable: true },
    { field: 'interviewee.review.workWithThem', headerName: 'Work with them', sortable: true },
    { field: 'interviewee.review.workWithThemExcited', headerName: 'Work with them: excited', sortable: true },
    { field: 'interviewee.review.questionQuality', headerName: 'Question quality', sortable: true },
    { field: 'interviewee.review.questionHintQuality', headerName: 'Hint quality', sortable: true },
    { field: 'interviewee.review.audioQuality', headerName: 'Audio quality', sortable: true },
  ];

  return (
    <Suspense fallback={<div className="my-4">Loading...</div>}>
      <LazyLoadedAGGrid
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        className="ag-theme-alpine my-4 w-full text-xs"
        style={{ height: '500px' }}
        rowData={interviewer.interviews}
        columnDefs={columnDefs}
      />
      <div>
        <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
          Export to CSV
        </button>
      </div>
    </Suspense>
  );
};

export default InterviewerInterviewsPanel;
