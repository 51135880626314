import classNames from 'classnames';

const styles = {
  primary:
    'inline-flex items-center border border-transparent font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-center bg-blue-500 hover:bg-blue-700 focus:ring-blue-500',
  disabled:
    'inline-flex items-center border border-transparent font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-center bg-gray-300 cursor-not-allowed',
  confirm:
    'inline-flex items-center border border-transparent font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-center bg-green-600 hover:bg-green-800 focus:ring-green-500',
  danger:
    'inline-flex items-center border border-transparent font-medium rounded shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 text-center bg-red-500 hover:bg-red-700 focus:ring-red-500',
  secondary:
    'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
};

const sizes = {
  xs: 'px-2 py-1 text-xs',
  sm: 'px-2.5 py-1.5 text-sm',
  md: 'px-3 py-2 text-base-legacy',
  lg: 'px-3.5 py-2.5 text-lg',
  xl: 'px-4 py-3 text-xl',
  fill: 'px-3.5 py-2.5 w-full text-lg justify-center h-full',
};

type Props = {
  label?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: (value: unknown) => void;
  size?: keyof typeof sizes;
  style?: keyof typeof styles;
  fullWidthOnMobile?: boolean;
  className?: string;
};

export default function iioButton({
  label,
  onClick,
  type = 'button',
  disabled = false,
  size = 'md',
  style = 'primary',
  fullWidthOnMobile = false,
  className,
}: Props) {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames(
        fullWidthOnMobile && 'sm:justify-left h-full w-full justify-center sm:h-auto sm:w-auto',
        styles[disabled ? 'disabled' : style],
        sizes[size],
        className
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
