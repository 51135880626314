import { useEffect, useState } from 'react';

import { getUser, getUserClaimableRound, userHasRoundRole } from '../../components/Claims/ClaimsUtils';
import { User, UserClaimableRound } from '../../components/Claims/ClaimsTypes';
import ClaimPanel from '../../components/Claims/ClaimPanel';
import ClaimBanner from '../../components/Claims/ClaimBanner';

export type ClaimInterviewProps = {
  roundId: string;
};

const ClaimInterview = (props: ClaimInterviewProps) => {
  const { roundId } = props;
  const [userClaimableRound, setUserClaimableRound] = useState<UserClaimableRound>();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const updateUserClaimableRound = async () => {
      if (!roundId) {
        setUserClaimableRound(undefined);
        return;
      }
      try {
        const userClaimableRoundResult = await getUserClaimableRound(roundId);
        setUserClaimableRound(userClaimableRoundResult);
      } catch (err) {
        if (err.status === 404) {
          window.location.replace('/');
        }
      }
    };
    updateUserClaimableRound();
  }, [roundId]);

  useEffect(() => {
    const fetchUser = async () => setUser(await getUser());
    fetchUser();
  }, []);
  if (!userClaimableRound) return null;

  const isUserInterviewee = userHasRoundRole(user, userClaimableRound.round, 'interviewee');
  const isUserInterviewer = userHasRoundRole(user, userClaimableRound.round, 'interviewer');

  return (
    <>
      {isUserInterviewer && userClaimableRound.round.claimableWhileAssigned && <ClaimBanner />}
      <ClaimPanel
        userClaimableRound={userClaimableRound}
        isUserInterviewee={isUserInterviewee}
        isUserInterviewer={isUserInterviewer}
      />
    </>
  );
};

export default ClaimInterview;
