import { useState } from 'react';
import OnboardingModal from './OnboardingModal';
import { updateAppliedCompanies } from '../../../api/signups';

type OnboardingModalInputs = {
  onExperienceSubmit?: (engineerType: string, yoe: number) => Promise<void>;
  onCurrentCompanySubmit?: (company?: string, shouldAvoidCompany?: boolean, isHiringManager?: boolean) => Promise<void>;
  onAppliedCompaniesSubmit?: (appliedCompanies: string[]) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchCallback?: (value: string, showDefault: boolean) => Promise<any[]>;
  onOnboardingCompleted?: () => Promise<void>;
};

declare global {
  interface Window {
    showOnboardingModal: (inputs: OnboardingModalInputs) => void;
  }
}

const OnboardingModalContainer = () => {
  const [isOnboardingModalVisible, setIsOnboardingModalVisible] = useState(false);
  const [inputs, setInputs] = useState<OnboardingModalInputs>({});

  window.showOnboardingModal = (inputs: OnboardingModalInputs) => {
    setInputs(inputs);
    setIsOnboardingModalVisible(true);
  };

  return (
    <OnboardingModal
      onExperienceSubmit={inputs.onExperienceSubmit}
      onCurrentCompanySubmit={inputs.onCurrentCompanySubmit}
      onAppliedCompaniesSubmit={updateAppliedCompanies}
      searchCallback={inputs.searchCallback}
      open={isOnboardingModalVisible}
      setOpen={setIsOnboardingModalVisible}
      onOnboardingCompleted={inputs.onOnboardingCompleted}
    />
  );
};

export default OnboardingModalContainer;
