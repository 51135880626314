import React from 'react';

export type RefreshIconButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const RefreshIconButton = ({ onClick }: RefreshIconButtonProps) => (
  <button
    onClick={onClick}
    className="inline-flex items-center rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
  >
    <span>Refresh</span>
  </button>
);

export default RefreshIconButton;
