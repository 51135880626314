import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import RequestFormModal, { TRequestFormModalProps } from './RequestFormModal';
import { useUser } from '../../userContext';
import { Employer } from '../../types/Employer';
import { SingleActionModal } from '../Modals/SingleActionModal';
import { ChevronRightIcon, ExclamationIcon } from '@heroicons/react/outline';
import { AcademicCapIcon, BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import EligibilityCriteriaDetails from './EligibilityCriteriaDetails';
import LocationModePill from './LocationModePill';

export type TCompanyListItemProps = {
  company: Employer;
};

const showRequestModal = (props: TRequestFormModalProps) => NiceModal.show(RequestFormModal, props);

const showNotAcceptingIntroductionsModal = (companyName: string) =>
  NiceModal.show(SingleActionModal, {
    actionLabel: 'OK',
    title: `Introductions to ${companyName} are paused`,
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-50">
        <ExclamationIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
      </div>
    ),
    message: <p>Check back soon.</p>,
  });

const showIneligibleModal = () =>
  NiceModal.show(SingleActionModal, {
    actionLabel: 'OK',
    title: 'Sorry. You are not yet eligible for Company Introductions',
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">
        <ExclamationIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
      </div>
    ),
    message: <EligibilityCriteriaDetails />,
  });

const showTokensRequiredModal = () =>
  NiceModal.show(SingleActionModal, {
    actionLabel: 'OK',
    title: `You're out of tokens`,
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-50">
        <ExclamationIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
      </div>
    ),
    message: (
      <p>
        Head to the <a href="/dashboard/interviewee">Interviewee Dashboard</a> to purchase interviews and obtain a
        token.
      </p>
    ),
  });

function CompanyListItem(props: TCompanyListItemProps) {
  const { company } = props;
  const { user } = useUser();
  const handleRequestClick = async () => {
    if (user.isCompanyIntroductionsEligible) {
      if (!user._unusedCompanyIntroductionTokens) {
        showTokensRequiredModal();
        window.angular
          ?.element(document.body)
          .injector()
          .get('AnalyticsService')
          .track(
            window.CONFIG.const.analytics.eventNames
              .EVENT_REVENUE_COMPANY_INTRO_REQUEST_LISTING_CLICK_DENIED_INELIGIBLE,
            {
              employerId: company._id.toString(),
            }
          );
      } else if (company.accountSettings.companyIntroductions.participationStatus === 'AcceptingIntroductions') {
        window.angular
          ?.element(document.body)
          .injector()
          .get('AnalyticsService')
          .track(window.CONFIG.const.analytics.eventNames.EVENT_REVENUE_COMPANY_INTRO_REQUEST_LISTING_DISPLAY_FORM, {
            employerId: company._id.toString(),
          });
        showRequestModal({
          company: company,
          userId: user._id.toString(),
          firstName: user.firstName,
          lastName: user.lastName,
          linkedInHandle: user.linkedin.handle,
        });
      } else {
        window.angular
          ?.element(document.body)
          .injector()
          .get('AnalyticsService')
          .track(
            window.CONFIG.const.analytics
              .EVENT_REVENUE_COMPANY_INTRO_REQUEST_LISTING_CLICK_COMPANY_NOT_ACCEPTING_INTROS,
            {
              employerId: company._id.toString(),
            }
          );
        showNotAcceptingIntroductionsModal(company.companyName);
      }
    } else {
      window.angular
        ?.element(document.body)
        .injector()
        .get('AnalyticsService')
        .track(
          window.CONFIG.const.analytics.eventNames.EVENT_REVENUE_COMPANY_INTRO_REQUEST_LISTING_CLICK_DENIED_INELIGIBLE,
          {
            employerId: company._id.toString(),
          }
        );
      showIneligibleModal();
    }
  };

  return (
    <li className="flex flex-nowrap gap-2 px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div className="flex shrink-0 items-center">
        <button onClick={() => handleRequestClick()}>
          <img className="h-12 w-12" src={company.companyLogoSquareUrl} alt={company.companyName} />
        </button>
      </div>
      <div className="items-left flex flex-1 flex-col overflow-hidden md:gap-1">
        <div className="flex flex-row items-center gap-2">
          <button className="text-bold block max-w-full truncate hover:underline" onClick={() => handleRequestClick()}>
            <span className="text-lg">{company.companyName}</span>
          </button>
          {company.accountSettings.companyIntroductions.locationModes.map((locationMode: string) => (
            <LocationModePill key={locationMode} locationMode={locationMode} />
          ))}
        </div>
        <div className="flex flex-1 flex-col gap-1 text-sm text-gray-500 md:flex-row md:gap-4">
          <div className="flex flex-row flex-nowrap items-center gap-1 overflow-hidden">
            <BriefcaseIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span
              className="truncate"
              dangerouslySetInnerHTML={{
                __html: company.accountSettings.companyIntroductions.positionsShortDescription,
              }}
            />
          </div>
          <div className="flex flex-row flex-nowrap items-center gap-1 overflow-hidden">
            <AcademicCapIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span className="truncate">
              {company.accountSettings.companyIntroductions.positionLevelsShortDescription}
            </span>
          </div>
          <div className="flex flex-row flex-nowrap items-center gap-1 overflow-hidden">
            <LocationMarkerIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span className="truncate">{company.accountSettings.companyIntroductions.locationsShortDescription}</span>
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-center">
        <button onClick={() => handleRequestClick()}>
          <ChevronRightIcon className="h-7 w-7 rounded-full border-2 border-blue-500 bg-blue-50 text-blue-500 hover:border-blue-700 hover:bg-blue-100 hover:text-blue-700 focus:border-blue-700 focus:bg-blue-100 focus:text-blue-700" />
        </button>
      </div>
    </li>
  );
}

export default CompanyListItem;
