import React from 'react';

import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookPart } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookPart';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { BookTitle } from '@interviewingio/iio-react-component-lib';
import { LinkAsButton } from '@interviewingio/iio-react-component-lib';
import { Card } from '@interviewingio/iio-react-component-lib';
import '../BeyondCTCIBookStyles.css';

type BeyondCTCIBookPartPageProps = {
  partSlug: string;
};

const BeyondCTCIBookPartContent = ({ bookPart }: { bookPart: TBeyondCTCIBookPart }) => (
  <Card title={bookPart.attributes.Name}>
    <ul className="[&_ul]:list-[revert] list-outside list-disc pl-5">
      {bookPart.attributes.book_chapters.data.map((bookChapter: TBeyondCTCIBookChapter, j: number) => (
        <li className="open-book-list-item" key={j}>
          <div className="flex flex-col pl-1">
            <a href={`${window?.CONFIG.beyondCTCIBaseUrl}/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}`}>
              {bookChapter.attributes.Name}
            </a>
          </div>
        </li>
      ))}
    </ul>
  </Card>
);

const BeyondCTCIBookPartPage = ({ partSlug }: BeyondCTCIBookPartPageProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookSection, isLoading, isFetched } = useBeyondCTCIBookPart(partSlug);

  if (!isUserAllowed) return null;

  return (
    <div className="flex max-w-screen-lg justify-center gap-4">
      <div className="flex-1">
        <BookTitle />
        <div className="py-4">
          <LinkAsButton href={`${window?.CONFIG.beyondCTCIBaseUrl}`}>‹ Back to table of contents</LinkAsButton>
        </div>

        {isLoading && <p>Loading...</p>}
        {isFetched && <BeyondCTCIBookPartContent bookPart={bookSection} />}
      </div>
    </div>
  );
};

export default BeyondCTCIBookPartPage;
