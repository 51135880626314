import { Transition } from '@headlessui/react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import React, { Fragment, ReactElement, useCallback, useEffect, useReducer, useState } from 'react';

type ToastContent = {
  id?: string;
  primaryMessage: string;
  secondaryMessage?: string;
  type?: 'success' | 'failure';
  displayTimeout?: number;
};
type Action =
  | { type: 'addToast'; toastContent: ToastContent }
  | { type: 'clearToasts' }
  | { type: 'removeToast'; id: string };

export type Dispatch = (action: Action) => void;

type State = { toasts: ToastContent[] };
type ToastProviderProps = { children: React.ReactNode };

const ToastStateContext = React.createContext<{
  state: State;
  dispatch: Dispatch;
}>(null);

const ToastContainer = (props: { children: ReactElement }) => (
  <div
    aria-live="assertive"
    className="pointer-events-none fixed inset-0 flex items-end px-4 py-12 sm:items-start sm:p-12"
    style={{ zIndex: 9999999 }}
  >
    {props.children}
  </div>
);

const Toast = (props: ToastContent & { dispatch: Dispatch }) => {
  const { id, primaryMessage, secondaryMessage, type = 'success', displayTimeout } = props;
  const [isShown, setIsShown] = useState(true);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (displayTimeout) {
      timer = setTimeout(() => {
        setIsShown(false);
        props.dispatch({
          type: 'removeToast',
          id,
        });
      }, displayTimeout);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <Transition
      show={isShown}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0 translate-y-2 sm:translate-x-2"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === 'success' ? (
                <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
              ) : (
                <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{primaryMessage}</p>
              {secondaryMessage && <p className="mt-1 mb-0 text-sm text-gray-500">{secondaryMessage}</p>}
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="focus:ring-[#216ec4]-500 inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                style={{ backgroundColor: 'white' }}
                onClick={() => {
                  setIsShown(false);
                  props.dispatch({
                    type: 'removeToast',
                    id,
                  });
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

function toastReducer(state: State, action: Action): State {
  // const { type, toastContent } = action;
  switch (action.type) {
    case 'addToast': {
      const toast = { id: crypto.randomUUID(), ...action.toastContent };
      return { toasts: [...state.toasts, toast] };
    }
    case 'removeToast': {
      const toastId = action.id;
      return { toasts: state.toasts.filter((toast) => toast.id !== toastId) };
    }
    case 'clearToasts': {
      return { toasts: [] };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function ToastProvider({ children }: ToastProviderProps) {
  const [state, dispatch] = useReducer(toastReducer, { toasts: [] });

  // Use useCallback to ensure the dispatch function doesn't change on every render
  const memoizedDispatch = useCallback(dispatch, []);

  const value = { state, dispatch: memoizedDispatch };

  return (
    <ToastStateContext.Provider value={value}>
      {children}
      <ToastContainer>
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {state.toasts.map((toast) => (
            <Toast key={toast.id} dispatch={memoizedDispatch} {...toast} />
          ))}
        </div>
      </ToastContainer>
    </ToastStateContext.Provider>
  );
}

function useToasts() {
  const context = React.useContext(ToastStateContext);
  if (!context) {
    throw new Error('useToasts must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToasts };
