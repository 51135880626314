import { useQuery } from '@tanstack/react-query';

import axios from '../../utils/axios';

function CouponAndFreeSessionBanners() {
  const userCouponsAndFreeSessions = useQuery({
    queryKey: ['userCouponsAndFreeSessions'],
    queryFn: (): Promise<string | null> =>
      axios.get('api/users/me/').then((response) => {
        const coupon = response.data?.practice?.coupons?.sort(
          (couponA: { absoluteOffOrder: number }, couponB: { absoluteOffOrder: number }) =>
            couponB.absoluteOffOrder - couponA.absoluteOffOrder
        )[0];
        if (!coupon) {
          return null;
        }
        let platformBannerMessage = `You have a coupon available for $${
          coupon.absoluteOffOrder / 100
        } off when purchasing your next premium interview.`;
        if (response.data?.practice?.coupons.length > 1) {
          const additionalCoupons = response.data?.practice?.coupons.length - 1;
          platformBannerMessage += ` Plus ${additionalCoupons} additional coupon${
            additionalCoupons > 1 ? 's' : ''
          } in waiting.`;
        }
        platformBannerMessage += ` Nice!`;

        return platformBannerMessage;
      }),
  });

  if (!userCouponsAndFreeSessions.isSuccess || !userCouponsAndFreeSessions.data) {
    return null;
  }

  return (
    <div className="mb-4 flex grow flex-col rounded bg-iio-success-50 sm:flex-row sm:gap-8 sm:p-3 sm:align-middle">
      <p className="text-md flex flex-col font-sans sm:text-left lg:text-lg">{userCouponsAndFreeSessions.data}</p>
    </div>
  );
}

export default CouponAndFreeSessionBanners;
