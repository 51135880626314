import { useQuery } from '@tanstack/react-query';

import TextPanel from './TextPanel';
import axios from '../../utils/axios';
import RequestListItem from './IntroductionRequestListItem';

export type TCompanyIntroductionRequest = {
  _id: string;
  user: string;
  status: string;
  token: string;
  company: {
    _id: string;
    companyId: string;
    companyName: string;
    companyLogoSquareUrl: string;
  };
  companyListing: {
    positionsShortDescription: string;
    positionLevelsShortDescription: string;
    locationsShortDescription: string;
    locationModes: string[];
  };
  createdAt: Date;
  updatedAt: Date;
};

const IntroductionRequestListItems = ({
  introductionRequests,
}: {
  introductionRequests: TCompanyIntroductionRequest[];
}) => (
  <div className="my-4 overflow-hidden bg-white shadow sm:rounded-md">
    <ul role="list" className="divide-y divide-gray-200" style={{ marginBottom: 0, marginTop: 0, paddingLeft: 0 }}>
      {introductionRequests.map((introductionRequest) => (
        <RequestListItem introductionRequest={introductionRequest} key={introductionRequest._id} />
      ))}
    </ul>
  </div>
);

const IntroductionRequestList = () => {
  const introductionRequests = useQuery({
    queryKey: ['companyIntroductionsRequestList'],
    queryFn: (): Promise<TCompanyIntroductionRequest[]> =>
      axios.get('api/company-introductions/introduction-request/').then((response) =>
        response.data
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((introductionRequest: any) => ({
            ...introductionRequest,
            ...{
              createdAt: new Date(introductionRequest.createdAt),
              updatedAt: new Date(introductionRequest.updatedAt),
            },
          }))
          .sort(
            (a: TCompanyIntroductionRequest, b: TCompanyIntroductionRequest) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          )
      ),
  });

  if (introductionRequests.isLoading) {
    return <TextPanel text="Loading ..." />;
  }
  if (introductionRequests.isError) {
    return <TextPanel text="An unexpected error occurred. Please try again later" />;
  }

  if (introductionRequests.data.length) {
    return <IntroductionRequestListItems introductionRequests={introductionRequests.data} />;
  } else {
    return <TextPanel text="Request an introduction, and we'll keep track of it right here." />;
  }
};

export default IntroductionRequestList;
