import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import classNames from 'classnames';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type TLazyLoadedAGGridProps<T> = AgGridReactProps<T> & {
  className?: string;
  style?: React.CSSProperties;
};

const LazyLoadedAGGrid = <T,>(props: TLazyLoadedAGGridProps<T>) => {
  const { className, style, ...agGridProps } = props;
  const gridClassName = classNames('ag-theme-alpine', className);

  return (
    <div className={gridClassName} style={style}>
      <AgGridReact {...agGridProps} />
    </div>
  );
};

export default LazyLoadedAGGrid;
