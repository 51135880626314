import { Fragment, useEffect, useState } from 'react';
import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import classNames from 'classnames';
import { v4 } from 'uuid';

import affirmImage from '../../../assets/images/affirm.png';
import Button from '../../../elements/Button';
import { TDedicatedCoachingProductOrgCategory, TProduct, TSale } from '../../../types/ProductCatalog';
import { useProductCatalog } from '../../../hooks/useProductCatalog';
import { DedicatedCoachingSaleBanner } from '../DedicatedCoachingSaleBanner/DedicatedCoachingSaleBanner';

const sortByOrder = (a: [unknown, unknown, number], b: [unknown, unknown, number]) => a[2] - b[2];

type TOrgCategoryQuantities = Record<number, [number, string, number]>;
type TCategoryFocuses = Record<string, [string, string, number]>;
type TSKUMatrix = Record<number, Record<string, TProduct>>;

function trackProductViewed(product: TProduct, sale?: TSale) {
  let itemPrice: number;
  let absDiscount: number;
  if (sale) {
    absDiscount =
      sale.productDiscounts.find((productDiscount: { absDiscount: number; skuIds: string[] }) =>
        productDiscount.skuIds.includes(product.skuId)
      )?.absDiscount || 0;
    itemPrice = (product.unitPrice - absDiscount) / 100;
  } else {
    itemPrice = product.unitPrice / 100;
  }
  window.angular
    ?.element(document.body)
    .injector()
    .get('AnalyticsService')
    .track(window.CONFIG.const.analytics.eventNames.EVENT_ECOMMERCE_PRODUCT_VIEWED, {
      coupon: null,
      currency: 'USD',
      discount: absDiscount,
      products: [
        {
          coupon: null,
          currency: 'USD',
          discount: absDiscount,
          sku: product.skuId,
          name: 'Dedicated Coaching',
          price: itemPrice,
        },
      ],
      total: itemPrice,
    });
}

const formatProductNormalPrice = (course: TProduct) =>
  '$' + (course.unitPrice / 100).toLocaleString('en-US', { maximumFractionDigits: 2 });

const formatProductSalePrice = (product: TProduct, sale: TSale) => {
  const { absDiscount } = sale.productDiscounts.find((productDiscount: { absDiscount: number; skuIds: string[] }) =>
    productDiscount.skuIds.includes(product.skuId)
  );
  const saleUnitPrice = product.unitPrice - absDiscount;
  return '$' + (saleUnitPrice / 100).toLocaleString('en-US', { maximumFractionDigits: 2 });
};

export type MentorLink = {
  userId: string;
  pseudonym: string;
  orgIds: string[];
} | null;

export type TDedicatedCoachingModalProps = {
  orgCategory: TDedicatedCoachingProductOrgCategory;
  showBundleCheckout: (
    dedicatedCoachingSku: { catalogItem: TProduct; mentorLink: MentorLink; dcCourseSurveyLinkId: string },
    onPaymentConfirmation: () => void
  ) => void;
  onPaymentConfirmation: (arg0: TProduct | null, arg1: MentorLink, arg2: string) => () => void;
  mentorLink?: MentorLink;
};

export const DedicatedCoachingModal = NiceModal.create((props: TDedicatedCoachingModalProps) => {
  const { onPaymentConfirmation, orgCategory, showBundleCheckout, mentorLink } = props;
  const dcCourseSurveyLinkId = v4();
  const modal = useModal();
  const { data: productCatalog } = useProductCatalog();
  const now = new Date();
  const activeSale =
    now >= productCatalog?.sale?.startDate && now < productCatalog?.sale?.endDate ? productCatalog.sale : null;

  const orgCategoryQuantities: TOrgCategoryQuantities = {};
  const categoryFocuses: TCategoryFocuses = {};

  const quantityFocusSKUMatrix: TSKUMatrix = orgCategory.products.reduce((acc, product) => {
    if (!acc[product.dedicatedCoachingMetadata.quantity]) {
      acc[product.dedicatedCoachingMetadata.quantity] = {};
    }
    acc[product.dedicatedCoachingMetadata.quantity][product.dedicatedCoachingMetadata.focus] = product;

    orgCategoryQuantities[product.dedicatedCoachingMetadata.quantity] = [
      product.dedicatedCoachingMetadata.quantity,
      product.dedicatedCoachingMetadata.quantity.toString(),
      product.dedicatedCoachingMetadata.quantity,
    ];
    categoryFocuses[product.dedicatedCoachingMetadata.focus] = [
      product.dedicatedCoachingMetadata.focus,
      product.dedicatedCoachingMetadata.focusLabel,
      product.dedicatedCoachingMetadata.focusOrder,
    ];
    return acc;
  }, {} as TSKUMatrix);

  const orgCategoryQuantityValues = Object.values(orgCategoryQuantities)
    .sort(sortByOrder)
    .map((quantityOrderingData) => quantityOrderingData[0]);
  const categoryFocusesValues = Object.values(categoryFocuses)
    .sort(sortByOrder)
    .map((focusOrderingData) => focusOrderingData[0]);

  const [selectedQuantity, setSelectedQuantity] = useState<number>(
    activeSale ? orgCategoryQuantityValues[1] : orgCategoryQuantityValues[0]
  );
  const [selectedFocus, setSelectedFocus] = useState<string>(categoryFocusesValues[0]);

  useEffect(() => {
    const product = quantityFocusSKUMatrix[selectedQuantity][selectedFocus];
    trackProductViewed(product, activeSale);
  }, [selectedQuantity, selectedFocus]);

  return (
    <Transition.Root as={Fragment} show={modal.visible} afterLeave={() => modal.remove()}>
      <Dialog as="div" className="relative z-10" onClose={() => modal.hide()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="flex w-full transform flex-col text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                <div className="relative flex w-full flex-col items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                  {activeSale && (
                    <div className="mb-2 w-full">
                      <DedicatedCoachingSaleBanner activeSale={activeSale} showDescription={false} />
                    </div>
                  )}
                  <div className="relative">
                    <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                      <div
                        className="aspect-w-2 aspect-h-3 col-span-1 h-[325px] overflow-hidden rounded-lg border-[1px] border-solid border-[#dedede] bg-gray-100 px-8 sm:col-span-5 lg:col-span-5"
                        style={{
                          background: '#F7F7F7',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {quantityFocusSKUMatrix[selectedQuantity][selectedFocus].dedicatedCoachingMetadata ? (
                          <img
                            src={
                              quantityFocusSKUMatrix[selectedQuantity][selectedFocus].dedicatedCoachingMetadata
                                ?.imageSrc
                            }
                            className="h-full w-full shrink object-contain object-center p-6 md:py-14"
                          />
                        ) : (
                          <img src={orgCategory.image} className="object-cover object-center" />
                        )}
                      </div>
                      <div className="relative col-span-1 sm:col-span-7">
                        <button
                          type="button"
                          className="absolute top-0 right-0 text-gray-400 hover:text-gray-500"
                          onClick={() => modal.hide()}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                        <h2 className="font-['Inter'] text-xl font-medium text-gray-900 sm:pr-12">
                          {orgCategory.name[0].toUpperCase() + orgCategory.name.slice(1)}
                          {mentorLink?.pseudonym && ' with ' + mentorLink.pseudonym}
                        </h2>
                        <section aria-labelledby="information-heading" className="mt-2">
                          {activeSale?.productDiscounts
                            .flatMap((productDiscount: { skuIds: string[] }) => productDiscount.skuIds)
                            .includes(quantityFocusSKUMatrix[selectedQuantity][selectedFocus].skuId) ? (
                            <div>
                              <span
                                style={{
                                  verticalAlign: 'top',
                                  fontFamily: 'Inter',
                                  fontWeight: '400',
                                  fontSize: '22px',
                                  textDecorationLine: 'line-through',
                                  color: '#8B8B8B',
                                }}
                              >
                                {formatProductNormalPrice(quantityFocusSKUMatrix[selectedQuantity][selectedFocus])}
                              </span>
                              <span className="ml-4 inline-block align-bottom font-[Inter] text-[28px] font-bold text-black">
                                {formatProductSalePrice(
                                  quantityFocusSKUMatrix[selectedQuantity][selectedFocus],
                                  activeSale
                                )}
                              </span>
                            </div>
                          ) : (
                            <p className="font-[Inter] text-[22px] font-bold text-black">
                              {formatProductNormalPrice(quantityFocusSKUMatrix[selectedQuantity][selectedFocus])}
                            </p>
                          )}
                        </section>

                        <section aria-labelledby="options-heading" className="mt-2">
                          <form>
                            <div className="mt-4">
                              <div className="flex items-center justify-between">
                                <h4 className="text-sm font-medium text-gray-900"># of Sessions</h4>
                              </div>

                              <RadioGroup
                                value={selectedQuantity}
                                onChange={setSelectedQuantity}
                                className="justify-left mt-2"
                              >
                                <RadioGroup.Label className="sr-only">Select number of sessions</RadioGroup.Label>
                                <div className="flex flex-wrap gap-4">
                                  {orgCategoryQuantityValues.map((quantity) => (
                                    <RadioGroup.Option
                                      key={quantity}
                                      value={quantity}
                                      className={({ checked }) =>
                                        classNames(
                                          activeSale &&
                                            activeSale.productDiscounts
                                              .flatMap(
                                                (productDiscount: { skuIds: string[] }) => productDiscount.skuIds
                                              )
                                              .includes(quantityFocusSKUMatrix[quantity][selectedFocus].skuId)
                                            ? 'outline outline-2 outline-offset-2 outline-lime-600'
                                            : '',
                                          checked
                                            ? 'border-transparent bg-[#0027F5] text-white hover:bg-[#0027F5]/70'
                                            : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                                          'shrink cursor-pointer items-center rounded-md border py-3 px-3 text-sm font-medium'
                                        )
                                      }
                                    >
                                      <RadioGroup.Label as="span">
                                        {orgCategoryQuantities[quantity][1]}
                                      </RadioGroup.Label>
                                    </RadioGroup.Option>
                                  ))}
                                </div>
                              </RadioGroup>
                            </div>

                            <div className="mt-4">
                              <div className="flex items-center justify-between">
                                <h4 className="text-sm font-medium text-gray-900">Focus</h4>
                              </div>

                              <RadioGroup
                                value={selectedFocus}
                                onChange={setSelectedFocus}
                                className="justify-left mt-2"
                              >
                                <RadioGroup.Label className="sr-only">Select a focus</RadioGroup.Label>
                                <div className="flex flex-wrap gap-4">
                                  {categoryFocusesValues.map((focus) => (
                                    <RadioGroup.Option
                                      key={focus}
                                      value={focus}
                                      className={({ checked }) =>
                                        classNames(
                                          activeSale &&
                                            activeSale.productDiscounts
                                              .flatMap(
                                                (productDiscount: { skuIds: string[] }) => productDiscount.skuIds
                                              )
                                              .includes(quantityFocusSKUMatrix[selectedQuantity][focus].skuId)
                                            ? 'outline outline-2 outline-offset-2 outline-lime-600'
                                            : '',
                                          checked
                                            ? 'border-transparent bg-[#0027F5] text-white hover:bg-[#0027F5]/70'
                                            : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                                          'shrink cursor-pointer items-center rounded-md border py-3 px-3 text-sm font-medium'
                                        )
                                      }
                                    >
                                      <RadioGroup.Label as="span">{categoryFocuses[focus][1]}</RadioGroup.Label>
                                    </RadioGroup.Option>
                                  ))}
                                </div>
                              </RadioGroup>
                            </div>

                            {activeSale?.productDiscounts
                              .flatMap((productDiscount: { skuIds: string[] }) => productDiscount.skuIds)
                              .includes(quantityFocusSKUMatrix[selectedQuantity][selectedFocus].skuId) && (
                              <div className="mt-4 font-sans text-2xl font-light tracking-tighter text-lime-600">
                                {activeSale.cardCTA}
                              </div>
                            )}

                            {/* List of bullets */}
                            <div className="mt-2 pl-4 font-['Inter'] text-[14px] text-black">
                              <ul className="list-disc">
                                {quantityFocusSKUMatrix[selectedQuantity][
                                  selectedFocus
                                ].dedicatedCoachingMetadata.modalDescription.map((bullet: string, i: number) => (
                                  <li key={i}>{bullet}</li>
                                ))}
                              </ul>
                            </div>
                            <Button
                              label="Add to cart"
                              size="fill"
                              className="mt-6 flex w-full items-center justify-center rounded-md border border-transparent bg-[#0027F5] py-3 px-8 text-base font-medium text-white hover:bg-[#0027F5]/70 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => {
                                modal.hide();
                                showBundleCheckout(
                                  {
                                    catalogItem: quantityFocusSKUMatrix[selectedQuantity][selectedFocus],
                                    mentorLink,
                                    dcCourseSurveyLinkId,
                                  },
                                  onPaymentConfirmation(
                                    quantityFocusSKUMatrix[selectedQuantity][selectedFocus],
                                    mentorLink,
                                    dcCourseSurveyLinkId
                                  )
                                );
                              }}
                            />
                            <div className="col-span-1 my-0 mt-2 sm:col-span-7">
                              <a href="https://www.affirm.com/how-it-works" target="_blank">
                                <img src={affirmImage} className="mx-auto w-[68px]" />
                              </a>
                            </div>
                          </form>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
