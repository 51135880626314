import axios from '../utils/axios';

async function updateAppliedCompanies(appliedCompanies: string[]) {
  try {
    await axios.post('api/signups/appliedCompanies', { appliedCompanies });
  } catch (err) {
    return Promise.resolve(err);
  }
}

export { updateAppliedCompanies };
