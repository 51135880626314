import { Dispatch, SetStateAction } from 'react';
import Select, { iioSelectOption } from '../../../../elements/Select';
import { CashIcon, EmojiHappyIcon, LocationMarkerIcon, NewspaperIcon, PhoneIcon } from '@heroicons/react/outline';

type UrgencyProps = {
  urgency: string;
  setUrgency: Dispatch<SetStateAction<string>>;
};

const urgencies: iioSelectOption<string>[] = [
  {
    value: 'offer',
    label: 'I already have an offer',
    icon: <CashIcon className="w-6" />,
  },
  {
    value: 'onsite',
    label: 'Completed/scheduled onsite',
    icon: <LocationMarkerIcon className="w-6" />,
  },
  {
    value: 'interview',
    label: 'Completed/scheduled phone screen',
    icon: <PhoneIcon className="w-6" />,
  },
  {
    value: 'notTalking',
    label: 'Just starting',
    icon: <NewspaperIcon className="w-6" />,
  },
  {
    value: 'notLooking',
    label: "I'm not looking for a job",
    icon: <EmojiHappyIcon className="w-6" />,
  },
];

const placeholder = 'Select...';

const Urgency = ({ urgency, setUrgency }: UrgencyProps) => (
  <div className="flex h-full flex-col items-center">
    <div className="h-full w-full">
      <div className="mb-6">
        <Select
          label="Where are you in your job search?"
          options={urgencies}
          onChange={setUrgency}
          selected={urgencies.find((option) => option.value === urgency)}
          placeholder={placeholder}
        />
      </div>
    </div>
  </div>
);

export default Urgency;
