import React, { useState, useEffect, useRef, SyntheticEvent, ChangeEvent, KeyboardEvent } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import http from '../../utils/axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Logo from './Logo';

type MessageType = {
  content: string;
  role: string;
};
type ClassNameType = {
  className?: string;
};
type DisplayMessageType = MessageType & ClassNameType;
type MutationType = { messages: MessageType[]; padUrl: string; qIndex: number };
type CreatePadType = {
  data: {
    url?: string;
    qIndex?: number;
    question?: string;
  };
};

const Message = ({ content, role, className }: DisplayMessageType) =>
  role === 'assistant' ? (
    <div
      className={`mr-0 mt-1 ml-6 w-auto border-l-2 border-[#ffd829] bg-[#30343a] px-4 py-2 text-white ${
        className || ''
      } grid grid-cols-12`}
    >
      <div className="ol-span-1 hidden overflow-hidden pt-1 lg:block">
        <div className="h-6 w-6 rounded-full bg-[#ffd829] py-1 px-1 pl-[.20rem] ">
          <Logo />
        </div>
      </div>
      <div className="col-span-11 overflow-hidden pl-1">
        <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
      </div>
    </div>
  ) : (
    <div
      className={`mr-0 mt-1 ml-6 w-auto overflow-hidden border-l-2 border-white bg-[#30343a] px-4 py-2 text-white ${
        className || ''
      } grid grid-cols-12`}
    >
      <div className="col-span-1 hidden overflow-hidden lg:block">
        <div className=" h-6 w-6 rounded-full bg-gray-100 text-center text-lg font-semibold leading-6 text-black ">
          #
        </div>
      </div>
      <div className="col-span-11 pl-1">{content}</div>
    </div>
  );

const Working = () => (
  <div className={`mr-0 mt-1 flex w-auto rounded bg-transparent px-4 py-2`}>
    <span className="mr-1 mt-1 h-2 w-2 animate-[bounce_.5s_infinite_0s] rounded-full bg-green-300"></span>
    <span className="mr-1 mt-1 h-2 w-2 animate-[bounce_.5s_infinite_.10s] rounded-full bg-green-300"></span>
    <span className="mr-1 mt-1 h-2 w-2 animate-[bounce_.5s_infinite_.20s] rounded-full bg-green-300"></span>
  </div>
);

const InterviewAIPage = () => {
  const chatRef = useRef(null);
  const [difficulty, setDifficulty] = useState<string>('any');
  const [githubQuestion, setGithubQuestion] = useState<string>('');
  const create_url = `/api/interviews/ai/create?difficulty=${difficulty}`;
  const message_url = '/api/interviews/ai/sendMessage';
  const {
    data,
    //isInitialLoading: isLoadingExisting,
    isLoading,
    //refetch,
  } = useQuery(['iframe_url', difficulty], () => http.get<undefined, CreatePadType>(create_url));
  const [messageInput, setMessageInput] = useState<string>('');
  const [messages, setMessages] = useState<MessageType[]>([
    {
      content: `Welcome to your interview! I’ve dropped a question for you in the coding environment to the left. Please choose the programming language you’d like to work in from the dropdown.

Please treat this more like an interviewer with a human rather than a LeetCode session — ask me questions and discuss your thought process with me in the chat. I’ll give you small “nudges” if you’re blocked, and I’ll give you feedback at the end.`,
      role: 'assistant',
    },
  ]);

  const question_url = '/api/interviews/ai/test';
  useEffect(() => {
    try {
      http.get(question_url).then((res) => {
        const newMessages = res.data;
        // eslint-disable-next-line no-console
        console.log(newMessages.content);
        setGithubQuestion(newMessages.content);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }, [setGithubQuestion]);

  const mutation = useMutation({
    mutationFn: (newMessage: MutationType) => http.post(message_url, newMessage, { timeout: 60000 }),
    onSuccess: (data, variables) => {
      const newMessages = data?.data?.newMessages;
      setMessages([...variables.messages, ...newMessages]);
    },
  });

  useEffect(() => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const { url = undefined, qIndex = undefined, question = '' } = data?.data || {};
  const fullURL = `${url}?iio&name=${encodeURIComponent('user')}&color=000000&contents=${encodeURIComponent(
    'Welcome to AI Interviewer!'
  )}`.replace('coderpad.io', 'app.coderpad.io');

  const updateMessageInput = (event: ChangeEvent) => {
    // @ts-ignore
    setMessageInput(event?.target?.value);
  };

  const sendMessage = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (!isLoading && url && fullURL && qIndex !== undefined && !mutation.isLoading && messageInput) {
      const newMessage = { content: messageInput, role: 'user' };
      setMessages([...messages, newMessage]);
      mutation.mutate({ messages: [...messages, newMessage], padUrl: url, qIndex });
      setMessageInput('');
    }
  };

  const sendCommand = async (message: string) => {
    if (!isLoading && url && fullURL && qIndex !== undefined && !mutation.isLoading && message) {
      const newMessage = { content: message, role: 'user' };
      setMessages([...messages, newMessage]);
      mutation.mutate({ messages: [...messages, newMessage], padUrl: url, qIndex });
    }
  };

  const addMessageWithoutAI = async (newMessages: MessageType[]) => {
    if (
      !isLoading &&
      url &&
      fullURL &&
      qIndex !== undefined &&
      !mutation.isLoading &&
      newMessages &&
      newMessages?.length > 0
    ) {
      setMessages([...messages, ...newMessages]);
    }
  };

  const getHelp = () => {
    sendCommand(`Can you please offer assistance?`);
  };

  const endInterview = () => {
    sendCommand(`I'd like to end the interview. Please offer feedback and rate my performance.`);
  };

  const viewQuestion = () => {
    addMessageWithoutAI([
      { content: 'Can you share the question again?', role: 'user' },
      { content: question, role: 'assistant' },
    ]);
  };

  const checkKeyPress = (e: KeyboardEvent) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      sendMessage(e);
    }
  };

  const changeDifficulty = (event: ChangeEvent) => {
    // @ts-ignore
    const value = event?.target?.value;
    if (value !== difficulty && !isLoading) {
      const confirmed = confirm(
        'Selecting a different difficulty level will end your current session and select a new interview question.'
      );
      // Setting value here will  cause the `usequery` to automatically update based on its dependencty `difficulty` now changing.
      if (confirmed) {
        setDifficulty(value);
      }
    }
  };

  return (
    <div className="sticky m-0 flex overflow-hidden bg-[#1E2126] p-0">
      <div className="h-[100vh] w-[60%] p-0">
        {!isLoading && url && fullURL && <iframe src={fullURL} className="h-[100vh] w-full" id="ai_coder_pad"></iframe>}
      </div>
      <div>{githubQuestion}</div>
      <div className="flex h-[100vh] w-[40%] flex-col border-l-2 border-[#30343a] bg-[#1E2126]">
        <div className="grid h-12 w-full flex-none grid-flow-row grid-cols-12 overflow-hidden border-b-2 border-[#30343a] px-3 py-4 text-base text-white">
          <div className="col-span-6 h-auto bg-transparent leading-6 ">AI Interviewer</div>
          <div className="col-span-0 justify-end bg-transparent p-0 lg:col-span-6">
            <select
              className="float-right h-[25px] w-auto !bg-[#30343a] p-0 pr-8 pl-3 text-left text-sm !text-white lg:min-w-[120px]"
              style={{ height: '25px' }}
              onChange={changeDifficulty}
              defaultValue={'any'}
              disabled={isLoading}
              value={difficulty}
            >
              <option value="any" selected={difficulty === 'any'}>
                Any Difficulty
              </option>
              <option value="easy" selected={difficulty === 'easy'}>
                Easy
              </option>
              <option value="medium" selected={difficulty === 'medium'}>
                Medium
              </option>
              <option value="hard" selected={difficulty === 'hard'}>
                Hard
              </option>
            </select>
            <select
              className="ml-2 hidden h-[25px] w-[100px] !bg-[#30343a] p-0 pr-8 text-sm !text-white"
              style={{ height: '25px' }}
              disabled={true}
              defaultValue={'strict'}
            >
              <option value="strict">Strict</option>
            </select>
          </div>
        </div>
        <div className="w-full flex-grow overflow-y-auto overflow-x-hidden py-4 pb-12">
          {messages.map((item, idx) => (
            <Message content={item.content} role={item.role} key={`${idx}_message`} />
          ))}
          {mutation.isLoading ? (
            <div className={`mr-0 mt-1 ml-6 w-auto rounded bg-[#30343a] p-0`}>
              <Working />
            </div>
          ) : null}
          <div ref={chatRef}></div>
        </div>
        <div className="h-[110px] w-full flex-none text-white">
          <div className="fixed right-0 z-50 -mt-[33px] h-[30px] w-auto bg-transparent pr-5 pt-1">
            <a
              href="https://iiosurveys.typeform.com/to/UtCEsAF0"
              target="_blank"
              className="rounded bg-[#ffd829] px-2 py-1 text-[.6rem] font-normal text-black"
            >
              Leave feedback
            </a>
          </div>
          <textarea
            className="inset-0 m-0 h-[72px] w-full border-0 !bg-[#30343a] px-2 text-sm text-white ring-0 ring-transparent hover:ring-transparent focus:ring-transparent active:ring-transparent"
            style={{ backgroundColor: '#444 !important', height: '72px', minHeight: '72px' }}
            id="message_body"
            value={messageInput}
            onChange={updateMessageInput}
            onKeyDown={checkKeyPress}
            placeholder="Send message"
          />
          <div className="flex w-full flex-row">
            <button
              className="h-[38px] flex-grow content-center bg-[#30343a] px-2 text-center hover:bg-[#3e434a]"
              onClick={viewQuestion}
              disabled={mutation.isLoading}
            >
              <div className="mx-auto w-auto text-base font-normal ">
                {mutation.isLoading ? <Working /> : 'Repeat Question'}
              </div>
            </button>
            <button
              className="mx-1 h-[38px] flex-shrink content-center bg-[#30343a] px-6 text-center hover:bg-[#3e434a]"
              onClick={getHelp}
              disabled={mutation.isLoading}
            >
              <div className="mx-auto w-auto text-base font-normal ">{mutation.isLoading ? <Working /> : 'Hint'}</div>
            </button>
            <button
              className="h-[38px] min-w-[25%] flex-grow content-center  bg-red-700 px-2 text-center hover:bg-red-600"
              onClick={endInterview}
              disabled={mutation.isLoading}
            >
              <div className="mx-auto w-auto text-base font-normal ">
                {mutation.isLoading ? <Working /> : 'End interview'}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewAIPage;
