import { Dispatch, SetStateAction } from 'react';
import Select, { iioSelectOption } from '../../../../elements/Select';

type ExperienceProps = {
  YOE: number;
  setYOE: Dispatch<SetStateAction<number>>;
};

const student = { label: 'Student', value: -1 };
const notAnEngOrZero = { label: '0 (or not a software engineer)', value: 0 };
const twentyPlus = { label: '20+', value: 20 };
const yearsOfExperienceOptions: iioSelectOption<number | string>[] = [
  student,
  notAnEngOrZero,
  ...[...Array(19).keys()].map((i) => ({ label: String(i + 1), value: i + 1 })),
  twentyPlus,
];

const placeholder = 'Select...';

const Experience = ({ YOE, setYOE }: ExperienceProps) => (
  <div className="flex h-full flex-col items-center">
    <div className="h-full w-full">
      <div className="mb-6">
        <Select
          label="How many years of experience do you have as a software engineer?"
          options={yearsOfExperienceOptions}
          onChange={setYOE}
          selected={yearsOfExperienceOptions.find((option) => option.value === YOE)}
          placeholder={placeholder}
        />
      </div>
    </div>
  </div>
);

export default Experience;
