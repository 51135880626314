import NiceModal from '@ebay/nice-modal-react';
import { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axios';
import { ReplayTranscriptEditorModal } from './ReplayTranscriptEditorModal';
import { useQuery } from '@tanstack/react-query';
import { TrashIcon } from '@heroicons/react/outline';

import axios from 'axios';
import { DeleteTranscriptModal } from './DeleteTranscriptModal';
import { getUser } from '../Claims/ClaimsUtils';

type shareReplayToggleProps = {
  interview: Record<string, any>;
  editted: boolean;
  toggle: (enabled: boolean, editted: boolean) => Promise<void>;
};

const ShareReplayToggle = (props: shareReplayToggleProps) => {

  const { interview, editted, toggle } = props;

  const [enabled, setEnabled] = useState(false);

  const toggleValue = async (newValue: boolean) => {
    try {
      setEnabled(newValue);
      await toggle(newValue, editted);
    } catch {
      // Revert back to old value if request fails
      setEnabled(!newValue);
    }
  };

  useEffect(() => {

    const init = async () => {

      const user = await getUser();

      let obj = [interview.interviewee, interview.interviewer].find(obj => obj.user === user._id);
      if (!obj) obj = interview.interviewer;

      const { wantsPublic, wantsEdittedPublic } = obj;
      if (editted) {
        setEnabled(wantsEdittedPublic);
      } else {
        setEnabled(!!wantsPublic);
      }

    }

    init();

  }, [editted]);

  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div className="flex">
        <span className="ml-2 text-sm font-medium text-white mr-2">
          Share {editted ? "edited" : "original"} replay?
        </span>
        <label className="inline-flex relative items-center mr-5 cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={enabled}
            readOnly
          />
          <div
            onClick={() => { toggleValue(!enabled) }}
            className="w-11 h-6 bg-red rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
          ></div>
        </label>
      </div>
    </div>
  );
}


const LoadingSpinner: React.FC = () => (
  <div role="status">
    <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span className="sr-only">Loading...</span>
  </div>
);

type showTranscriptEditModalProps = {
  sourceTranscript: Record<string, any>;
  fetchData: () => Promise<void>;
  interview: Record<string, any>;
};

const showTranscriptEditModal = (props: showTranscriptEditModalProps) => {
  NiceModal.show(ReplayTranscriptEditorModal, props);
};

type showDeleteTranscriptModalProps = {
  edittedTranscript: Record<string, any>;
  fetchData: () => Promise<void>;
  interview: Record<string, any>;
};

const showDeleteTranscriptModal = (props: showDeleteTranscriptModalProps) => {
  NiceModal.show(DeleteTranscriptModal, {
    edittedTranscript: props.edittedTranscript,
    fetchData: props.fetchData,
    interview: props.interview,
  })
};

type TranscriptSelectorProps = {
  interview: Record<string, any>;
  fetchData: () => Promise<void>;
  refetch: () => Promise<void>;
  updateTranscriptIdx: (idx: number) => void;
  transcriptIdx: number;
};

const TranscriptSelector : React.FC<TranscriptSelectorProps> = ({ transcriptIdx, updateTranscriptIdx, interview, fetchData, refetch }) => {
  const sourceTranscript = JSON.parse(interview?.transcript?.find((t: string) => {
    const transcriptObj = JSON.parse(t);
    return !!transcriptObj["isOriginal"];
  }));


  let edittedTranscript: Record<string, any>;
  try {
    edittedTranscript = JSON.parse(interview?.transcript?.find((t: string) => {
      const transcriptObj = JSON.parse(t);
      return transcriptObj["isOriginal"] !== true;
    }));
  } catch {
    edittedTranscript = undefined;
  }

  const transcriptCount = interview?.transcript?.length || 0;

  const [voiceSwapStatus, setShowTranscripts] = useState("untried");

  useQuery({
    queryKey: ['swappedVoice'],
    queryFn: async () => {
      const id = edittedTranscript?.id;
      if (id) {
        const microserviceLink = `https://ffmpeg-microservice.onrender.com/swapVoice/${id}`;
        const signedUrlResponse = await axios.get(microserviceLink);
        const signedUrl = signedUrlResponse.data.url;
        if (signedUrl) {
          const response = await axios.get(signedUrl);
          if (response.status === 200) {
            setShowTranscripts("succeeded");
          } else {
            setShowTranscripts("failed");
          }
        }
      }
      return true;
    },
    refetchInterval: 2000,
    enabled: voiceSwapStatus !== "succeeded"
  });


  return (
    <div className="relative inline-block text-left flex">
      {voiceSwapStatus === "succeeded" && transcriptCount > 1 && interview?.transcript?.map((transcript: string, idx: number) => (
        <button
          key={idx}
          onClick={() => updateTranscriptIdx(idx)}
          className="px-4 py-2 mr-1 text-white rounded-md focus:outline-none focus:ring"
          style={{
            backgroundColor: idx == transcriptIdx ? '#ffd829' : '#30343a',
            color: idx == transcriptIdx ? 'black' : 'white',
          }}
        >
          {JSON.parse(transcript).isOriginal ? "Original" : `Edited`}
        </button>
      ))}
      

      { voiceSwapStatus === "succeeded" && transcriptCount >= 2 ? 
      <button
        className="px-4 py-2 text-black bg-[#ffd829] rounded-md focus:outline-none focus:ring"
        role="menuitem"
        onClick={() => { showDeleteTranscriptModal({ edittedTranscript, fetchData: refetch, interview }) }}
      >
      < TrashIcon className="h-4 w-4 shrink-0" />

      </button> : 
      <button
        className="px-4 py-2 text-black bg-[#ffd829] rounded-md focus:outline-none focus:ring"
        role="menuitem"
        onClick={() => { showTranscriptEditModal({ sourceTranscript, fetchData, interview }) }}
      >
      Edit transcript
      </button>
      }

    </div>
  );
};

type MessageProps = {
  content: string;
  start_time: number;
  end_time: number;
  current_time: number;
  highlight: boolean;
  messageIdx: number;
  autoScroll: boolean;
  speaker: string;
};

const Message = ({ content, highlight, messageIdx, autoScroll, speaker }: MessageProps) => {

  const idString = `chat-message-${messageIdx}`;

  useEffect(() => {
    if (highlight && autoScroll) {
      document.getElementById(idString).scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [highlight, autoScroll]);

  return (
    <div
      className={`mr-0 mt-1 w-auto overflow-hidden border-l-2 bg-[#30343a] px-4 py-2 text-white grid grid-cols-12`}
      id={idString}
      style={{
        borderColor: highlight ? '#ffd829' : '#30343a'
      }}
    >
      <div className="col-span-11 pl-1">{content}</div>
    </div>
  );
}

type ReplaySidebarProps = {
  interview: Record<string, any>;
  fetchData?: () => Promise<void>;
};

const ReplaySidebarComponent: React.FC<ReplaySidebarProps> = ({ interview: interviewObj }) => {

  const [playbackPosition, setPlaybackPosition] = useState<number | undefined>();
  const [autoScroll, setAutoScroll] = useState(true);

  window.synchronizeReplayChat = (position: number) => {
    setPlaybackPosition(position / 1000);
  };

  const [interview, setInterview] = useState<Record<string, any>>(interviewObj);

  const [transcriptIdx, setTranscriptIdx] = useState(0);

  const updateTranscriptIdx = (idx: number) => setTranscriptIdx(idx);

  const transcript = interview?.transcript && interview.transcript.length && JSON.parse(interview.transcript[transcriptIdx]);
  const sentences = transcript?.output?.segments;
  const transcriptionFailed = transcript?.status === "failed"

  const generateTranscript = async () => {
    const res = await axiosInstance.post(`api/interviews/${interview.friendlyId}/generateTranscript`, {})
    const { data } = res;
    return data;
  };

  const toggleSetWantsPublic = async (enabled: boolean, editted: boolean) => {
    const res = await axiosInstance.put(`api/interviews/${interview.friendlyId}/wantsPublic`, { wantsPublic: enabled, editted: editted })
    const { data } = res;
    setInterview(data);
    return data;
  };

  const fetchInterview = async () => {
    const { data } = await axiosInstance.get(`api/interviews/${interview.friendlyId}`);
    setTranscriptIdx(0);
    setInterview(data);
  };

  const fetchData = async () => {
    const updatedInterview = await generateTranscript();
    setInterview(updatedInterview);
  }

  const retryTranscribe = async () => {
    const {data}= await axiosInstance.delete(`api/interviews/${interview.friendlyId}/deleteTranscript/${transcript.id}`)
    setInterview(data);
  }


  const transcriptionMissingSentences = false;
  ///if (sentences) {
  ///  const startInSeconds = new Date(interview.startTime).getTime();
  ///  const endInSeconds = new Date(interview.endTime).getTime();
  ///  const interviewLength = (endInSeconds - startInSeconds) / 1000;
  ///  const transcriptEndInSeconds = sentences[sentences.length - 1].end;
  ///  transcriptionMissingSentences= Math.abs(interviewLength - transcriptEndInSeconds) > 20;
  ///}

  useQuery({
    queryKey: ['replayTranscription'],
    queryFn: () => {
      fetchData();
      return true;
    },
    refetchInterval: 2000,
    enabled: !sentences && !transcriptionFailed 
  });

  useEffect(() => {

    const originalAudio = { ...interview?.recordings[0] }

    if (transcript?.swapVoice) {
      // Can't have async as useEffect function, so using immediately invoking for async 
      const swapAudio = async () => {
        const id = transcript?.id;
        const microserviceLink = `https://ffmpeg-microservice.onrender.com/swapVoice/${id}`;
        const req = await axios.get(microserviceLink);
        const newRecording = { ...originalAudio, _id: id, url: req.data.url}
        window.downloadRecordings([newRecording]);
      };

      swapAudio();

    } else {
      window.downloadRecordings([originalAudio]);
    }


  }, [ transcriptIdx]);

  const showPublic = (interview: Record<string, any>) => {
    const orgId = interview?.round?.orgId;
    const isPremiumPractice = interview.round?.isPremiumPractice;
    const paidInterviewer = interview.interviewer?.paidInterviewer === "yes";
    return (!orgId || (isPremiumPractice || paidInterviewer)) 
      && sentences 
      && !transcriptionMissingSentences 
      && !transcriptionFailed;
  };

  return (
    <div className="h-full bg-[#1E2126] w-full flex flex-col border-t-2 border-l-2 border-[#30343a]">

      { showPublic(interview) && 
        <div className='flex w-full'>
          <div className='ml-auto mt-2'>
            <ShareReplayToggle editted={transcriptIdx !== 0} toggle={toggleSetWantsPublic} interview={interview} />
          </div>
        </div>
      }

      <div className="flex items-center p-2 justify-between">
        <div className='flex flex-col border-gray-50'>
          { 
            sentences && !transcriptionMissingSentences &&
            <div>
              <input
                type="checkbox"
                checked={autoScroll}
                onChange={() => setAutoScroll((curr) => !curr)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label className="ml-2 text-sm font-medium" style={{ color: "white" }}>Auto scroll</label>
            </div>
          }

        </div>
        { sentences && !transcriptionMissingSentences &&
         <TranscriptSelector 
            interview={interview} 
            fetchData={fetchData} 
            updateTranscriptIdx={updateTranscriptIdx} 
            transcriptIdx={transcriptIdx} 
            refetch={fetchInterview}  
            /> }
      </div>
      <div className="h-full bg-[#1E2126] w-full overflow-auto flex flex-col border-[#30343a]">
        {
          sentences ? (transcriptionMissingSentences ? 
          <div
            className='h-full ml-2 font-medium justify-center items-center flex'
            style={{ color: 'white' }}
          >
            <div> 
              <span className="text-2xl">Missing transcription segments</span>
              <div className="mt-2 flex justify-center">
                <span >Sorry, the transcription ended early.</span>


              </div>
              <div className="mt-2 flex justify-center">
                <button 
                onClick={() => { retryTranscribe() }}
                className="px-4 py-2 text-black bg-[#ffd829] rounded-md focus:outline-none focus:ring" role="menuitem" >
                  Try again
                </button>
              </div>
            </div>
          </div>
          : sentences?.map((sentence: any, idx: any) => {
            const start_time = parseFloat(sentence.start);
            const end_time = parseFloat(sentence.end);
            const {speaker} = sentence;
            return (
              <div style={{ margin: "0.5rem" }}>
                <Message
                  autoScroll={autoScroll}
                  key={idx}
                  messageIdx={idx}
                  content={sentence.text}
                  start_time={start_time}
                  end_time={end_time}
                  highlight={start_time <= playbackPosition - 3 && playbackPosition - 3 <= end_time}
                  current_time={playbackPosition - 3}
                  speaker={speaker}
                />
              </div>
            );
          })
          )
                    : ( transcriptionFailed ? 
          <div
            className='h-full ml-2 font-medium justify-center items-center flex'
            style={{ color: 'white' }}
          >
            <div> 
              <span className="text-2xl ml-2">Transcription failed</span>
              <div className="mt-2 flex justify-center">
                <span >Sorry, transcription may not work for this interview.</span>
              </div>
            </div>
          </div>
          : <div
            className='h-full ml-2 font-medium justify-center items-center flex'
            style={{ color: 'white' }}
          >
            <div> 
              <span className="text-2xl">Generating transcript</span>
              <div className="mt-2 flex justify-center">
                <span >(Takes about 2-4 minutes)</span>
              </div>
              <div className="flex justify-center mt-4">
                <LoadingSpinner/>
              </div>
            </div>

          </div>
          )
        }

      </div>
    </div>
  );
};

export default ReplaySidebarComponent;
