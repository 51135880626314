type PageHeaderProps = { children: React.ReactNode };

function PageHeader({ children }: PageHeaderProps) {
  return (
    <header>
      <div className="pl-0">
        <h1 className="flex items-center space-x-2 whitespace-nowrap text-3xl text-sm font-bold font-medium leading-tight text-gray-900">
          {children}
        </h1>
      </div>
    </header>
  );
}

export default PageHeader;
