import classNames from 'classnames';
import { OfficeBuildingIcon, ChatAlt2Icon } from '@heroicons/react/solid';

const tabs = [
  { slug: 'companies', name: 'Companies', href: '/company-introductions', icon: OfficeBuildingIcon },
  { slug: 'requests', name: 'My requests', href: '/company-introductions/requests', icon: ChatAlt2Icon },
];

export type TabPanelProps = {
  currentTab: string;
};

function TabPanel(props: TabPanelProps) {
  const { currentTab } = props;

  const setSelectedTab = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    window.location.href = `/company-introductions${event.target.value == 'companies' ? '' : `/${event.target.value}`}`;
  };

  return (
    <div>
      <div className="my-4 sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.slug === currentTab).slug}
          onChange={setSelectedTab}
        >
          {tabs.map((tab) => (
            <option key={tab.slug} value={tab.slug}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.slug === currentTab
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={tab.slug === currentTab ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.slug === currentTab ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default TabPanel;
