import { useState } from 'react';

import { claimRound, setClaimableWhileAssigned } from './ClaimsUtils';
import type { ClaimActionBarProps, Round } from './ClaimsTypes';
import Button from '../../elements/Button';

const ClaimActionBar = (props: ClaimActionBarProps) => {
  const { isUserInterviewee, isUserInterviewer, userClaimableRound } = props;
  const [processing, setProcessing] = useState<boolean>(false);
  const [processingError, setProcessingError] = useState<string>();
  const [successfullyClaimed, setSuccessfullyClaimed] = useState<boolean>(false);

  const claimInterview = async () => {
    setProcessing(true);
    setProcessingError(null);

    try {
      let results: Round;
      if (isUserInterviewer) {
        // @ts-ignore
        results = await setClaimableWhileAssigned(userClaimableRound.round._id, false);
      } else {
        // @ts-ignore
        results = await claimRound(userClaimableRound.round._id);
      }
      if (results?._id) {
        setProcessingError(null);
        setSuccessfullyClaimed(true);
      } else {
        throw new Error();
      }
    } catch (err) {
      setProcessingError(err?.data?.error?.message || 'An unexpected error occurred. Please try again.');
    }
    setProcessing(false);
  };

  return (
    <div className="flex flex-wrap">
      <Button
        label={isUserInterviewer ? 'Keep the interview' : "I'll claim it"}
        disabled={!userClaimableRound.canClaim || isUserInterviewee || successfullyClaimed || processing}
        onClick={claimInterview}
      />
      {!userClaimableRound.canClaim && <span className="ml-2 mt-2 text-red-600">{userClaimableRound.reason}</span>}
      {processingError && <span className="ml-2 mt-2 text-red-600">{processingError}</span>}
      {successfullyClaimed && (
        <span className="ml-2 mt-2">
          Success! Check out your <a href="/schedule/view-schedule/">upcoming interviews</a>.
        </span>
      )}
    </div>
  );
};

export default ClaimActionBar;
