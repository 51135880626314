import { ClipboardCopyIcon, SpeakerphoneIcon } from '@heroicons/react/outline';

import { useToasts } from '../ToastContext';

const ClaimBanner = () => {
  const { dispatch } = useToasts();

  const copyUrlToClipboard = () => {
    try {
      navigator.clipboard.writeText(window.location.toString());
      dispatch({
        type: 'addToast',
        toastContent: {
          primaryMessage: 'Copied to your clipboard',
          secondaryMessage: 'Anyone with this link can claim the interview!',
        },
      });
    } catch {}
  };

  return (
    <div className="mx-auto mb-4">
      <div className="rounded-lg bg-[#1c66ba] p-2 shadow-lg sm:p-3">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg bg-[#216ec4] p-2">
              <SpeakerphoneIcon
                className="h-6 w-6 text-white"
                aria-hidden="true"
              />
            </span>
            <p className="ml-3 font-medium text-white">
              Your interview is now claimable by other interviewers using{' '}
              <a
                href={window.location.toString()}
                onClick={(e) => {
                  e.preventDefault();
                  copyUrlToClipboard();
                }}
                style={{
                  color: 'rgb(255, 255, 255 / var(--tw-text-opacity))',
                  fontWeight: 500,
                  textDecoration: 'underline',
                }}
              >
                this link
                <ClipboardCopyIcon
                  className="ml-1 inline h-3 w-3 cursor-pointer text-white"
                  aria-hidden="true"
                />
              </a>
              . Feel free to to share it around!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimBanner;
