import axios from 'axios';
import cookies from 'js-cookie';

const axiosInstance = axios.create({
  timeout: 3000,
});

axiosInstance.interceptors.request.use((request) => {
  const token = cookies.get('token');
  if (token) request.headers['Authorization'] = `Bearer ${token}`;
  return request;
});

export default axiosInstance;
