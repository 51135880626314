import React from 'react';

import { AcademicCapIcon, BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import LocationModePill from './LocationModePill';
import { TCompanyIntroductionRequest } from './IntroductionRequestList';

export type TIntroductionRequestListItemProps = {
  introductionRequest: TCompanyIntroductionRequest;
};

const statusLabels: Record<string, { label: string; className: string }> = {
  in_review: { label: 'In review', className: 'text-md text-green-800 font-bold' },
  iio_declined: { label: 'Declined', className: 'text-sm text-gray-800 font-bold' },
  company_declined: { label: 'Declined', className: 'text-sm text-gray-800 font-bold' },
  introduction_made: { label: 'Introduction made', className: 'text-sm text-gray-800' },
  candidate_cancelled: { label: 'Cancelled', className: 'text-sm text-gray-800' },
};

const IntroductionRequestStatusLabel = ({ status }: { status: string }) => (
  <span className={statusLabels[status].className}>{statusLabels[status].label}</span>
);

const IntroductionRequestListItem = (props: TIntroductionRequestListItemProps) => {
  const { introductionRequest } = props;

  return (
    <li className="flex flex-nowrap gap-2 px-4 py-4 hover:bg-gray-50 sm:px-6">
      <div className="flex shrink-0 items-center">
        <img
          className="h-12 w-12"
          src={introductionRequest.company.companyLogoSquareUrl}
          alt={introductionRequest.company.companyName}
        />
      </div>
      <div className="items-left flex flex-1 flex-col overflow-hidden md:gap-1">
        <div className="flex flex-row items-center gap-2">
          <span className="text-lg">{introductionRequest.company.companyName}</span>
          {introductionRequest.companyListing.locationModes.map((locationMode: string) => (
            <LocationModePill key={locationMode} locationMode={locationMode} />
          ))}
        </div>
        <div className="flex flex-1 flex-col gap-1 text-sm text-gray-500 md:flex-row md:gap-4">
          <div className="flex flex-row flex-nowrap items-center gap-1">
            <BriefcaseIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span
              className="truncate"
              dangerouslySetInnerHTML={{
                __html: introductionRequest.companyListing.positionsShortDescription,
              }}
            />
          </div>
          <div className="flex flex-row flex-nowrap items-center gap-1">
            <AcademicCapIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span className="truncate">{introductionRequest.companyListing.positionLevelsShortDescription}</span>
          </div>
          <div className="flex flex-row flex-nowrap items-center gap-1">
            <LocationMarkerIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
            <span className="truncate">{introductionRequest.companyListing.locationsShortDescription}</span>
          </div>
        </div>
      </div>
      <div className="flex shrink-0 flex-row items-center gap-2">
        <span className="text-sm text-gray-500">{introductionRequest.createdAt.toLocaleDateString('en-US')}</span>
        <IntroductionRequestStatusLabel status={introductionRequest.status} />
      </div>
    </li>
  );
};

export default IntroductionRequestListItem;
