import { InterviewersAvailabilityTimeSlots } from '../../../../../types/InterviewerAvailability';

type InterviewerAvailabilityTimeSlotsGridProps = {
  interviewersAvailabilityTimeSlots: InterviewersAvailabilityTimeSlots;
  selectedTimezone: string;
};

const InterviewerSchedulerTimeSlotsGrid = (props: InterviewerAvailabilityTimeSlotsGridProps) => {
  const { interviewersAvailabilityTimeSlots, selectedTimezone } = props;
  const openTimeSlots = Object.keys(interviewersAvailabilityTimeSlots.timeSlots);

  // Get the current date
  const currentDate = new Date();
  const daysAhead = 21; // Number of days to display

  // Create a matrix for the grid
  const timeSlots = Array.from({ length: 24 }, (_, hour) => ({
    hour: hour,
    slots: Array.from({ length: daysAhead }, (_, day) => ({
      date: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + day),
      open: false,
    })),
  }));

  // Mark available timeslots based on timestamps
  openTimeSlots.forEach((timestamp: string) => {
    const localTime = new Date(timestamp);
    const dayIndex = Math.floor((localTime.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)); // Calculate day index

    if (dayIndex >= 0 && dayIndex < daysAhead) {
      const options: Intl.DateTimeFormatOptions = { timeZone: selectedTimezone, hour: 'numeric', hour12: false };
      const hourInSelectedTimezone = parseInt(new Intl.DateTimeFormat('en-US', options).format(localTime), 10);
      timeSlots[hourInSelectedTimezone].slots[dayIndex].open = true;
    }
  });

  return (
    <div className="mb-4 flex w-full max-w-full flex-col">
      <h2 className="mb-4 text-lg font-semibold">Interview Scheduler Time Slots</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse rounded border-2 border-gray-500">
          <thead>
            <tr className="bg-gray-200 font-bold">
              <th className="border border-gray-300 p-2">Time</th>
              {timeSlots[0].slots.map((slot, index) => (
                <th key={index} className="border border-gray-300 p-2">
                  {slot.date.toLocaleDateString('en-US', {
                    weekday: 'short',
                    // year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  })}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeSlots.map((timeSlot) => (
              <tr key={timeSlot.hour}>
                <td className="border border-gray-300 bg-gray-200 p-2 font-bold">{`${timeSlot.hour}:00`}</td>
                {timeSlot.slots.map((slot, index) => (
                  <td
                    key={index}
                    className={`border border-gray-300 p-2 text-center text-white ${
                      slot.open ? 'bg-green-500' : 'bg-red-500'
                    }`}
                  >
                    {slot.open ? 'Y' : 'N'}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InterviewerSchedulerTimeSlotsGrid;
