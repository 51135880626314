export type TextPanelProps = {
  text: string;
};

const TextPanel = ({ text }: TextPanelProps) => (
  <div className="overflow-hidden bg-white shadow sm:rounded-md">
    <p className="px-4 py-4 sm:px-6">{text}</p>
  </div>
);

export default TextPanel;
