import React from 'react';

import CompanyList from '../../components/CompanyIntroductions/CompanyList';
import EligibilityPanel from '../../components/CompanyIntroductions/EligibilityPanel/EligibilityPanel';
import IntroductionRequestList from '../../components/CompanyIntroductions/IntroductionRequestList';
import TabPanel from '../../components/CompanyIntroductions/TabHeader';
import Banner from '../../elements/Banner';
import type { TUser } from '../../types/User';
import { useUser } from '../../userContext';

export type CompanyIntroductionsPageProps = {
  currentTab: string;
};

const TokenWarningBanner = ({ user }: { user: TUser }) => (
  <Banner className="my-4 text-sm">
    <div>
      {user._unusedCompanyIntroductionTokens === 1 && 'You have 1 introduction token remaining.'}
      {user._unusedCompanyIntroductionTokens !== 1 &&
        `You have ${user._unusedCompanyIntroductionTokens || 'no'} introduction tokens remaining.`}{' '}
      Get 1 token with{' '}
      <a className="underline" href="/dashboard/interviewee">
        every interview/session you purchase
      </a>
      .
    </div>
  </Banner>
);

const CompanyIntroductionsPage = (props: CompanyIntroductionsPageProps) => {
  const { user } = useUser();
  const { currentTab } = props;

  return (
    <div className="mb-4 flex flex-col sm:gap-4">
      <div className="flex flex-row flex-wrap md:gap-x-4 lg:flex-nowrap">
        <div className="lg:flex-auto">
          <h1 className="font-['Inter'] text-xl font-black">
            Warm intros to recruiters & hiring managers at top companies, just for our top performers (beta)
          </h1>
          <div className="font-['Inter'] text-lg">
            <p>
              Decision-makers at top companies trust us because we consistently send them great candidates. If you're a
              top performer on interviewing.io, we'll get you in at the top of the pile. Stop applying online, and talk
              to a real human.
            </p>
            <p>Choose a company below to get introduced to a hiring decision maker.</p>
            {user && user._unusedCompanyIntroductionTokens <= 3 && <TokenWarningBanner user={user} />}
          </div>
        </div>
        <div className="w-full flex-col lg:w-1/3 lg:flex-none xl:w-1/4">
          <EligibilityPanel />
        </div>
      </div>
      <TabPanel currentTab={currentTab} />
      {currentTab === 'companies' && <CompanyList />}
      {currentTab === 'requests' && <IntroductionRequestList />}
    </div>
  );
};

export default CompanyIntroductionsPage;
