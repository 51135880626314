import { InterviewerWithInterviews } from '../../../../types/InterviewerWithInterviews';
import { useUser } from '../../../../userContext';
import { SuperAdminHyperlinkField, UserTimeZoneDateField } from '../../../superAdminFormatters';

export type InterviewerOverviewPanelProps = {
  interviewer: InterviewerWithInterviews;
};

const InterviewerOverviewPanel = (props: InterviewerOverviewPanelProps) => {
  const { interviewer } = props;
  const { user } = useUser();

  return (
    <div className="flex flex-col rounded border-2  border-gray-500 p-2 md:flex-row md:gap-4">
      <div className="w-full md:w-1/3">
        <h3>Overview</h3>
        <div>
          <span className="font-bold">User:</span>{' '}
          <SuperAdminHyperlinkField
            anchorHref={`/admin/users/?userId=${interviewer.id}`}
            anchorText={interviewer.email}
            clipboardValue={interviewer.id}
            clipboardSuccessMessage={`User Id "${interviewer.id}" copied to the clipboard`}
          />
        </div>
        <div>
          <span className="font-bold">Status:</span> {interviewer.status}
        </div>
        <div>
          <span className="font-bold">Watchlist:</span> {!!interviewer.watchlist ? 'Yes' : 'No'}
        </div>
        <div>
          <span className="font-bold">Throttle:</span>{' '}
          {interviewer.interviewerInterviewThrottle > 0
            ? `${interviewer.interviewerInterviewThrottle} interviews`
            : 'No'}
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <h3>Scoring</h3>
        <div>
          <span className="font-bold">Sufficient feedback for scoring:</span>{' '}
          {!!interviewer.interviewerScoringV4.sufficientFeedbackForScoring ? 'Yes' : 'No'}
        </div>
        <div>
          <span className="font-bold">Score:</span> {Math.round(interviewer.interviewerScoringV4?.score * 100)}
        </div>
        <div>
          <span className="font-bold">Percentile:</span>{' '}
          {Math.round(interviewer.interviewerScoringV4.percentile * 1_000) / 1_000}
        </div>
        <div>
          <span className="font-bold">Interviews relevant for scoring:</span>{' '}
          {interviewer.interviewerScoringV4.interviewCount}
        </div>
        <div>
          <span className="font-bold">Interviews excluded from scoring:</span>{' '}
          {interviewer.interviewerScoringV4.excludedFromScoringInterviewIds?.length || 0}
        </div>
        <div>
          <span className="font-bold">Negative Experience (%):</span>{' '}
          {(Math.round(interviewer.interviewerScoringV4.negativeExperiencePct * 10_000) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Audio Quality (avg):</span>{' '}
          {(Math.round(interviewer.interviewerScoringV4.audioQualityAvg * 100) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Excited to work with (avg):</span>{' '}
          {(Math.round(interviewer.interviewerScoringV4.workWithThemExcitedAvg * 100) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Question quality (avg):</span>{' '}
          {(Math.round(interviewer.interviewerScoringV4.questionQualityAvg * 100) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Hint quality (avg):</span>{' '}
          {(Math.round(interviewer.interviewerScoringV4.hintQualityAvg * 100) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Earliest Interview:</span>{' '}
          {interviewer.interviewerScoringV4.scoredInterviewStartTimeEarliest ? (
            <UserTimeZoneDateField
              timezone={user.timezone}
              dateNum={new Date(interviewer.interviewerScoringV4.scoredInterviewStartTimeEarliest).getTime()}
            />
          ) : (
            'N/A'
          )}
        </div>
        <div>
          <span className="font-bold">Latest Interview:</span>{' '}
          {interviewer.interviewerScoringV4.scoredInterviewStartTimeLatest ? (
            <UserTimeZoneDateField
              timezone={user.timezone}
              dateNum={new Date(interviewer.interviewerScoringV4.scoredInterviewStartTimeLatest).getTime()}
            />
          ) : (
            'N/A'
          )}
        </div>
      </div>
      <div className="w-full md:w-1/3">
        <h3>Accuracy</h3>
        <div>
          <span className="font-bold">Accuracy:</span> {interviewer.interviewerAccuracy?.accuracy || 'N/A'}
        </div>
        <div>
          <span className="font-bold">"Would You Hire?" %:</span>{' '}
          {(Math.round(interviewer.interviewerAccuracy?.wouldYouHirePercentage * 100) / 100).toFixed(2)}
        </div>
        <div>
          <span className="font-bold">Earliest Interview:</span>{' '}
          {interviewer.interviewerAccuracy?.interviewStartTimeEarliest ? (
            <UserTimeZoneDateField
              timezone={user.timezone}
              dateNum={new Date(interviewer.interviewerAccuracy?.interviewStartTimeEarliest).getTime()}
            />
          ) : (
            'N/A'
          )}
        </div>
        <div>
          <span className="font-bold">Latest Interview:</span>{' '}
          {interviewer.interviewerAccuracy?.interviewStartTimeLatest ? (
            <UserTimeZoneDateField
              timezone={user.timezone}
              dateNum={new Date(interviewer.interviewerAccuracy?.interviewStartTimeLatest).getTime()}
            />
          ) : (
            'N/A'
          )}
        </div>
      </div>
    </div>
  );
};

export default InterviewerOverviewPanel;
