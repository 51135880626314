import { ClaimListProps } from '../ClaimsTypes';
import ClaimListItem from './ClaimListItem';

function ClaimList(props: ClaimListProps) {
  const { userClaimableRounds, user } = props;

  if (!userClaimableRounds) return null;

  if (!userClaimableRounds.length)
    return (
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <p className="px-4 py-4 sm:px-6">There are no claimable interviews at this time. Check back soon!</p>
      </div>
    );

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200" style={{ marginBottom: 0, marginTop: 0, paddingLeft: 0 }}>
        {userClaimableRounds.map((claimableRound) => (
          <ClaimListItem key={claimableRound.round._id} claimableRound={claimableRound} user={user} />
        ))}
      </ul>
    </div>
  );
}

export default ClaimList;
