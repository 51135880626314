import { useQuery } from '@tanstack/react-query';
import axios from '../../../../../utils/axios';
import { InterviewerWithInterviews } from '../../../../../types/InterviewerWithInterviews';
import { useUser } from '../../../../../userContext';
import { useState } from 'react';
import { TUser } from '../../../../../types/User';
import { InterviewersAvailabilityTimeSlots } from '../../../../../types/InterviewerAvailability';
import InterviewerSchedulerTimeSlotsGrid from './InterviewerSchedulerTimeSlotsGrid';
import CandidateSelect from './CandidateSelect';
import InterviewerOrgSelect from './InterviewerOrgSelect';
import classNames from 'classnames';

export type InterviewerAvailabilityPanelProps = {
  interviewer: InterviewerWithInterviews;
};

type InterviewerSchedulerTimeSlotWrapperProps = {
  org: {
    _id: string;
    companyName: string;
  };
  interviewer: InterviewerWithInterviews;
  candidateTimezone: string;
};

const InterviewerSchedulerTimeSlotsWrapper = (props: InterviewerSchedulerTimeSlotWrapperProps) => {
  const { org, interviewer, candidateTimezone } = props;
  const {
    data: interviewersAvailabilityTimeSlots,
    isLoading,
    error,
  } = useQuery<InterviewersAvailabilityTimeSlots, Error>({
    queryKey: [`interviewersAvailabilityTimeSlots-${interviewer.id}-${org._id}`],
    queryFn: (): Promise<InterviewersAvailabilityTimeSlots> =>
      axios
        .get(
          `api/availability/availableTimesForInterviewer?interviewerId=${interviewer.id}&orgId=${org._id}&sourceType=standalone`
        )
        .then((response) => response.data),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  const orgAvailabilityForSelectedOrg =
    interviewersAvailabilityTimeSlots && Object.entries(interviewersAvailabilityTimeSlots.availabilities)?.[0]?.[1];

  if (!orgAvailabilityForSelectedOrg) {
    return <>Interviewer has not set availability preferences for this 'org'.</>;
  }

  const offeredFocusesMap: Record<string, boolean> = Object.fromEntries(
    Object.values(window.CONFIG.const.ROUND_FOCUSES).map((key: string) => [key, false])
  );
  if (interviewersAvailabilityTimeSlots.availabilities?.[orgAvailabilityForSelectedOrg.availabilityId]?.focuses) {
    interviewersAvailabilityTimeSlots.availabilities?.[orgAvailabilityForSelectedOrg.availabilityId]?.focuses.forEach(
      (focus: string) => {
        if (offeredFocusesMap[focus] === undefined) {
          window.Rollbar.warn(`Unexpected focus ${focus} enabled for interviewer ${interviewer.id}`);
          return;
        }
        offeredFocusesMap[focus] = true;
      }
    );
  }

  return (
    <>
      <h3>Focuses offered</h3>
      <div className="mb-4 flex flex-wrap gap-2 rounded border-2 border-gray-500 p-2">
        {Object.keys(offeredFocusesMap).map((focus, i) => (
          <div
            key={i}
            className={classNames('rounded-full px-1 py-1 text-xs text-white', {
              'bg-green-500': offeredFocusesMap[focus],
              'bg-red-500': !offeredFocusesMap[focus],
            })}
          >
            {focus}
          </div>
        ))}
      </div>
      <h3>Timeslots available</h3>
      <InterviewerSchedulerTimeSlotsGrid
        interviewersAvailabilityTimeSlots={interviewersAvailabilityTimeSlots}
        selectedTimezone={candidateTimezone}
      />
    </>
  );
};

export const InterviewerSchedulerPanel = (props: InterviewerAvailabilityPanelProps) => {
  const { user } = useUser();
  const { interviewer } = props;

  const [selectedOrg, setSelectedOrg] = useState<{ _id: string; companyName: string }>(null);
  const [candidate, setCandidate] = useState<TUser>(user);

  return (
    <div className="mb-4 flex flex-col">
      <div className="item-left max-w-full md:max-w-[50%]">
        <InterviewerOrgSelect interviewer={interviewer} onSelectedOrg={setSelectedOrg} />
        <CandidateSelect onSelectCandidate={setCandidate} initialCandidate={candidate} />
      </div>
      {selectedOrg && (
        <InterviewerSchedulerTimeSlotsWrapper
          org={selectedOrg}
          interviewer={interviewer}
          candidateTimezone={candidate.timezone}
        />
      )}
    </div>
  );
};

export default InterviewerSchedulerPanel;
