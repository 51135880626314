import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { SubmitErrorHandler, useForm } from 'react-hook-form';
import { SingleActionModal } from '../Modals/SingleActionModal';
import { CheckIcon } from '@heroicons/react/outline';
import Button from '../../elements/Button';
import axios from '../../utils/axios';
import { AcademicCapIcon, BriefcaseIcon, LocationMarkerIcon } from '@heroicons/react/solid';
import { Employer } from '../../types/Employer';
import { useUser } from '../../userContext';
import LocationModePill from './LocationModePill';

type TRequestFormData = {
  userId: string;
  notInProcess: boolean;
  linkedInHandle: string;
  firstName: string;
  lastName: string;
  dataSharingConsent: boolean;
  employerId: string;
  companyName: string;
};

const showConfirmationModal = (company: Employer) => {
  NiceModal.show(SingleActionModal, {
    actionLabel: 'Got it',
    title: 'Thank you!',
    icon: (
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
      </div>
    ),
    message: (
      <p>
        We've logged your info and will be in touch shortly. This is a new feature for us, so it may take us 1-2
        business days to follow up and make the intro.
      </p>
    ),
  });
  window.angular
    ?.element(document.body)
    .injector()
    .get('AnalyticsService')
    .track(window.CONFIG.const.analytics.eventNames.EVENT_REVENUE_COMPANY_INTRO_REQUEST_SUBMISSION_CONFIRMED, {
      employerId: company._id.toString(),
    });
};

const afterClose = (company: Employer, result: 'submitted' | 'cancelled') => {
  if (result == 'submitted') {
    showConfirmationModal(company);
  }
};

export type TRequestFormModalProps = {
  company: Employer;
  userId: string;
  firstName: string;
  lastName: string;
  linkedInHandle: string;
};

const RequestFormModal = NiceModal.create((props: TRequestFormModalProps) => {
  const { company, userId, firstName, lastName, linkedInHandle } = props;
  const [nonFormFieldError, setNonFormFieldError] = useState<string | null>(null);
  const { invalidateUser } = useUser();

  const modal = useModal();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<TRequestFormData>({
    defaultValues: {
      employerId: company._id.toString(),
      userId,
      firstName,
      lastName,
      linkedInHandle: linkedInHandle,
    },
  });

  const [resultOnHide, setResultOnHide] = useState<'submitted' | 'cancelled'>();

  const trackFormSubmit = () =>
    window.angular
      ?.element(document.body)
      .injector()
      .get('AnalyticsService')
      .track(window.CONFIG.const.analytics.eventNames.EVENT_REVENUE_COMPANY_INTRO_REQUEST_SUBMIT_CLICK, {
        companyId: company._id.toString(),
      });

  const handleFormValidationError: SubmitErrorHandler<TRequestFormData> = trackFormSubmit;

  const performSubmit = async (data: TRequestFormData) => {
    trackFormSubmit();
    setNonFormFieldError(null);
    try {
      await axios.post('api/company-introductions/introduction-request/', data);
      invalidateUser();
      setResultOnHide('submitted');
      modal.hide();
    } catch (err) {
      if (err.response) {
        err.response.data.errors?.forEach(
          ({ name, type, msg }: { name: keyof TRequestFormData | 'nonFieldError'; type: string; msg: string }) => {
            if (name in ['userId', 'firstName', 'lastName', 'linkedInHandle']) {
              setError(name as keyof TRequestFormData, { type, message: msg });
            } else if (name === 'nonFieldError') {
              setNonFormFieldError(msg);
            } else {
              setNonFormFieldError('An unexpected error occurred. Please try again.');
            }
          }
        );
      } else {
        setNonFormFieldError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.(company, resultOnHide);
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setResultOnHide('cancelled');
          modal.hide();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-sm transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:max-w-[550px] sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      A warm intro to an actual human
                    </Dialog.Title>
                    <p className="my-2 text-sm">
                      Unlike the rest of the platform, company introductions are NOT anonymous.
                    </p>
                  </div>
                  <hr className="my-4" />
                  <div className="my-2 flex flex-nowrap gap-3">
                    <div className="flex shrink-0 items-center">
                      <img className="h-12 w-12" src={company.companyLogoSquareUrl} alt={company.companyName} />
                    </div>
                    <div className="items-left flex flex-1 flex-col overflow-hidden md:gap-1">
                      <div className="flex flex-row items-center gap-2">
                        <div className="text-bold block max-w-full truncate text-lg hover:underline">
                          <span>{company.companyName}</span>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col flex-wrap gap-1 text-sm text-gray-500">
                        <div className="items-top flex flex-row flex-nowrap gap-1">
                          <BriefcaseIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: company.accountSettings.companyIntroductions.positionsShortDescription,
                            }}
                          />
                        </div>
                        <div className="flex flex-row flex-nowrap items-center gap-1">
                          <AcademicCapIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                          {company.accountSettings.companyIntroductions.positionLevelsShortDescription}
                        </div>
                        <div className="flex flex-row flex-nowrap items-center gap-1">
                          <LocationMarkerIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
                          {company.accountSettings.companyIntroductions.locationsShortDescription}
                        </div>
                        <div className="flex flex-row flex-nowrap items-center gap-1">
                          {company.accountSettings.companyIntroductions.locationModes.map((locationMode: string) => (
                            <LocationModePill key={locationMode} locationMode={locationMode} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <form onSubmit={handleSubmit(performSubmit, handleFormValidationError)} className="text-sm">
                    <div className="my-2">
                      <div className="items-top flex flex-row flex-nowrap gap-1">
                        <label className="flex text-sm" htmlFor="notInProcess">
                          I'm not currently in process with {company.companyName}, nor was I in the last 9 months
                        </label>
                        <input
                          id="notInProcess"
                          type="checkbox"
                          aria-invalid={errors.notInProcess ? 'true' : 'false'}
                          className="form-checkbox my-1 flex rounded"
                          {...register('notInProcess', { required: { value: true, message: 'Required' } })}
                        />
                      </div>
                      <div className="mt-2 rounded bg-yellow-50 p-2">
                        <p className="text-xs leading-normal">
                          “In process” means you've applied and have had some kind of next step with the company, such
                          as a coding challenge, a recruiter call, or an interview. If you were rejected before you got
                          to one of these, you can still request an intro.
                        </p>
                      </div>
                      {errors.notInProcess && <p className="mt-2 text-red-500">{errors.notInProcess.message}</p>}
                    </div>
                    <hr className="my-4" />
                    <div className="my-2">
                      <label className="text-sm" htmlFor="linkedInHandle">
                        LinkedIn:
                      </label>
                      <input
                        id="linkedInHandle"
                        type="text"
                        className="form-input rounded-full px-4 py-3"
                        aria-invalid={errors.linkedInHandle ? 'true' : 'false'}
                        placeholder="in/AdaLovelace"
                        {...register('linkedInHandle', {
                          required: "A Linkedin URL or handle in the form 'in/handle' is required.",
                          pattern: {
                            value: /(.*)in\/[\w-]+[\p{L}\w-]*\/?$/u,
                            message: "A Linkedin URL or handle in the form 'in/handle' is required.",
                          },
                        })}
                      />
                      {errors.linkedInHandle && <p className="mt-2 text-red-500">{errors.linkedInHandle.message}</p>}
                    </div>
                    <div className="my-4">
                      <label className="text-sm" htmlFor="firstName">
                        First name:
                      </label>
                      <input
                        id="firstName"
                        type="text"
                        className="form-input my-1 rounded-full px-4 py-3"
                        aria-invalid={errors.firstName ? 'true' : 'false'}
                        placeholder="Ada"
                        {...register('firstName', {
                          required: { value: true, message: 'First name is a required field' },
                        })}
                      />
                      {errors.firstName && <p className="mt-2 text-red-500">{errors.firstName.message}</p>}
                    </div>
                    <div className="my-2">
                      <label className="text-sm" htmlFor="lastName">
                        Last name:
                      </label>
                      <input
                        id="lastName"
                        type="text"
                        className="form-input my-1 rounded-full px-4 py-3"
                        aria-invalid={errors.lastName ? 'true' : 'false'}
                        placeholder="Lovelace"
                        {...register('lastName', {
                          required: { value: true, message: 'Last name is a required field' },
                        })}
                      />
                      {errors.lastName && <p className="my-2 text-red-500">{errors.lastName.message}</p>}
                    </div>
                    <hr className="my-4" />
                    <div className="my-2">
                      <div className="items-top flex flex-row flex-nowrap gap-1">
                        <label className="text-sm" htmlFor="dataSharingConsent">
                          I'm OK sharing my LinkedIn profile with {company.companyName} and I will be ready to interview
                          within the next 7-10 business days
                        </label>
                        <input
                          type="checkbox"
                          className="form-checkbox my-1 flex rounded"
                          aria-invalid={errors.dataSharingConsent ? 'true' : 'false'}
                          {...register('dataSharingConsent', { required: { value: true, message: 'Required' } })}
                        />
                      </div>
                      {errors.dataSharingConsent && (
                        <p className="mt-2 text-red-500">{errors.dataSharingConsent.message}</p>
                      )}
                    </div>
                    {nonFormFieldError && <div className="text-red-500">{nonFormFieldError}</div>}
                    <hr className="my-4" />
                    <div className="mt-2 flex flex-row gap-2">
                      <Button type="submit" label="Request intro" disabled={isSubmitting} />
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});
export default RequestFormModal;
