/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DatePicker from '../../../../elements/DatePicker';
import Button from '../../../../elements/Button';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (date: string) => void;
};

const SetDueDateModal = (props: Props) => {
  const { open, setOpen, onSubmit } = props;

  const now = new Date();
  // Setting day parameter to 0 means one day less than first day
  // of the month which is last day of the previous month.
  // This sets the default date to the last day of the month, two months from now.
  const defaultExtensionDate = new Date(now.getFullYear(), now.getMonth() + 3, 0);
  const [date, setDate] = useState(defaultExtensionDate.toLocaleDateString('en-CA'));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <div className="flex flex-row justify-center">
                  <Dialog.Title className="text-2xl font-bold">New Due Date:</Dialog.Title>
                </div>
                <div className="flex flex-row justify-center mt-6">
                  <DatePicker defaultDate={date} onChange={setDate} />
                </div>
                <div className="mt-8">
                  <Button
                    label="Submit"
                    size="fill"
                    onClick={() => {
                      setOpen(false);
                      onSubmit(date);
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SetDueDateModal;
