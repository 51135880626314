import { useEffect } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import axios from '../../../utils/axios';

import { DedicatedCoachingOrgCategoryCard } from '../DedicatedCoachingOrgCategoryCard/DedicatedCoachingOrgCategoryCard';
import { DedicatedCoachingModal, MentorLink } from '../DedicatedCoachingModal/DedicatedCoachingModal';
import {
  TDedicatedCoachingProduct,
  TDedicatedCoachingProductOrgCategory,
  TProduct,
} from '../../../types/ProductCatalog';

import { XIcon } from '@heroicons/react/outline';
import { useProductCatalog } from '../../../hooks/useProductCatalog';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export type TDedicatedCoachingCatalogProps = {
  showBundleCheckout: (
    dedicatedCoachingSku: { catalogItem: TProduct; mentorLink: MentorLink },
    onPaymentConfirmation: () => void
  ) => void;
  showPaymentConfirmationModal: (arg0: TProduct | null, arg1: MentorLink, arg2: string) => () => void;
  purchaseAttempted: boolean;
};

export const DedicatedCoachingCatalog = (props: TDedicatedCoachingCatalogProps) => {
  const { showBundleCheckout, showPaymentConfirmationModal, purchaseAttempted } = props;
  const { data: productCatalog } = useProductCatalog();

  const [mentorId, setMentorId] = useState(null);
  const [mentorLink, setMentorLink] = useState(null as MentorLink);
  const removeMentor = () => {
    setMentorLink(null);
  };

  const isDisabled = (orgId: string) => {
    if (!mentorLink) {
      return false;
    }
    if (mentorLink?.orgIds.length === 0) {
      return true;
    }
    return !mentorLink?.orgIds.includes(orgId);
  };

  const orgCategoriesByOrgId = productCatalog?.products
    .filter((product) => product.dedicatedCoachingMetadata)
    .reduce((acc, dedicatedCoachingProduct: TDedicatedCoachingProduct) => {
      if (!acc[dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId]) {
        acc[dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId] = {
          image: dedicatedCoachingProduct.dedicatedCoachingMetadata.imageSrc,
          name: dedicatedCoachingProduct.companyName,
          lead: dedicatedCoachingProduct.dedicatedCoachingMetadata.categoryLead,
          order: dedicatedCoachingProduct.dedicatedCoachingMetadata.categoryOrder,
          isNew: dedicatedCoachingProduct.dedicatedCoachingMetadata.categoryIsNew,
          products: [],
          orgId: dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId,
          startingUnitPrice: dedicatedCoachingProduct.unitPrice,
          disabled: isDisabled(dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId),
        };
      }
      acc[dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId].products.push(dedicatedCoachingProduct);
      acc[dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId].startingUnitPrice = Math.min(
        dedicatedCoachingProduct.unitPrice,
        acc[dedicatedCoachingProduct.dedicatedCoachingMetadata.orgId].startingUnitPrice
      );
      return acc;
    }, {} as Record<string, TDedicatedCoachingProductOrgCategory>);

  const sortCategories = (a: TDedicatedCoachingProductOrgCategory, b: TDedicatedCoachingProductOrgCategory) => {
    if (a.disabled > b.disabled) return 1;
    if (b.disabled > a.disabled) return -1;
    if (a.order > b.order) return 1;
    if (b.order > a.order) return -1;
    return 0;
  };

  const orgCategories = orgCategoriesByOrgId ? Object.values(orgCategoriesByOrgId).sort(sortCategories) : null;

  const handleShowProduct = (orgCategory: TDedicatedCoachingProductOrgCategory) => {
    NiceModal.show(DedicatedCoachingModal, {
      orgCategory: orgCategory,
      showBundleCheckout: showBundleCheckout,
      onPaymentConfirmation: showPaymentConfirmationModal,
      mentorLink,
    });
  };

  // Check for mentor link
  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    const mentorId = queryString.get('mentor');
    if (mentorId) {
      setMentorId(mentorId);
    }
  });

  // If mentorlink, get mentor offerings
  useQuery({
    queryKey: ['dedicatedCoachingLink'],
    queryFn: async () => {
      const { data } = await axios.post('/api/dc-courses/dedicatedCoachingLink', { mentorId });
      setMentorLink(data);

      return data;
    },
    enabled: !!mentorId && !!(orgCategories && orgCategories.length),
  });

  // If only one mentor offering, show immediately
  useEffect(() => {
    if (!purchaseAttempted && mentorLink?.orgIds.length === 1) {
      handleShowProduct(orgCategoriesByOrgId[mentorLink?.orgIds[0]]);
    }
  });

  return (
    <div>
      {mentorLink?.pseudonym && (
        <div
          onClick={removeMentor}
          className="my-2 flex w-full cursor-pointer items-center justify-between rounded bg-blue-600 p-2 text-lg text-white no-underline hover:bg-blue-500"
        >
          <div>
            Currently showing {mentorLink.pseudonym}'s available courses. <u>Click here</u> to see all courses, with any
            mentor.
          </div>
          <XIcon className="h-5 w-5" aria-hidden="true" />
        </div>
      )}
      {orgCategories && (
        <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {orgCategories.map((orgCategory: TDedicatedCoachingProductOrgCategory) => (
            <DedicatedCoachingOrgCategoryCard
              key={orgCategory.orgId}
              handleShowProduct={handleShowProduct}
              orgCategory={orgCategory}
              disabled={orgCategory.disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};
