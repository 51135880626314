import React from 'react';
import { HashtagIcon } from '@heroicons/react/solid';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookChapter as useBeyondCTCIBookChapter } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookChapter';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { ComponentTypes, TBasicRichTextStrapiComponent } from '../../../types/BasicRichTextStrapiComponent';
import { TRelatedBookSectionStrapiComponent } from '../../../types/RelatedCTCIBookSectionStrapiComponent';
import { BookTitle } from '@interviewingio/iio-react-component-lib';
import { Card } from '@interviewingio/iio-react-component-lib';
import { LinkAsButton } from '@interviewingio/iio-react-component-lib';

type BookChapterPageProps = {
  chapterSlug: string;
};

const updateUrlWithFragmentIdentifier = (newFragmentIdentifier: string) => {
  const currentUrl = window.location.href;
  const currentUrlWithoutFragment = currentUrl.split('#')[0];
  const newUrl = `${currentUrlWithoutFragment}#${newFragmentIdentifier}`;
  return newUrl;
};

const ChapterContent = ({ bookChapter }: { bookChapter: TBeyondCTCIBookChapter }) => (
  <div className="py-4">
    <Card
      title={bookChapter.attributes.Name}
      footer={
        <div className="flex justify-around px-4 py-4 sm:px-6">
          <div>Part 1</div>
          <div>Chapter 1</div>
        </div>
      }
    >
      {bookChapter.attributes.chapterContent.map(
        (bookSection: TBasicRichTextStrapiComponent | TRelatedBookSectionStrapiComponent, i: number) => {
          if (bookSection.__component === ComponentTypes.basicRichTextComponentLabel) {
            return <div key={i}>{bookSection.RichText}</div>;
          } else if (bookSection.__component === ComponentTypes.sectionsRelatedBookSection) {
            return (
              <div key={i}>
                <h3 id={bookSection.book_section.data.attributes.Slug} className="text-xl">
                  {bookSection.book_section.data.attributes.Title}{' '}
                  <a
                    className="inline-block"
                    href={updateUrlWithFragmentIdentifier(bookSection.book_section.data.attributes.Slug)}
                    aria-label={`Link to section ${bookSection.book_section.data.attributes.Title} (within Chapter page)`}
                  >
                    <span className="flex h-6 w-6 items-center justify-center rounded-md text-slate-400">
                      <HashtagIcon />
                    </span>
                  </a>
                  <a
                    className="inline-block"
                    href={`${window?.CONFIG.beyondCTCIBaseUrl}/${bookChapter.attributes.book_part.data.attributes.Slug}/${bookChapter.attributes.Slug}/${bookSection.book_section.data.attributes.Slug}`}
                    aria-label={`Link to section ${bookSection.book_section.data.attributes.Title}  (standalone Section page)`}
                  >
                    <span className="flex h-6 w-6 items-center justify-center rounded-md text-slate-400">
                      <ExternalLinkIcon />
                    </span>
                  </a>
                </h3>
                <div>
                  <BlocksRenderer content={bookSection.book_section.data.attributes.Body} />
                </div>
                <div aria-hidden="true" className="inset-0 flex items-center py-4">
                  <div className="w-full border-t border-gray-300" />
                </div>
              </div>
            );
          }
          return null;
        }
      )}
    </Card>
  </div>
);

const ChapterPage = ({ chapterSlug }: BookChapterPageProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const { data: bookChapter, isLoading, isFetched } = useBeyondCTCIBookChapter(chapterSlug);

  if (!isUserAllowed) return null;

  return (
    <div className="flex max-w-screen-lg justify-center gap-4">
      <div className="flex-1">
        <BookTitle />
        <LinkAsButton href={`${window?.CONFIG.beyondCTCIBaseUrl}`}>‹ Back to table of contents</LinkAsButton>

        {isLoading && <p>Loading...</p>}
        {isFetched && <ChapterContent bookChapter={bookChapter} />}
      </div>
    </div>
  );
};

export default ChapterPage;
