import NiceModal from '@ebay/nice-modal-react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { YesNoActionModal } from '../Modals/YesNoActionModal';
import { useToasts } from '../ToastContext';
import axios from '../../utils/axios';
import { useState } from 'react';

type ParticipantIntroductionPanelProps = {
  interviewId: string;
  linkInterview: (interview: unknown) => void;
  otherName: string;
};

function ParticipantIntroductionPanel(props: ParticipantIntroductionPanelProps) {
  const { linkInterview, interviewId, otherName } = props;
  const { dispatch } = useToasts();
  /*
    NG / React integration hack: the parent NG page dynamically rendering of this component (via ng-if attr.)
    doesn't correctly trigger re-render after the linkInterview function is called to signal a state
    change in the parent page. So in addition to sending the update to the parent, this component maintains
    its own state var (which is updated through the confirmation modal flow).

    Ideally when the parent page moves to react, this state var won't be needed and this component won't need 
    a false-render variation.
  */
  const [wantsIntro, setWantsIntro] = useState<boolean>(undefined);

  const confirm = () => {
    let confirmed = false;
    const afterClose = async () => {
      if (!confirmed) {
        return;
      }

      try {
        const response = await axios.post(`/api/interviews/${interviewId}/wantsIntro`);
        const updatedInterview = response.data;
        linkInterview(updatedInterview);
      } catch (e) {
        window.Rollbar.error(e);
        setWantsIntro(undefined);
        dispatch({
          type: 'addToast',
          toastContent: {
            type: 'failure',
            primaryMessage: 'Error',
            secondaryMessage: 'An error occurred. Please try again',
            displayTimeout: 3000,
          },
        });
        return;
      }
      setWantsIntro(true);
      dispatch({
        type: 'addToast',
        toastContent: {
          primaryMessage: 'Intro requested',
          secondaryMessage: `${otherName} has been sent an introduction request. If they reciprocate, you will be introduced`,
          displayTimeout: 3000,
        },
      });
    };

    NiceModal.show(YesNoActionModal, {
      confirmActionLabel: 'OK',
      rejectActionLabel: 'Cancel',
      title: `Confirm your intro request`,
      handleAction: () => {
        confirmed = true;
      },
      afterClose,
      icon: (
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-50">
          <ExclamationIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
        </div>
      ),
      message: (
        <p>
          If you’re ready to request an intro to your interview partner, click “OK”. If you changed your mind, you may
          close this window.
        </p>
      ),
    });
  };

  if (wantsIntro != null) {
    // wantsIntro is not null or undefined
    return null;
  }

  return (
    <div className="mb-6 flex w-full items-center justify-between rounded-lg bg-white p-6 shadow-lg">
      <div>
        <h1 className="_subheading mb-2">Request intro?</h1>
        <p>
          Would you like to be introduced to <strong>{otherName}</strong> via email?
        </p>
      </div>

      <div>
        <button className="bg-blue px-8 py-2 font-medium text-white sm:rounded-md" onClick={confirm}>
          Get Introduced
        </button>
      </div>
    </div>
  );
}

export default ParticipantIntroductionPanel;
