import { useQuery } from '@tanstack/react-query';
import axios from '../../../utils/axios';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Button from '../../../elements/Button';
import { useToasts } from '../../../components/ToastContext';

type SessionGrantsSuperAdminPageProps = {
  sessionGrantId: string;
};

type TSessionGrant = {
  _id: string;
  user: {
    _id: string;
    email: string;
    firstName: string;
    lastName: string;
  };
  source: string;
  focus: string;
  org: {
    _id: string;
    name: string;
  };
  bundled?: { dcCourseId?: string };
};

export const SessionGrantsSuperAdminPage = (props: SessionGrantsSuperAdminPageProps) => {
  const { sessionGrantId } = props;
  const [nonFormFieldError, setNonFormFieldError] = useState<string | null>(null);
  const { dispatch } = useToasts();

  const {
    data: sessionGrant,
    isLoading,
    error: retrievalError,
  } = useQuery<TSessionGrant, Error>({
    queryKey: ['interviewer'],
    queryFn: (): Promise<TSessionGrant> =>
      axios.get(`api/session-grants/${sessionGrantId}`).then((response) => response.data),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting, errors },
    reset,
    setValue,
    setError,
  } = useForm<TSessionGrant>();

  useEffect(() => {
    if (sessionGrant) {
      setValue('_id', sessionGrant._id);
      setValue('bundled.dcCourseId', sessionGrant.bundled?.dcCourseId);
    }
  }, [sessionGrant, setValue]);

  if (isLoading) return <div>Loading...</div>;
  if (retrievalError) return <div>An error has occurred: {retrievalError.message}</div>;

  const performSubmit = (data: Partial<TSessionGrant>) => {
    setNonFormFieldError(null);
    axios
      .patch(`/api/session-grants/${data._id}`, data)
      .then((response) => {
        const updatedSessionGrant = response.data;
        if (updatedSessionGrant) {
          reset(updatedSessionGrant);
          dispatch({
            type: 'addToast',
            toastContent: {
              primaryMessage: 'Success',
              secondaryMessage: 'Session grant updated.',
              displayTimeout: 3000,
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.errors?.forEach(
            ({
              type,
              msg,
              param,
            }: {
              param: keyof TSessionGrant | 'nonFieldError';
              type: string;
              msg: string;
              location: string;
            }) => {
              if (['bundled.dcCourseId'].includes(param)) {
                setError(param as keyof TSessionGrant, { type, message: msg });
              } else if (param === 'nonFieldError') {
                setNonFormFieldError(msg);
              } else {
                setNonFormFieldError('An unexpected error occurred. Please try again.');
              }
            }
          );
        } else {
          setNonFormFieldError('An unexpected error occurred. Please try again.');
          window.Rollbar.error(err);
        }
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">Session Grants</h1>
          <p className="mt-2 text-sm text-gray-700">
            Grants allow users to book Premium Practice and Dedicated Coaching sessions.
          </p>
          <div className="my-4">
            <h2 className="my-2 text-2xl">Grant</h2>
            <form onSubmit={handleSubmit(performSubmit)} className="text-sm">
              <div>
                <span className="font-bold">Id:</span> {sessionGrantId}
              </div>
              <div>
                <span className="font-bold">User:</span>{' '}
                <a className="underline" href={`/admin/users/?userId=${sessionGrant.user._id}`}>
                  {sessionGrant.user.email}
                </a>
              </div>
              <div>
                <span className="font-bold">Source:</span> {sessionGrant.source}
              </div>
              <div>
                <span className="font-bold">Org:</span>{' '}
                {sessionGrant.org._id ? (
                  <a className="underline" href={`/admin/orgs/?orgId=${sessionGrant.org._id}`}>
                    {sessionGrant.org.name}
                  </a>
                ) : (
                  'n/a'
                )}
              </div>
              <div>
                <span className="font-bold">Focus:</span> {sessionGrant.focus}
              </div>
              {sessionGrant.source === 'bundled' && (
                <div>
                  <h3 className="py-2 text-xl">Bundled session grant</h3>
                  <div className="items-top my-2 flex flex-col flex-nowrap gap-1">
                    <label className="flex text-sm font-semibold" htmlFor="dcCourseId">
                      Dedicated Coaching Course Id
                    </label>
                    <div className="mt-2 rounded bg-gray-50 p-2">
                      <p className="text-xs leading-normal">
                        Session Grants with a Dedicated Coaching Course Id are considered part of that course
                      </p>
                    </div>
                    <input
                      id="dcCourseId"
                      type="text"
                      aria-invalid={errors?.bundled?.dcCourseId ? 'true' : 'false'}
                      className="form-checkbox my-1 flex rounded"
                      {...register('bundled.dcCourseId')}
                    />
                  </div>
                  {errors.bundled?.dcCourseId && (
                    <p className="mt-2 text-red-500">{errors.bundled.dcCourseId.message}</p>
                  )}
                </div>
              )}
              {sessionGrant.source === 'bundled' && (
                <>
                  <div className="mt-2 flex flex-row gap-2">
                    <Button type="submit" label="Save" disabled={isSubmitting || !isDirty} />
                  </div>
                  {nonFormFieldError && <div className="text-red-500">{nonFormFieldError}</div>}
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionGrantsSuperAdminPage;
