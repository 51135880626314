import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  ModalPropsType,
  ContainerPropsType,
  OrganizationType,
  SchedulerPropsInputType,
  DetailsInputType,
} from './Types';
import Modal from './Modal';
import dayjs from 'dayjs';
import { useUser } from '../../userContext';

const mapDetailsToInputData = (details: DetailsInputType): SchedulerPropsInputType => {
  if (!details) {
    return;
  }
  const interviewerId = details.allowedUsers.find(
    (allowedUser) => allowedUser.roleAllowed === window.CONFIG.const.ROLE_INTERVIEWER
  )?.userId;
  const intervieweeId = details.allowedUsers.find(
    (allowedUser) => allowedUser.roleAllowed === window.CONFIG.const.ROLE_INTERVIEWEE
  )?.userId;
  const intervieweePseudonym = details._partnerName;
  return {
    sourceType: details.sourceType,
    rescheduleRoundId: details._id,
    focus: details.focus,
    focusDescription: details.companyName,
    focusImage: details.companyIconUrl,
    interviewerId,
    intervieweeId,
    intervieweePseudonym,
  } as SchedulerPropsInputType;
};

const Container = (props: ContainerPropsType) => {
  const { user } = useUser();

  const [open, setOpen] = useState<boolean>(true);
  const [page, setPage] = useState<string>('schedule');
  const [org, setOrg] = useState<OrganizationType>(props.org);
  const [input, setInput] = useState<SchedulerPropsInputType>(mapDetailsToInputData(props.details));

  const title = 'Reschedule Interview';

  // Connection to Angular/Core App
  // @ts-ignore
  window.showScheduleModal = (details: DetailsInputType, org: OrganizationType) => {
    setOrg(org);
    setInput(mapDetailsToInputData(details));
    setPage('schedule');
    setOpen(true);
  };

  if (!user || !input) {
    return null;
  }

  const dedicatedCoachingInterviewerRoundReschedule =
    user?._id === input.interviewerId && window.CONFIG.const.PREP_COURSE_FOCUSES.includes(input.focus);
  const { intervieweePseudonym } = input;

  // Dedicated Coaching mentors can reschedule; the expectation is that they've discussed and confirmed the time with the mentee
  const description = dedicatedCoachingInterviewerRoundReschedule
    ? `These are times where both you and your mentee are not booked on interviewing.io. Before rescheduling, you should make sure that this time actually works for them.`
    : `Rescheduling, like canceling, can be disruptive for your interviewer. Please keep that in mind when selecting a time and reschedule only if necessary.`;
  const todaysDate = props.todaysDate ? dayjs(props.todaysDate) : dayjs();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  });

  const modalProps: ModalPropsType = {
    open,
    setOpen,
    page,
    setPage,
    title,
    description,
    org,
    dedicatedCoachingInterviewerRoundReschedule,
    intervieweePseudonym,
    input,
    todaysDate,
  };

  return <QueryClientProvider client={queryClient}>{input && <Modal {...modalProps} />}</QueryClientProvider>;
};

export default Container;
