import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axiosInstance from '../../utils/axios';
import { useUser } from '../../userContext';
import { v4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';

import './styles.css';

export type TModalProps = {
  handleAction?: () => void;
  afterClose: () => void;
  icon: JSX.Element | string;
  sourceTranscript: Record<string, any>;
  fetchData: () => Promise<void>;
  interview: Record<string, any>
};

const ReplayTranscriptEditorModal = NiceModal.create((props: TModalProps) => {
  const modal = useModal();
  const { interview, fetchData, handleAction, afterClose, icon: ModalIcon, sourceTranscript} = props;

  const { user } = useUser();
  const showUseOneVoice = !!user._godModeId;

  const [transcript, setTranscript] = useState(sourceTranscript);

  const [swapVoice] = useState(true);
  const [useOneVoice, setUseOneVoice] = useState(false);
  const [signedUrl, setVoiceSwapParams] = useState("");
  const [edittedTranscript, setEdittedTranscript] = useState(null);

  const updateTranscript = (newText: string, idx: number) => {
    const copy = { ...transcript };
    copy.output.segments[idx].text = newText;
    setTranscript(copy);
  };

  useEffect(() => {
    const copy = { ...transcript };
    copy["swapVoice"] = swapVoice;
    setTranscript(copy);
  }, [swapVoice]);

  const close = async () => {
      handleAction?.();
      modal.hide();
  };

  const { status, data, error } = useQuery({
    queryKey: ['voiceSwap'],
    queryFn: async () => {
      const response = await axios.get(signedUrl);
      if (response.status === 200) {
        const newRecording = { /* assume not necessary ...originalAudio,*/ _id: edittedTranscript?.id, url: signedUrl};
        window.downloadRecordings([newRecording]);
        close();
      }
      return response;
    },
    refetchInterval: 2000,
    enabled: !!signedUrl
  });

  const saveTranscript = async (edittedTranscript: Record<string, any>) => {
    edittedTranscript["id"] = v4();
    edittedTranscript["isOriginal"] = false;
    edittedTranscript["useOneVoice"] = false;
    const saveResponse = await axiosInstance.post(`api/interviews/${interview.friendlyId}/saveTranscript`, edittedTranscript);
    const microserviceLink = `https://ffmpeg-microservice.onrender.com/swapVoice/${edittedTranscript["id"]}`;
    const signedUrlResponse = await axios.get(microserviceLink);
    setVoiceSwapParams(signedUrlResponse.data.url);
    setEdittedTranscript(edittedTranscript)

    const { data } = saveResponse;
    return data;
  };


  const submitEdits = async () => {
    await saveTranscript(transcript);
    await fetchData();
  };

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-[#1E2126] relative max-w-2xl transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                 
                <div>
                  {ModalIcon}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                      Edit transcript & swap voice
                    </Dialog.Title>
                    { signedUrl && 
                      <div role="status" className="mt-4">
                        <div className="text-white mb-4">Swapping voices. This typically takes 10-15 minutes...</div>
                        <div className="text-white mb-6">
                          You can click away. When done, we'll show your editted transcript here.
                        </div>
                        <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>

                      </div>
                    }

                    { !signedUrl &&
                      <div className="text-white mt-3"> 
                      Make changes to your transcript here, and we will make a new audio track for your interview with your voice swapped and your edits included (for now we just have one default voice for everyone).  
                    </div>
                    }
                    { !signedUrl &&
                    <div className="mt-5 sm:mt-6" style={{ overflow: "auto", maxHeight: "60vh" }}>
                      {transcript.output.segments.map((segment: Record<string, any>, idx: number) => (
                        <textarea
                          className='edit-textarea-styling'
                          key={idx}
                          value={segment.text}
                          onChange={(e) => updateTranscript(e.target.value, idx) }
                        />
                      ))}
                    </div>
                    }

                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col">
                  {!signedUrl && 
                    <div className='w-full items-center justify-center flex flex-col'>

                      { swapVoice && <span className="ml-2 text-sm font-medium text-white py-5">Generating the new audio track can take 10-15 minutes. Please make sure you have made all your transcript changes. You will have to make a new audio track if you wish to make changes later.</span> }
                      { showUseOneVoice && 
                        <div className="mb-4">
                          <input
                            type="checkbox"
                            checked={useOneVoice}
                            onChange={() => setUseOneVoice((curr) => !curr)}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                            <label className="ml-2 text-sm font-medium text-white">Use one voice in generated interview instead of two (godmode only)</label>
                        </div>
                      }

                      <button
                        type="button"
                        className="inline-flex w-max justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                        onClick={submitEdits}
                        tabIndex={0}
                      >
                        Submit edits and swap voice
                      </button>

                    </div>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export { ReplayTranscriptEditorModal };
