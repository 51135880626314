/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import Search from '../../../../elements/Search';
import Button from '../../../../elements/Button';

export type OnboardingCompanyProps = {
  buttonLabel?: string;
  onSubmit?: (currentCompany?: string, shouldAvoidCompany?: boolean, isHiringManager?: boolean) => void;
  searchCallback?: (value: string) => Promise<any[]>;
  clearInput?: boolean;
};

const CurrentCompany = (props: OnboardingCompanyProps) => {
  const { buttonLabel, onSubmit, searchCallback, clearInput }: OnboardingCompanyProps = props;
  const [currentCompany, setCurrentCompany] = useState<any>({});
  const [shouldAvoidCompany, setShouldAvoidCompany] = useState<boolean>(true);
  const [isHiringManager, setIsHiringManager] = useState<boolean>(false);

  return (
    <>
      <div className="my-2 flex h-full w-full flex-col gap-2">
        <div className="">
          <Search
            label="I work at"
            onSelect={setCurrentCompany}
            searchCallback={searchCallback}
            labelSelector={(value: any): string => value?.name}
            clearInput={clearInput}
            showDropdownOnFocus={false}
          />
        </div>
        <div className="flex h-5">
          <input
            id="dontMatch"
            aria-describedby="Don't match with coworkers"
            name="dontMatch"
            type="checkbox"
            checked={shouldAvoidCompany}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onChange={(event) => setShouldAvoidCompany(event.target.checked)}
          />
          <div className="ml-3 text-sm">
            <label htmlFor="dontMatch" className="font-semibold">
              Don't match me with coworkers
            </label>
          </div>
        </div>
        <div className="flex h-5">
          <input
            id="isHiringManager"
            aria-describedby="I am a hiring manager"
            name="isHiringManager"
            type="checkbox"
            checked={isHiringManager}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            onChange={(event) => setIsHiringManager(event.target.checked)}
          />
          <div className="ml-3 text-sm">
            <label htmlFor="isHiringManager" className="font-semibold">
              I am a hiring manager
            </label>
          </div>
        </div>

        <div className="relative flex items-start"></div>
      </div>
      <div
        className="
          relative inset-x-0 bottom-0 flex
          h-auto w-full flex-row-reverse"
      >
        <Button
          label={buttonLabel}
          size="fill"
          onClick={() =>
            onSubmit({
              ...currentCompany,
              avoidMatch: shouldAvoidCompany && currentCompany?.name?.length > 0,
              isHiringManager,
            })
          }
        />
      </div>
    </>
  );
};

export default CurrentCompany;
