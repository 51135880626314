import { useState } from 'react';
import ReplaySidebarComponent from './ReplaySidebarComponent';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { QueryClient,
         QueryClientProvider,
        } from '@tanstack/react-query';

const queryClient = new QueryClient()

const ReplaySidebarContainer: React.FC = () => {
  const [isReplayChatVisible, setIsReplayChatVisible] = useState(false);
  const [interviewObj, setInterviewObj] = useState<Record<string, any>>({});

  window.toggleReplayChat = (interview: Record<string, any>) => {
    setInterviewObj(interview);
    setIsReplayChatVisible((curr) => !curr);
  };

  window.setIsReplayChatVisible = setIsReplayChatVisible;

  const FOOTER_HEIGHT = 89;

  const { height } = useWindowDimensions();

  return (
    isReplayChatVisible && (
      <div
        className="flex absolute right-0"
        style={{
          height: height - FOOTER_HEIGHT,
          top: "44px",
          width: "26rem",
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ReplaySidebarComponent interview={interviewObj} />
        </QueryClientProvider>
      </div>
    )
  );
};

export default ReplaySidebarContainer;
