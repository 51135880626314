import { useState, useEffect, useMemo } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';

import http from '../../utils/axios';
import {
  getBaseCalendarData,
  transformAvailabilityData,
  getDaysAhead,
  mergeBaseWithAvailabilities,
  shouldHideEarlyTimes,
  mapExistingRounds,
} from './Utils';
import {
  CalendarPropsType,
  DateTimeType,
  MonthType,
  TimeSlotsWithAvailabilityType,
  TimeSlotWithAvailability,
  ExistingRoundsMappedType,
} from './Types';

dayjs.extend(advancedFormat);

const Calendar = (props: CalendarPropsType) => {
  const {
    dedicatedCoachingInterviewerRoundReschedule,
    org,
    input,
    actions: propsActions,
    title,
    description,
    todaysDate,
  } = props;
  const { focus } = input;

  // Init base calendar data
  const today = dayjs(todaysDate);
  const daysAhead = getDaysAhead(today);
  const monthsAhead = 2;
  const base = useMemo(() => getBaseCalendarData(today, daysAhead, monthsAhead), []);

  // Init State
  const [data, setData] = useState<MonthType[]>(base);
  const [timeSlots, setTimeSlots] = useState<TimeSlotsWithAvailabilityType>({});
  const [existing, setExisting] = useState<ExistingRoundsMappedType>({});
  const [showEarlyTimes, setShowEarlyTimes] = useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = useState<number>(0);
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<TimeSlotWithAvailability | undefined>();
  const [isReady, setIsReady] = useState<boolean>(false);

  // Actions
  const selectTime = (data: TimeSlotWithAvailability) => {
    setSelectedTime(data);
    propsActions.setSelectedTimeSlot(data); // Parent
  };
  const selectDate = (id: string) => {
    setSelectedDate(id);
    selectTime(undefined);
    const times = timeSlots[id];
    if (times) setShowEarlyTimes(!shouldHideEarlyTimes(times));
  };
  const selectMonth = (month: number) => {
    setSelectedMonth(month);
    selectDate('');
    selectTime(undefined);
  };

  // Fetching initial Data
  const timeSlotURL = dedicatedCoachingInterviewerRoundReschedule
    ? `/api/availability/getAvailableTimesForDCInterviewerRoundReschedule?rescheduleRoundId=${input.rescheduleRoundId}`
    : `/api/availability/availableTimes?${org && org._id ? `orgId=${org._id}&` : ''}rescheduleRoundId=${
        input.rescheduleRoundId
      }&sourceType=${input.sourceType}`;
  const { data: timeSlotData, isInitialLoading, refetch } = useQuery(['timeslots'], () => http.get(timeSlotURL));
  useEffect(() => {
    const apiData = timeSlotData?.data ? timeSlotData.data : null; // Map data
    const isAPIDataAString = typeof apiData === 'string'; // Service will return the result as an object or a refresh token as a string if it's not ready
    if (!isInitialLoading && apiData && !isAPIDataAString) {
      // Actual data from API, transform and merge!
      try {
        const transformed = transformAvailabilityData(timeSlotData.data, focus, org);
        const merged = mergeBaseWithAvailabilities(data, transformed);
        setTimeSlots(transformed);
        setData(merged);
        setSelectedDate(Object.keys(transformed)[0]);
      } catch (err) {
        // Error with data / transform
        window.Rollbar.error(err);
      } finally {
        setIsReady(true);
      }
    } else if (!isInitialLoading && apiData && isAPIDataAString) {
      // No data available yet, work has been sent to a queue worker
      // Refresh (retry) again after the backoff period.
      const backoff = 1000;
      // Hack from the server
      // tl;dr -> If the data isn't ready yet, the service will return a simple string with a URL to call to get the data in the future.
      // In this case, we can simply refresh after a back off period.
      setTimeout(() => {
        refetch({ throwOnError: true, cancelRefetch: true });
      }, backoff);
    }
  }, [timeSlotData]);
  const hasTimes = selectedDate && timeSlots[selectedDate];

  // Existing scheduled times
  const existingURL = `/api/rounds/forUser?joinableOnly=true`;
  const { data: existingSlotsData, isInitialLoading: isLoadingExisting } = useQuery(['existing'], () =>
    http.get(existingURL)
  );
  useEffect(() => {
    if (!isLoadingExisting && existingSlotsData && existingSlotsData?.data) {
      setExisting(mapExistingRounds(existingSlotsData?.data || []));
    }
  }, [existingSlotsData]);

  return (
    <div>
      <h2 className="border-b px-7 pb-3 text-2xl font-semibold text-gray-900">{title}</h2>
      <div className="sm:grid sm:grid-cols-12 sm:gap-x-8">
        <div className="px-4 pt-4 text-center sm:col-start-1 sm:col-end-8 sm:row-start-1 sm:pl-8">
          <div className="inline-flex items-center text-gray-900">
            <button
              type="button"
              className={classNames(
                `-m-1.5 flex flex-none items-center justify-center p-1.5`,
                selectedMonth === 0 ? `text-gray-200 hover:text-gray-200` : `text-gray-400 hover:text-gray-500`
              )}
              onClick={() => {
                if (selectedMonth !== 0) selectMonth(selectedMonth - 1);
              }}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <div className="flex min-w-[130px] place-content-center text-lg font-semibold">
              {isReady ? data[selectedMonth].monthText : 'Loading'}
            </div>
            <button
              type="button"
              className={classNames(
                `-m-1.5 flex flex-none items-center justify-center p-1.5`,
                selectedMonth === data.length - 1
                  ? `text-gray-200 hover:text-gray-200`
                  : `text-gray-400 hover:text-gray-500`
              )}
              onClick={() => {
                if (selectedMonth < data.length - 1) selectMonth(selectedMonth + 1);
              }}
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
            {data[selectedMonth].days.map((day: DateTimeType, dayIdx: number) => {
              const isSelected = day.date === selectedDate;
              return (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    'py-1.5  hover:bg-gray-100 focus:z-10',
                    (!day.isCurrentMonth || !isReady) && 'bg-gray-50', // background
                    isReady && day.isCurrentMonth && (day.isInPast || !day.isAvailable) ? 'bg-gray-50' : 'bg-white', // background
                    (isSelected || day.isToday) && 'font-semibold', // text weight
                    isReady && isSelected && 'text-white', // text color
                    day.isToday && !isSelected && 'text-white', // text color
                    !isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', // text color
                    (day.isInPast || !day.isCurrentMonth || !day.isAvailable || !isReady) && 'text-gray-400', // text color
                    dayIdx === 0 && 'rounded-tl-lg', // top left border
                    dayIdx === 6 && 'rounded-tr-lg', // top right border
                    dayIdx === data[selectedMonth].days.length - 7 && 'rounded-bl-lg', // bottom left border
                    dayIdx === data[selectedMonth].days.length - 1 && 'rounded-br-lg' // bottom right border
                  )}
                  onClick={() => {
                    if (day.isAvailable && !isSelected && isReady) selectDate(day.date);
                  }}
                >
                  <time
                    key={day.date}
                    dateTime={day.date}
                    className={classNames(
                      'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                      isReady && isSelected && 'bg-blue-500',
                      isReady && day.isFuture && day.isAvailable && !isSelected && day.slots > 0 && 'bg-blue-100',
                      !isSelected && day.isToday && 'bg-gray-500'
                    )}
                  >
                    {day.isCurrentMonth ? day.display : ''}
                  </time>
                </button>
              );
            })}
          </div>
          <div className="isolate py-4 px-1 text-left text-sm text-gray-400">{description}</div>
        </div>
        {hasTimes && (
          <ol className="mt-2 px-4 text-sm leading-5 sm:col-span-5 sm:mt-0 sm:max-h-[400px] sm:min-h-[480px] sm:overflow-y-auto sm:border-l">
            {
              <li className="sticky top-0 z-10 w-full bg-white py-3 text-center text-base font-semibold sm:text-left sm:text-sm">
                {dayjs(selectedDate).format('dddd, MMM Do')}
              </li>
            }
            {!showEarlyTimes && (
              <li
                className=" w-full cursor-pointer bg-white py-3 text-center text-xs font-normal text-blue-400 hover:text-blue-500"
                onClick={() => setShowEarlyTimes(true)}
              >
                View earlier times
              </li>
            )}
            {timeSlots[selectedDate].map((timeSlot) => {
              const isSelected = timeSlot.dateTime === selectedTime?.dateTime;
              // Show this entry if:
              // 1. It's not an early timeslot (which are always shown) OR
              // 2. It is an early timeslot, but the user selected to show them
              // AND
              // 3. User doesn't have an "existing" round at that time (of any type/focus/etc)
              const existingEntry = existing && existing[timeSlot.dateTime];
              const show = (!timeSlot.early || showEarlyTimes) && existing && !existingEntry;
              return (
                show && (
                  <li
                    key={timeSlot.dateTime}
                    className={classNames(
                      `relative mb-2 flex cursor-pointer items-center justify-center space-x-1 rounded-md border border-gray-200 py-2 text-center`,
                      isSelected
                        ? `border-blue-500 bg-blue-500 text-white hover:bg-blue-500 hover:text-white` // Selected
                        : `text-gray-700 hover:border-blue-200 hover:bg-blue-100 hover:text-gray-700` // Not Selected
                    )}
                    onClick={() => selectTime(timeSlot)}
                  >
                    <div>{timeSlot.display}</div>
                  </li>
                )
              );
            })}
          </ol>
        )}
        {!hasTimes && (
          <div className="mt-2 px-4 text-sm leading-5 sm:col-span-5 sm:mt-0 sm:max-h-[400px] sm:min-h-[480px] sm:overflow-y-auto sm:border-l">
            <div className="sticky top-0 z-10 w-full bg-white py-3 text-center text-base font-semibold sm:text-left sm:text-sm">
              {dayjs(selectedDate).format('dddd, MMM Do')}
            </div>
            <p>No times available.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendar;
