/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../../elements/Button';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  message?: string;
  successMessage?: string;
  failMessage?: string;
  onSuccess?: () => void;
  onFail?: () => void;
};

const YesNoDialog = (props: Props) => {
  const { open = false, setOpen, message, successMessage = 'Yes', failMessage = 'No', onSuccess, onFail } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto px-2 sm:px-0">
          <div className="flex items-center justify-center min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-visible shadow-xl transform transition-all sm:my-10 sm:max-w-sm sm:w-full sm:pt-10 sm:py-4 sm:px-0 sm:min-w-[450px]">
                <Dialog.Title as="h3" className="text-sm leading-6 font-medium text-gray-900 px-2 sm:px-10">
                  <div className="font-normal text-base text-center">{message}</div>
                </Dialog.Title>
                <div className="my-4 border-b border-gray-200"></div>
                <div className="px-2 sm:px-10 py-2">
                  <div
                    className="
                      w-full flex flex-row-reverse relative h-10 mt-4"
                  >
                    <div className="ml-2 h-10">
                      <Button
                        label={failMessage}
                        style="danger"
                        size="fill"
                        onClick={() => {
                          onFail();
                          setOpen(false);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        label={successMessage}
                        style="confirm"
                        size="fill"
                        onClick={() => {
                          onSuccess();
                          setOpen(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default YesNoDialog;
