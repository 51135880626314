import React, { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../../../utils/axios';
import { TUser } from '../../../../../types/User';

type CandidateIdSelectProps = {
  onSelectCandidate: (candidate: TUser | null) => void;
  initialCandidate?: TUser;
};

// Function to fetch candidate data
const fetchCandidate = async (candidateId: string) => {
  const response = await axios.get<TUser>(`/api/users/${candidateId}/details`);
  return response.data;
};

const CandidateSelect: React.FC<CandidateIdSelectProps> = (props) => {
  const { onSelectCandidate, initialCandidate } = props;
  const [candidateId, setCandidateId] = useState<string>(initialCandidate?._id || '');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { data: candidate, refetch } = useQuery<TUser, Error>(
    ['user', candidateId],
    () => fetchCandidate(candidateId),
    {
      enabled: false,
      onSuccess: (data) => {
        onSelectCandidate(data);
        setErrorMessage(null);
      },
      onError: () => {
        setErrorMessage('Failed to fetch candidate. Please check the ID and try again.'); // Set error message
      },
    }
  );

  // Encapsulate the candidateId state assignment and data retrieval (via refetch) for the initialCandidate prop
  useEffect(() => {
    if (initialCandidate) {
      setCandidateId(initialCandidate._id);
      refetch();
    }
  }, [initialCandidate]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCandidateId(value);
    onSelectCandidate(null);
    setErrorMessage(null);
  };

  const handleGoClick = () => {
    if (candidateId) {
      refetch();
    }
  };

  return (
    <div className="my-4 flex flex-col space-y-4">
      <h4>Candidate</h4>
      <p>
        Scheduler timeslots are based on the Interviewer's availability preferences but are unique for each candidate
        due to company matching preferences, and past rounds. Choose a candidate to see when they could book this
        interviewer.
      </p>
      <label className="block text-sm font-medium text-gray-700">Candidate ID:</label>
      <input
        type="text"
        value={candidateId}
        onChange={handleInputChange}
        className="rounded-md border border-gray-300 p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        placeholder="Enter User ID"
      />
      <button type="button" onClick={handleGoClick} className="rounded-md bg-blue-500 p-2 text-white hover:bg-blue-600">
        Go
      </button>

      {errorMessage && (
        <p className="mt-2 text-red-500">{errorMessage}</p> // Display error message in red
      )}

      {candidate && (
        <div className="mt-4">
          <h2 className="text-lg">Selected Candidate:</h2>
          <p>Email: {candidate.email}</p>
          <p>Timezone: {candidate.timezone}</p>
        </div>
      )}
    </div>
  );
};

export default CandidateSelect;
