import { useQuery } from '@tanstack/react-query';

import { Employer } from '../../types/Employer';
import CompanyListItem from './CompanyListItem';
import axios from '../../utils/axios';
import TextPanel from './TextPanel';

const ListItems = ({ companies }: { companies: Employer[] }) => (
  <div className="my-4 overflow-hidden bg-white shadow sm:rounded-md">
    <ul role="list" className="divide-y divide-gray-200" style={{ marginBottom: 0, marginTop: 0, paddingLeft: 0 }}>
      {companies.map((company) => (
        <CompanyListItem key={company._id} company={company} />
      ))}
    </ul>
  </div>
);

function CompanyList() {
  const companies = useQuery({
    queryKey: ['companyIntroductionsCompanyList'],
    queryFn: (): Promise<Employer[]> =>
      axios
        .get('api/employer/getCompanyIntros')
        .then((response) =>
          response.data.sort((a: Employer, b: Employer) =>
            a.companyName.toLocaleLowerCase().localeCompare(b.companyName.toLocaleLowerCase())
          )
        ),
  });

  if (companies.isLoading) {
    return <TextPanel text="Loading ..." />;
  }
  if (companies.isError) {
    return <TextPanel text="An unexpected error occurred. Please try again later" />;
  }

  if (companies.data.length) {
    return <ListItems companies={companies.data} />;
  } else {
    return <TextPanel text="No companies are taking introductions at this time. Check back soon!" />;
  }
}

export default CompanyList;
