import { useState } from 'react';
import ExperienceConfirmationModal from './ExperienceConfirmationModal';

type Props = {
  onConfirm?: () => void;
};

declare global {
  interface Window {
    showExperienceConfirmationModal: (props: Props) => void;
  }
}

const ExperienceConfirmationModalContainer = () => {
  const [isExperienceConfirmationModalVisible, setIsExperienceConfirmationModalVisible] = useState(false);
  const [input, setInput] = useState<Props>({});

  window.showExperienceConfirmationModal = (props: Props) => {
    setIsExperienceConfirmationModalVisible(true);
    setInput(props);
  };

  return (
    <ExperienceConfirmationModal
      open={isExperienceConfirmationModalVisible}
      setOpen={setIsExperienceConfirmationModalVisible}
      onConfirm={input.onConfirm}
    />
  );
};

export default ExperienceConfirmationModalContainer;
