import React from 'react';

type LocationModePillProps = { locationMode: string };

function LocationModePill({ locationMode }: LocationModePillProps) {
  let locationModePillClasses;
  if (locationMode === window?.CONFIG.const.companyIntroductions.LOCATION_MODES.LOCATION_MODE_REMOTE) {
    locationModePillClasses = 'bg-blue-50 text-blue-800';
  } else {
    locationModePillClasses = 'bg-yellow-100 text-yellow-800';
  }

  return (
    <div className={`mt-0 truncate rounded-full px-2 align-middle text-xs font-semibold ${locationModePillClasses}`}>
      {locationMode}
    </div>
  );
}

export default LocationModePill;
