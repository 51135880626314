import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';
import classNames from 'classnames';

import http from '../../utils/axios';
import Calendar from './Calendar';
import Error from './Error';
import Final from './Final';

import { PagesType, ModalPropsType, TimeSlotWithAvailability } from './Types';

const Modal = (props: ModalPropsType) => {
  const {
    open,
    setOpen,
    page,
    setPage,
    title,
    description,
    org,
    dedicatedCoachingInterviewerRoundReschedule,
    intervieweePseudonym,
    input,
    todaysDate,
  } = props;
  const { rescheduleRoundId } = input;
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<TimeSlotWithAvailability | undefined>();
  const [hasError, setHasError] = useState<boolean>(false);

  // Scheduler Pages
  // Refer to PagesType and PageType for possible args
  const pages: PagesType = {
    schedule: {
      props: {
        title,
        description,
        org,
        dedicatedCoachingInterviewerRoundReschedule,
        input,
        actions: {
          setSelectedTimeSlot: (value: TimeSlotWithAvailability) => {
            setSelectedTimeSlot(value);
          },
        },
        todaysDate,
      },
      nextButtonText: 'Schedule Interview',
      component: Calendar,
      previous: null,
      next: 'final',
    },
    final: {
      props: {
        title: 'Reschedule Interview',
        description: input.focusDescription,
        date: selectedTimeSlot ? dayjs(selectedTimeSlot.selected.startDate) : null,
        intervieweePseudonym,
        dedicatedCoachingInterviewerRoundReschedule,
      },
      nextButtonText: 'Close',
      component: Final,
      previous: 'schedule',
      next: null,
    },
  };

  const closeButtonRef = useRef(null);
  const { component: Component, nextButtonText, props: componentProps, next: nextPage } = pages[page];

  // Schedule Time after selected
  const scheduleRoundURL = `/api/availability/rescheduleInterview`;
  const scheduleInterview = useMutation(
    (data: TimeSlotWithAvailability) => {
      const request = {
        roundId: rescheduleRoundId,
        timeSlot: data.selected,
      };
      return http.post(scheduleRoundURL, request);
    },
    {
      onError: () => {
        scheduleInterview.reset();
        setHasError(true);
      },
      onSuccess: (roundDetails) => {
        setHasError(false);
        setPage(pages[page].next);
        // @ts-ignore
        if (window.angular && window.onSuccessfulReschedule && roundDetails && roundDetails.data) {
          // @ts-ignore
          window.onSuccessfulReschedule(roundDetails.data);
        }
        scheduleInterview.reset();
      },
    }
  );

  const buttonNext = () => {
    if (pages[page].next) {
      if (selectedTimeSlot !== undefined && scheduleInterview.isIdle) {
        setHasError(false);
        scheduleInterview.mutate(selectedTimeSlot);
      }
    }
  };

  const buttonClose = () => {
    setSelectedTimeSlot(undefined);
    setHasError(false);
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={closeButtonRef} onClose={buttonClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative min-h-screen w-full max-w-2xl transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:min-h-min sm:rounded-lg">
                <div className="bg-white px-0 pt-4">
                  <ErrorBoundary FallbackComponent={() => <div></div>}>
                    <Component {...componentProps} />
                  </ErrorBoundary>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:bg-gray-50 sm:px-6">
                  <button
                    type="button"
                    className={classNames(
                      `inline-flex w-full min-w-[170px] justify-center rounded-md border border-transparent py-3 pl-5 pr-4 text-base font-medium shadow-sm transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:py-2 sm:text-sm`,
                      selectedTimeSlot !== undefined &&
                        (scheduleInterview.isIdle || scheduleInterview.isSuccess) &&
                        `cursor-pointer bg-blue-600 text-white hover:bg-blue-600 focus:ring-blue-500`, // Active state
                      selectedTimeSlot !== undefined &&
                        scheduleInterview.isLoading &&
                        `cursor-pointer bg-blue-400 text-white hover:bg-blue-500 focus:ring-blue-200`, // Working state
                      selectedTimeSlot === undefined &&
                        `cursor-default bg-gray-300 text-white hover:bg-gray-300 focus:ring-gray-300` // Default 'disabled' state
                    )}
                    onClick={nextPage ? buttonNext : buttonClose}
                  >
                    {scheduleInterview.isLoading ? 'Scheduling...' : nextButtonText}
                  </button>
                  {nextPage && (
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-3 text-base  font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:py-2 sm:text-sm"
                      onClick={buttonClose}
                      ref={closeButtonRef}
                    >
                      Cancel
                    </button>
                  )}
                  {hasError && <Error />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
