import axios from '../utils/axios';
import { useQuery } from '@tanstack/react-query';

import { TProductCatalog } from '../types/ProductCatalog';

export const useProductCatalog = () =>
  useQuery({
    queryKey: ['productCatalog'],
    queryFn: (): Promise<TProductCatalog> =>
      axios.get('api/orders/catalog').then((response) => {
        if (response.data.sale) {
          response.data.sale.startDate = new Date(response.data.sale.startDate);
          response.data.sale.endDate = new Date(response.data.sale.endDate);
        }
        return response.data;
      }),
    refetchInterval: 60 * 1000,
  });
