/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { CheckIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';

type Props = {
  label?: string;
  searchCallback: (value: string) => Promise<any[]>;
  labelSelector: (value: any) => string;
  imageSelector?: (value: any) => string;
  onSelect?: (value: any) => void;
  className?: string;
  clearInput?: boolean;
  showDropdownOnFocus?: boolean;
};

const Search = (props: Props) => {
  const { label, searchCallback, labelSelector, onSelect, imageSelector, className, clearInput, showDropdownOnFocus } =
    props;
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState();
  const [queryResults, setQueryResults] = useState([]);

  useEffect(() => {
    searchCallback(query).then((results) => {
      setQueryResults(results);
    });
  }, [query]);

  return (
    <Combobox
      as="div"
      value={selected}
      className={className}
      onChange={(option: any) => {
        setSelected(option);
        onSelect && onSelect(option);
      }}
      onSelect={() => {
        if (clearInput) {
          setSelected(undefined);
          onSelect && onSelect(undefined);
        }
      }}
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
      <div className="relative mt-1">
        {showDropdownOnFocus ? (
          <Combobox.Button as="div">
            <Combobox.Input
              className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-400 sm:text-sm"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={labelSelector}
            />
          </Combobox.Button>
        ) : (
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-400 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={labelSelector}
          />
        )}

        {queryResults?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {queryResults.map((result, index) => (
              <Combobox.Option
                key={index}
                value={result}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-blue-500 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      {imageSelector != null && (
                        <img src={imageSelector(result)} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                      )}
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                        {labelSelector(result)}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-blue-500'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default Search;
