import { useEffect, useState } from 'react';
import ClaimList from '../../components/Claims/ClaimsList/ClaimList';
import { User, UserClaimableRound } from '../../components/Claims/ClaimsTypes';

import { getUser, getUserClaimableRounds } from '../../components/Claims/ClaimsUtils';
import PageHeader from '../../elements/PageHeader';

const ClaimInterviewList = () => {
  const [user, setUser] = useState<User>();
  const [userClaimableRounds, setUserClaimableRounds] = useState<UserClaimableRound[]>();

  useEffect(() => {
    const fetchUser = async () => setUser(await getUser());
    fetchUser();
  }, []);

  useEffect(() => {
    getUserClaimableRounds().then(setUserClaimableRounds);
  }, []);

  if (!user || !userClaimableRounds) return null;

  return (
    <div>
      <PageHeader>Claimable interviews</PageHeader>
      <div className="my-2 md:my-4">
        <ClaimList user={user} userClaimableRounds={userClaimableRounds} />
      </div>
    </div>
  );
};

export default ClaimInterviewList;
