import { useState } from 'react';
import UpdateOnboardingModal from './UpdateOnboardingModal';
import { updateAppliedCompanies } from '../../../api/signups';

type UpdateOnboardingModalInputs = {
  onExperienceSubmit?: (engineerType: string, yoe: number) => Promise<void>;
  onAppliedCompaniesSubmit?: (appliedCompanies: string[]) => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchCallback?: (value: string, showDefault: boolean) => Promise<any[]>;
  onUpdateOnboardingCompleted?: () => Promise<void>;
};

declare global {
  interface Window {
    showUpdateOnboardingModal: (inputs: UpdateOnboardingModalInputs) => void;
  }
}

const UpdateOnboardingModalContainer = () => {
  const [isOnboardingModalVisible, setIsUpdateOnboardingModalVisible] = useState(false);
  const [inputs, setInputs] = useState<UpdateOnboardingModalInputs>({});

  window.showUpdateOnboardingModal = (inputs: UpdateOnboardingModalInputs) => {
    setInputs(inputs);
    setIsUpdateOnboardingModalVisible(true);
  };

  return (
    <UpdateOnboardingModal
      onExperienceSubmit={inputs.onExperienceSubmit}
      onAppliedCompaniesSubmit={updateAppliedCompanies}
      searchCallback={inputs.searchCallback}
      open={isOnboardingModalVisible}
      setOpen={setIsUpdateOnboardingModalVisible}
      onOnboardingCompleted={inputs.onUpdateOnboardingCompleted}
    />
  );
};

export default UpdateOnboardingModalContainer;
