import { useProductCatalog } from '../../../hooks/useProductCatalog';
import { TDedicatedCoachingProductOrgCategory } from '../../../types/ProductCatalog';

type TDedicatedCoachingOrgCategoryCardProps = {
  orgCategory: TDedicatedCoachingProductOrgCategory;
  handleShowProduct: (selectedCategory: TDedicatedCoachingProductOrgCategory) => void;
  disabled: boolean;
};

export const DedicatedCoachingOrgCategoryCard = (props: TDedicatedCoachingOrgCategoryCardProps) => {
  const { orgCategory, handleShowProduct, disabled } = props;
  const { data: productCatalog } = useProductCatalog();
  const now = new Date();
  const activeSale =
    now >= productCatalog?.sale?.startDate && now < productCatalog?.sale?.endDate ? productCatalog.sale : null;

  const formatPrice = (price: number) => '$' + (price / 100).toLocaleString('en-US', { maximumFractionDigits: 2 });

  const formatName = (name: string) => `${name[0].toUpperCase()}${name.slice(1)}`;

  return (
    <div className={'relative flex flex-col' + (disabled ? ' disabled' : '')}>
      <div
        className={'h-20 w-full cursor-pointer overflow-hidden rounded-md bg-gray-100 hover:opacity-80 md:h-40'}
        onClick={() => handleShowProduct(orgCategory)}
      >
        <img
          src={orgCategory.image}
          alt={`${formatName(orgCategory.name)} dedicated coaching product`}
          className="h-full w-full shrink object-contain object-center p-6 md:py-14"
        />
        <div className="absolute inset-x-0 top-0 flex items-end justify-end overflow-hidden p-1 md:p-2">
          <div aria-hidden="true" className="h-18 absolute inset-x-0 bottom-0 md:h-36" />
        </div>
      </div>
      <div className="mt-4 flex grow flex-col place-self-stretch">
        <h3
          className="cursor-pointer font-['Inter'] text-xl font-medium text-gray-900"
          onClick={() => handleShowProduct(orgCategory)}
        >
          {formatName(orgCategory.name)}
        </h3>
      </div>
      <div className="mt-2 flex flex-col">
        <span className="text-sm text-gray-500">Starting at</span>
        <div className="flex">
          <div className="inline-flex ">
            <span className="font-['Inter'] text-lg font-bold">{formatPrice(orgCategory.startingUnitPrice)}</span>
            {activeSale && (
              <span className="mx-1 inline-flex items-center py-0 font-['Inter'] text-lg font-light tracking-tighter">
                <svg className="mx-1 h-2 w-2 text-lime-600" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx={4} cy={4} r={3} />
                </svg>
                Sale applied
              </span>
            )}
          </div>
        </div>
      </div>
      <button
        className="mt-2 flex items-center justify-center rounded-md border border-solid border-[#0027F5] bg-white py-2 px-8 font-medium font-semibold text-[#0027F5] hover:bg-[#0027F5] hover:text-white"
        onClick={() => handleShowProduct(orgCategory)}
      >
        View Packages
      </button>
    </div>
  );
};
