import { useState, useEffect } from 'react';
import { CheckIcon, XIcon, ExclamationIcon } from '@heroicons/react/solid';
import NiceModal from '@ebay/nice-modal-react';

import { DedicatedCoachingCatalog } from '../../components/DedicatedCoaching/DedicatedCoachingCatalog/DedicatedCoachingCatalog';
import { DedicatedCoachingSaleBanner } from '../../components/DedicatedCoaching/DedicatedCoachingSaleBanner/DedicatedCoachingSaleBanner';
import Testimonial from '../../elements/Testimonial';
import testimonialImage from '../../assets/images/MjnariRichards.png';

import { TProduct } from '../../types/ProductCatalog';
import { useProductCatalog } from '../../hooks/useProductCatalog';
import { SingleActionModal } from '../../components/Modals/SingleActionModal';
import { MentorLink } from '../../components/DedicatedCoaching/DedicatedCoachingModal/DedicatedCoachingModal';

const removeSearchParams = () =>
  window.history.replaceState(null, '', `${window.location.pathname}${window.location.hash}`);

export type TDedicatedCoachingPageProps = {
  showBundleCheckout: (
    dedicatedCoachingSku: { catalogItem: TProduct; mentorLink: MentorLink },
    onPaymentConfirmation: () => void
  ) => null;
};

const DedicatedCoachingPage = (props: TDedicatedCoachingPageProps) => {
  const { showBundleCheckout } = props;
  const { data: productCatalog } = useProductCatalog();

  const [purchaseAttempted, setPurchaseAttempted] = useState(false);
  const now = new Date();
  const activeSale =
    now >= productCatalog?.sale?.startDate && now < productCatalog?.sale?.endDate ? productCatalog.sale : null;
  const testimonialText =
    "I don't think I would have passed without the help of interviewing.io. The dedicated coaching sessions helped me to feel more confident in my answers, learn subtle topics I didn't know that I should know, and learn to better express the things I did know.";
  const testimonialAuthor = 'Mjnari Richards, Software Engineer at Amazon';

  const showPaymentConfirmationModal =
    (purchase: TProduct, mentorLink: MentorLink, dcCourseSurveyLinkId: string) => () => {
      setPurchaseAttempted(true);
      const mentorLinkUsed = !!mentorLink;

      // Build survey link
      let surveyLinkPrefix;
      if (mentorLinkUsed) {
        surveyLinkPrefix = purchase.dedicatedCoachingMetadata.mentorPrepSurveyUrl;
      } else {
        surveyLinkPrefix = purchase.dedicatedCoachingMetadata.matchingSurveyUrl;
      }
      const surveyLink = `${surveyLinkPrefix}#dc_course_survey_link_id=${dcCourseSurveyLinkId}`;

      NiceModal.show(SingleActionModal, {
        actionLabel: 'Got it',
        title: 'Thanks for your purchase!',
        handleAction: removeSearchParams,
        icon: (
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
        ),
        message: (
          <>
            <p>
              Congrats, and thank you for doing dedicated coaching! You've made a great decision here.{' '}
              {mentorLinkUsed ? 'To better prepare your mentor, ' : 'So we can get you matched with the right mentor, '}{' '}
              please fill out{' '}
              <a href={surveyLink} target="_blank">
                this questionnaire.
              </a>{' '}
              We’ll be in touch with {mentorLinkUsed ? '' : 'your match and'} next steps within 2 business days.
            </p>
            <p>
              If you have any questions, please email{' '}
              <a href="mailto:support@interviewing.io">support@interviewing.io</a>.
            </p>
          </>
        ),
      });
    };

  const showPurchaseCanceledModal = () => {
    setPurchaseAttempted(true);
    NiceModal.show(SingleActionModal, {
      actionLabel: 'Ok',
      title: 'Purchase canceled',
      handleAction: removeSearchParams,
      icon: (
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100">
          <ExclamationIcon className="h-6 w-6 text-orange-600" aria-hidden="true" />
        </div>
      ),
      message: (
        <p>
          If you have any questions, please email <a href="mailto:support@interviewing.io">support@interviewing.io</a>.
        </p>
      ),
    });
  };

  const showPurchaseFailedModal = () => {
    setPurchaseAttempted(true);
    NiceModal.show(SingleActionModal, {
      actionLabel: 'Ok',
      title: 'Purchase failed',
      handleAction: removeSearchParams,
      icon: (
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
      ),
      message: (
        <>
          <p>
            Unfortunately this transaction was unsuccessful. Affirm's{' '}
            <a href="https://helpcenter.affirm.com/us/s/article/loan-approvals" target="_blank">
              help center article
            </a>{' '}
            has information on the loan approval process.
          </p>
          <p>Consider using a credit card for payment.</p>

          <p>
            If you have any questions, please email <a href="mailto:support@interviewing.io">support@interviewing.io</a>
            .
          </p>
        </>
      ),
    });
  };

  const showPurchaseIncompleteModal = () => {
    setPurchaseAttempted(true);
    NiceModal.show(SingleActionModal, {
      actionLabel: 'Ok',
      title: 'Purchase incomplete',
      handleAction: removeSearchParams,
      icon: (
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
      ),
      message: (
        <>
          <p>This purchase could not be completed due to technical difficulties.</p>
          <p>
            Please email <a href="mailto:support@interviewing.io">support@interviewing.io</a> for assistance.
          </p>
        </>
      ),
    });
  };

  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    if (queryString.get('step') !== 'affirmCheckoutRedirect') {
      return;
    }

    const affirmCartData = JSON.parse(localStorage.getItem('affirmCart'));
    localStorage.removeItem('affirmCart');

    const affirmStatus = queryString.get('status');
    const orderId = queryString.get('orderId');

    if (orderId !== affirmCartData.orderId) {
      showPurchaseIncompleteModal();
      return;
    }

    switch (affirmStatus) {
      case 'success':
        try {
          window.angular
            ?.element(document.body)
            .injector()
            .get('AnalyticsService')
            .track(
              window.CONFIG.const.analytics.eventNames.EVENT_ECOMMERCE_ORDER_COMPLETED,
              affirmCartData.analyticsTransactionContext
            );
        } catch (err) {
          window.Rollbar.warn('Error tracking (Affirm) Order Completed event', err);
        }
        const [{ catalogItem }] = affirmCartData.cartItems;
        showPaymentConfirmationModal(catalogItem, affirmCartData.mentorLink, affirmCartData.dcCourseSurveyLinkId)();
        break;
      case 'canceled':
        showPurchaseCanceledModal();
        break;
      case 'failed':
        showPurchaseFailedModal();
        break;
      case 'other':
      default:
        showPurchaseIncompleteModal();
        break;
    }
  }, []);

  return (
    <div className="mb-4 flex flex-col sm:gap-8">
      <div className="flex flex-row flex-wrap md:gap-x-4 lg:flex-nowrap">
        <div className="lg:flex-auto">
          <h1 className="font-['Inter'] text-xl font-black">
            Want the ultimate competitive advantage? Get coached by the same people who make hiring decisions.
          </h1>
          <div className="font-['Inter'] text-lg">
            <p>
              Our Dedicated Coaching mentors are our best, most experienced interviewers, and they know{' '}
              <span className="italic">exactly</span> what it takes to get hired. Your mentor will assess where you're
              at, come up with a custom curriculum tailored to you and the role you're applying for, and cover
              everything you need to get the offer. This is the best prep money can buy.
            </p>
            <p>
              Have questions about payment, or not sure which bundle is right for you? Check out the{' '}
              <a
                href="https://docs.google.com/document/d/1d7mJDPkEykKzSYjj-Wmza-UXGNAkL7kphwcpmmWOjRg/edit?usp=sharing"
                target="_blank"
                className="text-[#0027F5] underline"
              >
                Dedicated Coaching FAQ
              </a>{' '}
              or{' '}
              <a href="mailto:support@interviewing.io" className="text-[#0027F5] underline">
                email us
              </a>
              .
            </p>
            <p>Oh! You also get $500 off on salary negotiation when you buy any dedicated coaching package.</p>
          </div>
        </div>
        <div className="flex-col lg:w-1/3 lg:flex-none xl:w-1/4">
          <div className="rounded-lg bg-[#f4f8fd] p-4">
            <div className="mb-2 font-['Inter'] text-lg font-bold">
              82% of participants (so far) were hired by their target company after completing the course
            </div>
            <div className="font-['Inter'] text-base font-normal">
              99% were happy with their purchase (yes, really… we know it sounds nuts).
            </div>
          </div>
        </div>
      </div>
      {activeSale && <DedicatedCoachingSaleBanner activeSale={activeSale} />}
      <DedicatedCoachingCatalog
        showBundleCheckout={showBundleCheckout}
        showPaymentConfirmationModal={showPaymentConfirmationModal}
        purchaseAttempted={purchaseAttempted}
      />
      <Testimonial imgPath={testimonialImage} text={testimonialText} author={testimonialAuthor} />
    </div>
  );
};

export default DedicatedCoachingPage;
