type Props = {
  stepTotal: number;
  currentStep: number;
  stepLabels?: string[];
};

const getStepStatus = (currentStep: number, stepIndex: number) => {
  if (currentStep > stepIndex) {
    return 'complete';
  }

  if (currentStep === stepIndex) {
    return 'current';
  }

  return 'upcoming';
};

const ProgressDots = (props: Props) => {
  const { stepTotal, currentStep, stepLabels } = props;
  const steps = Array.from(Array(stepTotal).keys()).map((step) => ({
    name: stepLabels?.[step] ?? `Step ${step + 1}`,
    status: getStepStatus(currentStep, step),
  }));

  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      <ol role="list" className="flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {step.status === 'complete' ? (
              <a className="block w-2.5 h-2.5 bg-blue-700 rounded-full hover:bg-blue-900">
                <span className="sr-only">{step.name}</span>
              </a>
            ) : step.status === 'current' ? (
              <a className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-indigo-200" />
                </span>
                <span className="relative block w-2.5 h-2.5 bg-blue-700 rounded-full" aria-hidden="true" />
                <span className="sr-only">{step.name}</span>
              </a>
            ) : (
              <a className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </a>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default ProgressDots;
