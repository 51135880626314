import axios from '../../../utils/axios';

import { useState } from 'react';

type TAddSessionResult = {
  error?: unknown;
  dcCourseId?: unknown;
  menteeId?: unknown;
  mentorOrgId?: unknown;
} | null;

export const DCRelocateSessionsForm = (props: {
  dcCourseId?: string;
  sessionIds?: string[] | string;
  menteeId: string;
}) => {
  const { dcCourseId, sessionIds: queryParamSessions, menteeId } = props;

  let sessionIds: string[];
  if (typeof queryParamSessions === 'string') {
    sessionIds = [queryParamSessions];
  } else {
    sessionIds = queryParamSessions;
  }

  const [destinationDCCourseId, setDestinationDCCourseId] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [result, setResult]: [TAddSessionResult, (arg0: TAddSessionResult) => void] = useState(null);
  const submitForm = () => {
    axios
      .patch(`/api/dc-courses/${dcCourseId}/dc-sessions`, {
        modification: 'relocate-sessions',
        menteeId,
        sessionIds,
        destinationDCCourseId,
      })
      .then((response) => {
        setResult(response.data);
      })
      .catch((error: unknown) => {
        setResult({ error });
      });
  };

  return result ? (
    result.error ? (
      <>
        <div className="red mt-8"> Error </div>
        <div>
          {' '}
          <a href="/admin/dc-courses"> Return to DC Dashboard</a>{' '}
        </div>
      </>
    ) : (
      <div className="flex-column flex gap-8">
        <h2 className="mt-8">Success! </h2>
        <a href={'/admin/dc-courses/' + dcCourseId}> Go back to course page </a>
        <a href={'/admin/dc-courses/' + destinationDCCourseId}> Go to destination course page </a>
        <a href={'/admin/users?menteeId=' + menteeId}> Go to to mentee's user page</a>
      </div>
    )
  ) : (
    <>
      <h2 className="mt-8">Relocating {sessionIds.length} sessions</h2>
      <div className="flex-column card mt-4 flex max-w-prose gap-4 rounded border border-gray-200 p-2">
        <label className="font-bold">Origin DC Course</label>
        <input value={dcCourseId} type="text" readOnly></input>

        <label className="font-bold">Destination DC Course</label>
        <input
          value={destinationDCCourseId}
          type="text"
          placeholder="Paste DC Course ID (like 553f....)"
          onChange={(e) => setDestinationDCCourseId(e.target.value)}
        ></input>

        <button onClick={submitForm} className="m-auto rounded p-1 text-lg font-bold text-white">
          Relocate sessions
        </button>
      </div>

      <a href={'/admin/dc-courses/' + dcCourseId}> Go back to course page </a>
    </>
  );
};
