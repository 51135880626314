import { lazy, Suspense, useState, useEffect } from 'react';

import useWindowDimensions from '../../hooks/useWindowDimensions';

const Whiteboard = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "whiteboard" */ './WhiteboardComponent')
);

const WhiteboardContainer = () => {
  const [isWhiteboardVisible, setIsWhiteboardVisible] = useState<boolean>(false);
  const [friendlyId, setFriendlyId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [socket, setSocket] = useState<Record<string, (event: string, data?: any) => void>>(null);

  const [mode, setMode] = useState<'live' | 'playground' | 'replay'>('playground');

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const { height, width } = useWindowDimensions();

  window.setIsWhiteboardVisible = setIsWhiteboardVisible;

  window.toggleWhiteboardCollaboration = (
    friendlyId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socket: Record<string, (event: string, data?: any) => void>,
    mode: 'live' | 'replay' | 'playground'
  ) => {
    setFriendlyId(friendlyId);
    setSocket(socket);
    setIsWhiteboardVisible((current) => !current);
    setMode(mode);
  };

  const FOOTER_HEIGHT = 96;
  const MEDIUM_BREAKPOINT = 768;
  const LEFT_BAR_WIDTH = 80;
  const TOP_BAR_HEIGHT = 48;

  const [containerTop, setContainerTop] = useState<number>(0);
  const [containerLeft, setContainerLeft] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    const triggerFullscreen = width <= MEDIUM_BREAKPOINT || isFullscreen;

    setContainerTop(triggerFullscreen ? 0 : TOP_BAR_HEIGHT);
    // Live mode does not have a left bar
    setContainerLeft(triggerFullscreen ? 0 : mode === 'live' ? 0 : LEFT_BAR_WIDTH);
    setContainerHeight(height);

    if (!triggerFullscreen) {
      setContainerHeight((currHeight) => currHeight - FOOTER_HEIGHT);
    } else if (mode === 'replay') {
      // We need to leave space for the playback slider in replay mode if we toggle whiteboard fullscreen
      setContainerHeight((currHeight) => currHeight - FOOTER_HEIGHT / 2);
    }
  }, [isFullscreen, height, width, mode]);

  return (
    isWhiteboardVisible && (
      <div
        className="absolute right-0 flex bg-black"
        style={{
          height: containerHeight,
          top: containerTop,
          left: containerLeft,
        }}
      >
        <Suspense fallback="Loading">
          <Whiteboard
            onClose={() => setIsWhiteboardVisible(false)}
            socket={socket}
            friendlyId={friendlyId}
            mode={mode}
            toggleFullscreen={() => setIsFullscreen((current) => !current)}
          />
        </Suspense>
      </div>
    )
  );
};

export default WhiteboardContainer;
