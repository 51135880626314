import { ClipboardCopyIcon } from '@heroicons/react/outline';
import { ICellRendererParams, ValueFormatterFunc } from 'ag-grid-community';
import { useToasts } from './ToastContext';

export const formatUserTimeZoneDate = (timezone: string, dateNum: number) => {
  const date = new Date(dateNum);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timezone,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  return formattedDate;
};

export const UserTimeZoneDateField = (props: { timezone: string; dateNum: number }) => {
  const { dateNum, timezone } = props;
  const formattedDate = formatUserTimeZoneDate(timezone, dateNum);
  return <span>{formattedDate}</span>;
};

export const UserTimezoneDateFormatter = (timezone: string) => (params: { value?: number }) => {
  if (!params.value) {
    return ''; // Handle null or undefined values
  }

  const dateNum = params.value;

  return <UserTimeZoneDateField timezone={timezone} dateNum={dateNum} />;
};

export const spreadsheetFriendlyDateTimeFormatter: ValueFormatterFunc = (p) => {
  const timezoneIndex = 23;
  return p.data.createdAt.slice(0, timezoneIndex);
};

export const SuperAdminHyperlinkField = (props: {
  anchorHref: string;
  anchorText: string;
  clipboardValue: string;
  clipboardSuccessMessage: string;
}) => {
  const { dispatch } = useToasts();
  const { anchorHref, anchorText, clipboardValue, clipboardSuccessMessage } = props;

  const copyToClipboard = (value: string, message: string) => {
    try {
      navigator.clipboard.writeText(value);
      dispatch({
        type: 'addToast',
        toastContent: {
          primaryMessage: 'Clipboard copy',
          secondaryMessage: message,
          displayTimeout: 3000,
        },
      });
    } catch {}
  };

  return (
    <span>
      <a href={anchorHref} rel="noopener noreferrer">
        {anchorText}
      </a>{' '}
      <button
        style={{ backgroundColor: 'transparent' }}
        onClick={(e) => {
          e.preventDefault();
          copyToClipboard(clipboardValue, clipboardSuccessMessage);
        }}
      >
        <ClipboardCopyIcon className="ml-1 inline h-3 w-3 cursor-pointer text-black" aria-hidden="true" />
      </button>
    </span>
  );
};

export const SuperAdminHyperlinkCellRenderer =
  <D, V>(
    valueFormatter: (value: V) => string,
    linkFormatter: (data: D) => string,
    getClipboardValue: (data: D) => string,
    getClipboardCopySuccessMessage: (data: D) => string
  ) =>
  (params: ICellRendererParams<D, V>): JSX.Element => {
    if (!params.value) {
      return null; // Handle null or undefined values
    }
    const anchorHref = linkFormatter(params.data);
    const anchorText = valueFormatter(params.value);
    const clipboardValue = getClipboardValue(params.data);
    const clipboardSuccessMessage = getClipboardCopySuccessMessage(params.data);

    return (
      <SuperAdminHyperlinkField
        anchorHref={anchorHref}
        anchorText={anchorText}
        clipboardValue={clipboardValue}
        clipboardSuccessMessage={clipboardSuccessMessage}
      />
    );
  };
