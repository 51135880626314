import { Dialog, Transition } from '@headlessui/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Select, { iioSelectOption } from '../../../elements/Select';
import type { ColDef, ColGroupDef, GridOptions, GridReadyEvent, ITooltipParams } from 'ag-grid-community';
import { Suspense, lazy, useRef, useState, Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useProductCatalog } from '../../../hooks/useProductCatalog';
import { TProduct } from '../../../types/ProductCatalog';
import { TDCCourseSummary } from '../../../types/DCCourseSummary';

import axios from '../../../utils/axios';
import { useUser } from '../../../userContext';
import {
  SuperAdminHyperlinkCellRenderer,
  UserTimezoneDateFormatter,
  spreadsheetFriendlyDateTimeFormatter,
} from '../../superAdminFormatters';
const LazyLoadedAGGrid = lazy(() => import('../../../elements/LazyLoadedAGGrid'));

const statusExplanations: Record<string, string> = {
  Purchased: 'Course purchased. Intake form NOT complete.',
  Matching: 'Intake form complete. No mentor assigned.',
  'In progress': 'Intake form complete. Mentor assigned.',
  Completed: 'All sessions within this course completed.',
  Inactive: '90 days since last round scheduled. More than half of sessions remaining.',
  'Complete with sessions remaining': '90 days since last round scheduled. Fewer than half of sessions remaining.',
  'Clawed Back': 'Max one session conducted. Rest clawed back.',
  Uncategorized: 'None of the current statuses apply to this course',
};

const CreateDCCourseModal = NiceModal.create(() => {
  const modal = useModal();
  const queryClient = useQueryClient();

  const [newCourseUserId, setNewCourseUserId] = useState('');
  const [newCourseOrgId, setNewCourseOrgId] = useState(null);

  const createCourse = () => {
    axios.post('api/dc-courses', { userId: newCourseUserId, orgId: newCourseOrgId }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['dc-courses'] });
    });
    setNewCourseUserId('');
    modal.remove();
  };

  const handleNewCourseUserIdChange = (event: { target: { value: string } }) => {
    setNewCourseUserId(event.target.value);
  };

  const afterTransitionLeave = () => {
    modal.remove();
  };
  // Get dedicated coachign orgids from product catalog
  const { data: productCatalog } = useProductCatalog();
  let dcCourseProducts: iioSelectOption<string>[] = [];
  if (productCatalog) {
    const distinctDcCourseProductsOrgIds = Array.from(
      new Set(
        productCatalog?.products
          .filter((product) => product.dedicatedCoachingMetadata)
          .map((product) => product.dedicatedCoachingMetadata?.orgId)
      )
    );
    dcCourseProducts = distinctDcCourseProductsOrgIds
      .map((id) => productCatalog.products.find((product: TProduct) => product.dedicatedCoachingMetadata?.orgId === id))
      .map((product: TProduct) => ({ value: product.dedicatedCoachingMetadata?.orgId, label: product.companyName }));
  }

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={modal.remove}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:min-w-[400px] sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h2" className="text-lg font-medium leading-6">
                      Create Course
                    </Dialog.Title>
                    <form>
                      <div className="my-6">
                        <Select
                          label="Which DC Org is this course for?"
                          options={dcCourseProducts}
                          onChange={setNewCourseOrgId}
                          selected={dcCourseProducts.find((option) => option.value === newCourseOrgId)}
                          maxHeightClassName="max-h-24"
                        />
                      </div>
                      <div className="my-6">
                        <label className="block pb-1 text-base font-light" id="headlessui-listbox-label-8">
                          Mentee User Id{' '}
                        </label>
                        <input
                          type="text"
                          onChange={handleNewCourseUserIdChange}
                          value={newCourseUserId}
                          placeholder="Paste User ID (like 553f....)"
                        ></input>
                      </div>
                      <button
                        type="button"
                        className="font-small inline-flex w-max justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                        onClick={createCourse}
                        tabIndex={0}
                      >
                        Create Course
                      </button>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export const DCCoursesSuperAdminTab = () => {
  const { user } = useUser();

  const gridApi = useRef(null);

  const onGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api;
  };

  const { data: productCatalog } = useProductCatalog();
  const findProductLink = (dcCourse: TDCCourseSummary) => {
    if (!dcCourse.org) return null;

    const productOrgId = dcCourse.org._id;
    const product = productCatalog?.products?.find(
      (product) => product?.dedicatedCoachingMetadata?.orgId === productOrgId
    );
    if (product) {
      const dcCourseSurveyLinkId = dcCourse.surveyLinkId;
      const surveyLinkPrefix = product.dedicatedCoachingMetadata?.matchingSurveyUrl;
      const surveyLink = `${surveyLinkPrefix}#dc_course_survey_link_id=${dcCourseSurveyLinkId}`;
      return surveyLink;
    }

    return null;
  };
  const { data, isLoading } = useQuery({
    queryKey: ['dc-courses'],
    queryFn: () => axios.get('api/dc-courses/').then((response) => response.data),
  });

  const gridOptions: GridOptions = {
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    enableCellTextSelection: true,
  };

  const columnDefs: (ColDef | ColGroupDef)[] = [
    {
      colId: 'id',
      field: '_id',
      headerName: 'Id',
      hide: true,
    },
    {
      colId: 'dcCourseId',
      cellRenderer: SuperAdminHyperlinkCellRenderer<TDCCourseSummary, TDCCourseSummary['_id']>(
        (value) => value,
        (data) => `/admin/dc-courses/${data._id}`,
        (data) => data._id,
        (data) => `DC Course Id "${data._id}" copied to clipboard`
      ),
      field: '_id',
      filter: true,
      headerName: 'DC Course Id',
      pinned: 'left',
      sortable: true,
      headerTooltip: 'The DC Course Id',
    },
    {
      colId: 'menteeId',
      field: 'mentee._id',
      headerName: 'Mentee Id',
      hide: true,
    },
    {
      colId: 'menteeEmail',
      cellRenderer: SuperAdminHyperlinkCellRenderer<TDCCourseSummary, TDCCourseSummary['mentee']['email']>(
        (value) => value,
        (data) => `/admin/users/?userId=${data.mentee._id}`,
        (data) => data.mentee._id,
        (data) => `User Id for "${data.mentee.email}" copied to clipboard`
      ),
      field: 'mentee.email',
      headerName: 'Mentee email',
      pinned: 'left',
      sortable: true,
    },
    {
      colId: 'menteeId',
      field: 'mentee._id',
      headerName: 'Mentee Id',
      hide: true,
    },
    {
      colId: 'dcCourseDetails',
      children: [
        {
          colId: 'orgId',
          field: 'org._id',
          headerName: 'Org Id',
          hide: true,
        },
        {
          colId: 'orgName',
          cellRenderer: SuperAdminHyperlinkCellRenderer<TDCCourseSummary, TDCCourseSummary['org']['companyName']>(
            (value) => value,
            (data) => `/admin/orgs?orgId=${data.org._id}`,
            (data) => data.org._id,
            (data) => `Org Id for "${data.org.companyName}" copied to clipboard`
          ),
          field: 'org.companyName',
          filter: true,
          headerName: 'Org',
          headerTooltip: 'DC Course org',
          sortable: true,
        },
        {
          colId: 'focus',
          field: 'focus',
          filter: true,
          headerName: 'Focus',
          headerTooltip: 'Focus',
          sortable: true,
        },
        {
          field: 'sessions',
          filter: true,
          headerName: 'Sessions',
          headerTooltip: 'Sessions at the time of creation',
        },
      ],
      headerName: 'DC Course details at creation',
    },
    {
      cellRenderer: UserTimezoneDateFormatter(user.timezone),
      field: 'createdAt',
      filter: true,
      headerName: 'Created At',
      headerTooltip: 'DC Course creation time',
      sortable: true,
      tooltipValueGetter: (p: ITooltipParams) => p.value,
    },
    {
      field: 'createdAtSpreadsheetFriendly',
      hide: true,
      headerName: 'Created At (Spreadsheet Friendly)',
      valueFormatter: spreadsheetFriendlyDateTimeFormatter,
    },
    {
      field: 'status',
      filter: true,
      headerName: 'Status',
      headerTooltip: 'Where the candidate is in their course',
      sortable: true,
      tooltipValueGetter: (p: ITooltipParams) => p.value + ': ' + statusExplanations[p.value],
    },
    {
      field: 'allSessions',
      filter: true,
      headerName: 'All session grants',
      headerTooltip: 'All session grants associated with this course',
      sortable: true,
    },
    {
      colId: 'intakeSurveyLink',
      cellRenderer: SuperAdminHyperlinkCellRenderer<TDCCourseSummary, TDCCourseSummary>(
        () => 'Survey Link',
        () => '#',
        (data) => findProductLink(data),
        (data) => findProductLink(data) && `Survey link for this DC Course copied to clipboard`
      ),
      headerName: 'Intake Survey Link',
      field: 'org',
    },
  ];

  const csvExportParams = {
    columnKeys: [
      'dcCourseId',
      'menteeEmail',
      'menteeId',
      'orgId',
      'orgName',
      'focus',
      'sessions',
      'createdAt',
      'createdAtSpreadsheetFriendly',
      'status',
      'allSessions',
    ],
  };

  const exportToCsv = () => gridApi.current?.exportDataAsCsv();

  const showCreateCourseModal = () => {
    NiceModal.show(CreateDCCourseModal);
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <h2 className="mt-4">DC Course List</h2>
      <Suspense fallback={<div className="my-4">Loading...</div>}>
        <div className="flex justify-end">
          <button
            className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 "
            onClick={showCreateCourseModal}
          >
            Create new course
          </button>
        </div>

        <LazyLoadedAGGrid
          onGridReady={onGridReady}
          defaultCsvExportParams={csvExportParams}
          gridOptions={gridOptions}
          className="ag-theme-alpine my-4 w-full text-xs"
          style={{ height: '500px' }}
          columnDefs={columnDefs}
          rowData={data.rows}
        />
      </Suspense>
      <div className="my-2 flex flex-col gap-2">
        <div>
          <button className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600" onClick={exportToCsv}>
            Export to CSV
          </button>
        </div>
      </div>
    </>
  );
};

export default DCCoursesSuperAdminTab;
