import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axiosInstance from '../../utils/axios';

import './styles.css';

export type TModalProps = {
  handleAction?: () => void;
  afterClose: () => void;
  icon: JSX.Element | string;
  edittedTranscript: Record<string, any>;
  fetchData: () => Promise<void>;
  interview: Record<string, any>
};

const DeleteTranscriptModal = NiceModal.create((props: TModalProps) => {
  const modal = useModal();
  const { interview, fetchData, handleAction, afterClose, icon: ModalIcon, edittedTranscript } = props;

  const performAction = async () => {
    handleAction?.();
    modal.hide();
  };

  const deleteTranscript = async () => {
    await axiosInstance.delete(`api/interviews/${interview.friendlyId}/deleteTranscript/${edittedTranscript.id}`)
    await fetchData();
    performAction();
  };

  const afterTransitionLeave = () => {
    modal.remove();
    afterClose?.();
  };

  return (
    <Transition.Root show={modal.visible} as={Fragment} afterLeave={afterTransitionLeave}>
      <Dialog as="div" className="relative z-10" onClose={performAction}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-[#1E2126] relative max-w-2xl transform overflow-hidden rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div>
                  {ModalIcon}
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-white">
                      Delete edited transcript
                    </Dialog.Title>
                    <div className="mt-5 sm:mt-6 text-white" style={{ overflow: "auto", maxHeight: "60vh" }}>
                      You must delete the current edited transcript before making a new one. The original transcript will remain unaffected.
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col">
                  <div className='w-full items-center justify-center flex'>
                    <button
                      type="button"
                      className="inline-flex w-max justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                      onClick={deleteTranscript}
                      tabIndex={0}
                    >
                      Delete edited
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export { DeleteTranscriptModal };
