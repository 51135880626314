/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import Search from '../../../../elements/Search';
import Button from '../../../../elements/Button';
import Chips from '../../../../elements/Chips';

export type AppliedProps = {
  buttonLabel?: string;
  onSubmit?: (applied?: string[]) => void;
  searchCallback?: (value: string) => Promise<any[]>;
  clearInput?: boolean;
};

const Applied = (props: AppliedProps) => {
  const { buttonLabel, searchCallback, onSubmit, clearInput }: AppliedProps = props;
  const [applied, setApplied] = useState<any>([]);

  const handleDeselect = (company: string) => {
    setApplied(applied.filter((c: string) => c !== company));
  };

  return (
    <div className="h-full w-full">
      <div className="mt-4">
        <Search
          label="Here are a few ideas, but type in any others."
          onSelect={(selectedCompany) => {
            if (selectedCompany && !applied.includes(selectedCompany.name)) {
              setApplied([...applied, selectedCompany.name]);
            }
          }}
          searchCallback={searchCallback}
          labelSelector={(value: any): string => value?.name}
          clearInput={clearInput}
          showDropdownOnFocus={true}
        />
        {applied.length > 0 && (
          <div
            className="
              relative bottom-0 mt-4
              flex-row flex-wrap"
          >
            {applied.map((company: any) => (
              <span key={company} className="mb-2 mr-2 h-full">
                <Chips label={company} handleDeselect={handleDeselect} />
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="mt-6 flex h-full w-full flex-col"></div>
      <div
        className="
        relative inset-x-0 bottom-0 mt-4 flex
        h-auto w-full flex-row-reverse"
      >
        <Button label={buttonLabel} size="fill" onClick={() => onSubmit(applied)} />
      </div>
    </div>
  );
};

export default Applied;
