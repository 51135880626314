/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Experience from '../modalFrames/Experience/Experience';
import CurrentCompany from '../modalFrames/CurrentCompany/CurrentCompany';
import AppliedCompanies from '../modalFrames/AppliedCompanies/AppliedCompanies';
import ProgressDots from '../../../elements/ProgressDots';
import Pseudonym from '../modalFrames/Pseudonym/Pseudonym';
import Button from '../../../elements/Button';
import Urgency from '../modalFrames/Urgency/Urgency';
import { useUser } from '../../../userContext';

type Props = {
  onExperienceSubmit: (engineerType: string, yoe: number) => Promise<void>;
  onCurrentCompanySubmit: (
    currentCompany?: string,
    shouldAvoidCompany?: boolean,
    isHiringManager?: boolean
  ) => Promise<void>;
  onAppliedCompaniesSubmit: (appliedCompanies?: string[]) => void;
  onOnboardingCompleted: () => Promise<void>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchCallback: (value: string, showDefault: boolean) => Promise<any[]>;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const OnboardingModal = (props: Props) => {
  const {
    onExperienceSubmit,
    onCurrentCompanySubmit,
    onAppliedCompaniesSubmit,
    onOnboardingCompleted,
    searchCallback,
    open = false,
    setOpen = () => {},
  } = props;
  const { user } = useUser();

  const [step, setStep] = useState(0);
  const [urgency, setUrgency] = useState();
  // TODO: Re-write with react-hook-form and react-query mutations
  // Currently, state is used for value inserted/updated into the form. User (via Provider ... which may be nullish ...) record is the default submission value
  const [YOE, setYOE] = useState();

  return (
    user && (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => {
            /* Don't close when clicking outside */
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto px-2 sm:px-0">
            <div className="flex min-h-full items-center justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-y-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-10 sm:w-full sm:min-w-[450px] sm:max-w-sm sm:py-4 sm:px-0 sm:pt-10">
                  <Dialog.Title as="h3" className="px-2 text-sm font-medium leading-6 text-gray-900 sm:px-10">
                    {step === 0 && (
                      <>
                        <div className="pb-1 text-3xl font-extrabold">Welcome, friend.</div>
                        <div className="text-base font-normal">
                          We're an anonymous platform, so we need you to pick your anonymous handle. If you want, we can
                          keep you from matching with coworkers.
                        </div>
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <div className="pb-1 text-3xl font-extrabold">Next...</div>
                        <div className="text-base font-normal">
                          We need a few details to better match you with potential interviewers.
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <div className="pb-1 text-3xl font-extrabold">Almost done...</div>
                        <div className="text-base font-normal">
                          Which companies do you plan to apply at / are already in process with?
                        </div>
                      </>
                    )}
                  </Dialog.Title>
                  <div className="my-4 border-b border-gray-200"></div>
                  <div className="px-2 py-2 sm:px-10">
                    {step === 0 && (
                      <>
                        <Pseudonym />
                        <CurrentCompany
                          buttonLabel="Next"
                          onSubmit={(currentCompany, shouldAvoidCompany, isHiringManager) => {
                            setStep(1);
                            onCurrentCompanySubmit(currentCompany, shouldAvoidCompany);
                            onCurrentCompanySubmit(currentCompany, shouldAvoidCompany, isHiringManager);
                          }}
                          searchCallback={(val) => searchCallback(val, false)}
                          clearInput={false}
                        />
                      </>
                    )}
                    {step === 1 && (
                      <div className="w-100">
                        <Urgency urgency={urgency} setUrgency={setUrgency} />
                        <Experience YOE={YOE != null ? YOE : user.types.yearsExperience} setYOE={setYOE} />
                        <div
                          className="
                      relative flex h-10 w-full flex-row-reverse"
                        >
                          <Button
                            label="Next"
                            size="fill"
                            disabled={!urgency || !!(YOE == null && user.types.yearsExperience == null)}
                            onClick={() => {
                              setStep(2);
                              onExperienceSubmit(urgency, YOE != null ? YOE : user.types.yearsExperience);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <AppliedCompanies
                        buttonLabel="Get started"
                        onSubmit={(applied) => {
                          setStep(3);
                          onAppliedCompaniesSubmit(applied);
                          onOnboardingCompleted();
                          setOpen(false);
                        }}
                        searchCallback={(val) => searchCallback(val, true)}
                        clearInput={true}
                      />
                    )}
                    <div className="mt-4 flex flex-col items-center">
                      <ProgressDots stepTotal={3} currentStep={step} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
};

export default OnboardingModal;
