import { useState } from 'react';
import YesNoDialog from './YesNoDialog';

type Props = {
  message?: string;
  successMessage?: string;
  failMessage?: string;
  onSuccess?: () => void;
  onFail?: () => void;
  open?: boolean;
};

declare global {
  interface Window {
    showYesNoDialog: (props: Props) => void;
  }
}

const YesNoDialogContainer = () => {
  const [isYesNoDialogVisible, setIsYesNoDialogVisible] = useState(false);
  const [input, setInput] = useState<Props>({});

  window.showYesNoDialog = (props: Props) => {
    setIsYesNoDialogVisible(true);
    setInput(props);
  };

  return <YesNoDialog open={isYesNoDialogVisible} setOpen={setIsYesNoDialogVisible} {...input} />;
};

export default YesNoDialogContainer;
