import { useQuery } from '@tanstack/react-query';
import axios from '../../../../utils/axios';
import InterviewerOverviewPanel from './InterviewerOverviewPanel';
import { InterviewerWithInterviews } from '../../../../types/InterviewerWithInterviews';
import InterviewerInterviewsPanel from './InterviewerInterviewsPanel';
import InterviewerSchedulerPanel from './InterviewerAvailabilityPanel/InterviewerSchedulerPanel';
type TInterviewerDetailSuperAdminTabProps = {
  interviewerId: string;
};

export const InterviewerDetailSuperAdminTab = (props: TInterviewerDetailSuperAdminTabProps) => {
  const { interviewerId } = props;
  const {
    data: interviewer,
    isLoading,
    error,
  } = useQuery<InterviewerWithInterviews, Error>({
    queryKey: ['interviewer'],
    queryFn: (): Promise<InterviewerWithInterviews> =>
      axios.get(`api/interviewers/${interviewerId}`).then((response) => response.data),
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error has occurred: {error.message}</div>;

  return (
    <div className="my-2 mt-4 flex flex-col">
      <a href="/admin/interviewers">{'<'} Interviewers list</a>
      <h2 className="mt-4">Interviewer</h2>
      <h3 className="mt-4">Overview</h3>
      <InterviewerOverviewPanel interviewer={interviewer} />
      <h3 className="mt-4">Interviews</h3>
      <InterviewerInterviewsPanel interviewer={interviewer} />
      <h3 className="mt-4">Scheduler</h3>
      <InterviewerSchedulerPanel interviewer={interviewer} />
    </div>
  );
};

export default InterviewerDetailSuperAdminTab;
