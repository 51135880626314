import { createContext, FC, ReactElement, useContext } from 'react';

type TGlobalContext = Record<string, never>;
const GlobalContext = createContext<TGlobalContext>({});

export type ProviderProps = {
  children: ReactElement | ReactElement[];
};

const GlobalContextProvider: FC<ProviderProps> = ({ children }: ProviderProps) => (
  <GlobalContext.Provider value={{}}>{children}</GlobalContext.Provider>
);

function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a UserProvider');
  }
  return context;
}

export { GlobalContextProvider, useGlobalContext };
