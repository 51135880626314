import React, { useState } from 'react';
import { InterviewerWithInterviews } from '../../../../../types/InterviewerWithInterviews';

export type InterviewerOrgSelectProps = {
  interviewer: InterviewerWithInterviews;
  onSelectedOrg: (selectedOrgId: { _id: string; companyName: string }) => void;
};

const InterviewerOrgSelect = (props: InterviewerOrgSelectProps) => {
  const { interviewer, onSelectedOrg } = props;
  const [selectedOrgId, setSelectedOrgId] = useState<string>('');
  const orgsById = Object.fromEntries(interviewer.interviewerForOrgs.map((org) => [org._id, org]));

  const handleSelect = (org: { _id: string; companyName: string }) => {
    setSelectedOrgId(org?._id);
    onSelectedOrg(org);
  };

  return (
    <div className="my-4 flex flex-col space-y-4">
      <h4>Employer / Organization</h4>
      <p>
        Scheduler timeslots are initially generated per organization. The interviewer may be booked for any of their
        enabled focuses at these time slots.
      </p>
      <select value={selectedOrgId} onChange={(e) => handleSelect(orgsById[e.target.value])}>
        <option value="" disabled>
          Select an employer / organization
        </option>
        {interviewer.interviewerForOrgs.map((org: { _id: string; companyName: string }, i: number) => (
          <option key={i} value={org._id}>
            {org.companyName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InterviewerOrgSelect;
