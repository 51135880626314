const Logo = () => (
  <svg className="h-16px color-inherit text-black" viewBox="0 0 66 60">
    <g fill="none">
      <circle cx="33.5" cy="30.5" r="19.5" fill="#fff"></circle>
      <path
        fill="currentColor"
        d="M9.29361504 51.0638317C3.6112475 45.615908 0 38.1913413 0 30 0 13.4314575 14.7746033 0 33 0c18.2253967 0 33 13.4314575 33 30S51.2253967 60 33 60c-.675214 0-1.3456917-.0184354-2.0109527-.0547637L31 60H.9963151c-.55024963 0-.66757903-.3160924-.27378995-.6947357l8.5710899-8.2414326zM43.9135787 31.3513934l-6.5085816 6.4902392c-.6043165.6026134-.5148206 1.5337974.1997291 2.0088213l1.314223.8736796c.656402.4363681 1.5932336.3456817 2.1461663-.2056927l8.5002268-8.4762715c.5805928-.5789567.5784934-1.5077402 0-2.0846034l-8.5002268-8.4762715c-.554671-.5531078-1.4926435-.6401467-2.1461663-.2056927l-1.314223.8736796c-.7208472.4792104-.8095753 1.4006937-.1997291 2.0088213l6.5085816 6.4902392c.1914525.1909129 0 .7030516 0 .7030516zm-21.8271574 0c-.1923352-.1917932-.1914525-.5121387 0-.7030516l6.5085816-6.4902392c.6098462-.6081276.5211181-1.5296109-.1997291-2.0088213l-1.314223-.8736796c-.6535228-.434454-1.5914953-.3474151-2.1461663.2056927l-8.5002268 8.4762715c-.5784934.5768632-.5805928 1.5056467 0 2.0846034l8.5002268 8.4762715c.5529327.5513744 1.4897643.6420608 2.1461663.2056927l1.314223-.8736796c.7145497-.4750239.8040456-1.4062079.1997291-2.0088213l-6.5085816-6.4902392z"
      ></path>
    </g>
  </svg>
);

export default Logo;
