type TestimonialProps = {
  imgPath: string;
  text: string;
  author: string;
};

const Testimonial = (props: TestimonialProps) => {
  const { imgPath, text, author } = props;

  return (
    <div className="mt-8 flex border-l-4 border-yellow-400 pl-4 lg:pl-0">
      <div className="mx-8 flex hidden items-center justify-center lg:flex">
        <img className="mx-auto min-w-[100px] max-w-[100px]" src={imgPath} />
      </div>
      <p className="font-mono text-xl font-bold text-yellow-400 lg:text-5xl">“</p>
      <div>
        <p className="font-medium text-gray-500 md:text-lg lg:text-2xl">{text}</p>
        <div className="mt-2 flex flex-row">
          <div className="mr-4 flex basis-1/4 items-center justify-center lg:hidden">
            <img className="mx-auto min-w-[100px] max-w-[100px]" src={imgPath} />
          </div>
          <div className="self-center">
            <p className="mt-2 mb-0 font-semibold lg:text-xl">{author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
