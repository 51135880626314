import { useState } from 'react';
import SetDueDateModal from './SetDueDateModal';

declare global {
  interface Window {
    showSetDueDateModal: (submit: (date: string) => void) => void;
  }
}
const SetDueDateModalContainer = () => {
  const [isSetDueDateModalVisible, setIsSetDueDateModalVisible] = useState(false);
  // Initial state is a no-op ... we expect Angular to replace it by invoking the window.showSetDueDateModal() function added by this component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onSubmit, setOnSubmit] = useState({ submit: (date: string) => {} });

  window.showSetDueDateModal = (submit: (date: string) => void) => {
    setOnSubmit({ submit });
    setIsSetDueDateModalVisible(true);
  };

  return (
    <SetDueDateModal open={isSetDueDateModalVisible} setOpen={setIsSetDueDateModalVisible} onSubmit={onSubmit.submit} />
  );
};

export default SetDueDateModalContainer;
